import React, { useEffect, useState } from 'react'
import {
  Link as RouterLink,
  MemoryRouter as Router,
  LinkProps as RouterLinkProps,
  useHistory,
} from 'react-router-dom'
import clsx from 'clsx'
import { Flex, Spinner } from 'theme-ui'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Tabs,
  Tab,
  FormHelperText,
} from '@material-ui/core'
import { useTranslation, Trans, Translation } from 'react-i18next'
import { Decimal, Percent, LiquityStoreState, MINIMUM_COLLATERAL_RATIO } from "@liquity/lib-base";
import { useLiquitySelector } from '@liquity/lib-react'
import { COIN } from '../../strings'
import { Icon } from '../Icon'
import { useLiquity } from '../../hooks/LiquityContext'
import { Transaction, useMyTransactionState } from '../Transaction'
import RedditTextField from '../../components/new-ui/RedditTextField'
import icon_T from '../../assets/icon-lqty.svg'

import common from '../../utils/common';

import { RedemptionAction } from "../Redemption/RedemptionActionNew";
import { ActionDescription, Amount } from "../ActionDescription";
import { ErrorDescription } from "../ErrorDescriptionNew";

const mcrPercent = new Percent(MINIMUM_COLLATERAL_RATIO).toString(0);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxForm: {
      // padding: '0 20px 40px',
    },
    title: {
      textAlign: 'center',
      padding: '16px 16px 10px',
      fontSize: '18px',
      fontWeight: 500,
      color: '#745DDF',
      lineHeight: '25px',
    },
    margin: {
      margin: theme.spacing(1),
      width: '100%',
      marginBttom: '24px',
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '100%',
      margin: '0',
    },
    helperText: {
      marginTop: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    subBtn: {
      marginTop: '20px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(21, 66, 205, 1)',
      '&:hover': {
        backgroundColor: 'rgba(21, 66, 205, 1)',
        color: '#fff',
      },
    },
    card: {
      padding:'60px 40px',
      marginTop:'20px',
      background: '#FFFFFF',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.14)',
      borderRadius: '12px'
    },
    captionCard: {
      fontSize: '18px',
      fontWeight: 500,
      color: '#293147',
      lineHeight: '21px'
    },
    overline: {
      marginTop:'12px',
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '16px'
    }
  })
)

const select = ({ price, fees,redemptionRate, total,accountBalance,lusdBalance }: LiquityStoreState) => ({
  price,
  fees,
  redemptionRate,
  total,
  accountBalance,
  lusdBalance
})

const transactionId = "redemption";

type ChangeTy = {
  handleChangeTab: (num:number) => void;
}

const ChangeETH: React.FC<ChangeTy> = ({handleChangeTab}) => {
  const classes = useStyles()
  let { t, i18n } = useTranslation();
  // const borrowingFeePct = new Percent(borrowingRate)

  const { price, fees, total,lusdBalance,accountBalance } = useLiquitySelector(select)
  const [lusdAmount, setLUSDAmount] = useState(Decimal.ZERO)
  const [lusdAmountV, setLUSDAmountV] = useState<string>('')
  const [changePending, setChangePending] = useState(false)
  // const redemptionFeePct = new Percent(redemptionRate)
  
  useEffect(() => {
    setLUSDAmountV('')
    setLUSDAmount(Decimal.from('0'))
  }, [accountBalance, lusdBalance])
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLUSDAmountV(event.target.value)
    // if (event.target.value) {
    //   setLUSDAmount(Decimal.from(event.target.value))
    // } else {
    //   setLUSDAmount(Decimal.from('0'))
    // }
  }

  const handleMaxClick = (str: string) => {
    setLUSDAmountV(common.numberDecimal(Number(lusdBalance),2))
    // setLUSDAmount(Decimal.from(common.numberDecimal(Number(lusdBalance),2)))
  }

  useEffect(() => {
    if (Number(lusdAmountV)>0) {
      setLUSDAmount(Decimal.from(lusdAmountV))
    } else {
      setLUSDAmount(Decimal.from('0'))
    }
  },[lusdAmountV])

  // const lusdBalance = useLiquitySelector(selectLUSDBalance)

  const dirty = !lusdAmount.isZero
  const ethAmount = lusdAmount.div(price)
  const redemptionRates = fees.redemptionRate(lusdAmount.div(total.debt))
  const feePct = new Percent(redemptionRates)
  const ethFee = ethAmount.mul(redemptionRates)
  const maxRedemptionRate = redemptionRates.add(0.001); // TODO slippage tolerance
  
  const [canRedeem, description] = total.collateralRatioIsBelowMinimum(price)
    ? [
        false,
        <ErrorDescription>
          You can't redeem LUSD when the total collateral ratio is less than{" "}
          <Amount>{mcrPercent}</Amount>. Please try again later.
        </ErrorDescription>
      ]
    : lusdAmount.gt(lusdBalance)
    ? [
        false,
        <ErrorDescription>
          The amount you're trying to redeem exceeds your balance by{" "}
          <Amount>
            {lusdAmount.sub(lusdBalance).prettify()} {COIN}
          </Amount>
          .
        </ErrorDescription>
      ]
    : [
        true,
        null
      ];
  
  return (
    <Box className={classes.boxForm}>
      {/* <Box className={classes.card} display="flex" alignItems="center" justifyContent="center">
              <Box style={{textAlign:'center'}}>
                <Typography variant="h3" className={classes.captionCard} display="block" gutterBottom>
                Redemption is not enabled yet.
                </Typography>
                <Typography variant="caption" className={classes.overline} display="block" gutterBottom>
                LUSD redemption is disabled for the first 14 days after launch.
                </Typography>
              </Box>
            </Box> */}
      {/* <Typography variant="h3" className={classes.title}>
        兑换ETH
      </Typography> */}
      <RedditTextField
        labeltext={t('text_65')}
        coin_in="LUSD"
        coin={(<><img src={icon_T} style={{width:'18px',marginRight:'4px'}} alt=""/><Box>LUSD</Box></>)}
        type='number'
        endadornmenttext={t('text_18')}
        endadornmentvalue={lusdBalance.prettify()}
        onChange={handleChange}
        isbtn="1"
        btntext="Max"
        btncallback={handleMaxClick}
        value={lusdAmountV}
        // defaultValue={lusdAmount.toString(2)}
        className={clsx(classes.margin, classes.textField)}
        // defaultValue="react-reddit"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        style={{ backgroundColor: '#fff', marginTop: '20px' }}
      />
      {/* <FormHelperText component="div" className={classes.helperText}>
        <Typography variant="body2" gutterBottom>
        Redemption fee:
        </Typography>
        <Typography variant="body2" gutterBottom>
          {redemptionFeePct.toString(2)}
        </Typography>
      </FormHelperText> */}

      {/* <RedemptionAction
        {...{ lusdAmount, setLUSDAmount, changePending, setChangePending, maxRedemptionRate }}
      /> */}

        {((dirty || !canRedeem) && description)}

          <RedemptionAction
            transactionId={transactionId}
            disabled={!dirty || !canRedeem}
            lusdAmount={lusdAmount}
            maxRedemptionRate={maxRedemptionRate}
          />

      {
        Number(lusdAmount) > 0 ? (
          <>
            <FormHelperText
        component="div"
        style={{ marginTop: '20px' }}
        className={classes.helperText}
      >
        <Typography variant="body2" gutterBottom>
          Fee
        </Typography>
        <Typography variant="body2" gutterBottom>
          {ethFee.toString(4)} ETH({feePct.toString(2)})
        </Typography>
      </FormHelperText>
      <FormHelperText
        component="div"
        style={{ marginTop: '10px' }}
        className={classes.helperText}
      >
        <Typography variant="body2" gutterBottom>
          {t('text_092')}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {dirty ? ethAmount.sub(ethFee).prettify(4) : '0.00'} ETH
        </Typography>
      </FormHelperText>
          </>
        ) : (
          <FormHelperText
          component="div"
          style={{ marginTop: '20px' }}
          // className={classes.helperText}
            >
              <strong>{t('text_730')} </strong>{t('text_731')}
              <Button style={{color:'#1542CD',padding: '0'}} onClick={()=> handleChangeTab(1)}>
              {t('text_732')}
              </Button>
          {/* <Typography variant="body2" gutterBottom>
            Fee
          </Typography>
          <Typography variant="body2" gutterBottom>
            {ethFee.toString(4)} ETH({feePct.toString(2)})
          </Typography> */}
        </FormHelperText>
        )
      }
      
    </Box> 
  )
}

export default ChangeETH
