import { useState, useCallback } from "react";
import { TipContext } from "./TipContext";

type ContextType = {
  status: boolean;
  dispatchEvent: () => void;
};

export const TipContextProvider: React.FC = props => {
  const { children } = props;
  const [status, setStatus] = useState<boolean>(false)
  const dispatchEvent = useCallback((val:boolean) => {
    setStatus(val);
  }, []);

  return <TipContext.Provider value={{status,dispatchEvent}}>{children}</TipContext.Provider>;
};