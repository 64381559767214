const en_US = {
  text_01:"Deposit",
  text_02: "Withdraw",
  text_03: "Redemption ",
  text_04: "Total staked",
  text_05: "",
  text_06: "",
  text_07: "STAKED",
  text_08: "Stake ",
  text_09: "Borrow",
  text_091: "Trove",
  text_092: "Receive",
  text_10: "Stability Pool",
  text_11: "Stake",
  text_12: "Liquidation",
  text_13: "LQTY Remaining",
  text_14: "Reward",
  text_15: "Withdraw",
  text_16: "Deposit",
  text_17: "Available: ",
  text_18: "Wallet: ",
  text_19: "Repay",
  text_20: "Total debt must be at least 2000 LUSD.",
  text_21: "While the Minimum Collateral Ratio is 110% during normal operation, it is recommended to keep the Collateral Ratio always above 150% to avoid liquidation under Recovery Mode. A Collateral Ratio above 200% or 250% is recommended for additional safety.",
  text_22: "Available: ",
  text_23: "Make sure enough LUSD before closing trove",
  text_24: "Close trove",
  text_25: "This fee covers the gas cost a liquidator would pay to liquidate your Trove. You are refunded this fee when you repay your debt.",
  text_26: "Collateral ratio",
  text_261: "Ratio",
  text_27: "Redemption",
  text_28: "Debt",
  text_29: "Repay",
  text_30: "Withdraw",
  text_31: "Close Trove",
  text_32: "Unstake",
  text_33: "Stake",
  text_34: "Unstake",
  text_35: "Stake",
  text_36: "Available: ",
  text_37: "Total LUSD supply",
  text_38: "Total Collateral Ratio",
  text_39: "Price",
  text_40: " APR ",
  text_41: "Owner",
  text_42: "Collateral (ETH)",
  text_43: "Debt (LUSD)",
  text_44: "Coll Ratio",
  text_45: "Operation",
  text_46: "Liquidate",
  text_lp: "An estimate of the LQTY return on staked UNI LP tokens. The farm runs for 6-weeks, and the return is relative to the time remaining. ",
  text_47: "An estimate of the LQTY return on the LUSD deposited to the Stability Pool over the next year, not including your ETH gains from liquidations.",
  text_48: "Confirm",
  text_49: "Confirm",
  text_50: "Pending",
  text_51: "Success",
  text_52: "Transaction Confirmed",
  text_53: "Failure ",
  text_54: "Please try again.",
  text_55: "Error",
  text_56: "Transaction Rejected",
  text_57: "Broadcasting",
  text_58: "Liquidation reserve",
  text_59: "Total debt must be be at least",
  text_591: ".",
  text_60: "Fee",
  text_61: "An estimate of the LUSD&ETH return on the LQTY staked to this pool.（Calculation in LUSD)",
  text_62: "Liquity Analysis",
  text_63: "Switch to DevUI",
  text_64: "Switch to FiUI",
  text_65: "Redeem",
  text_67: "Your Trove has been redeemed",
  text_68: "Please reclaim your remaining collateral before opening a new Trove.",
  text_69: "Account",
  text_70: "Connected ",
  text_71: "Change",
  text_720: "The total amount of LUSD your Trove will hold. You will need to repay ",
  text_721: " LUSD to reclaim your collateral (200 LUSD Liquidation Reserve excluded).",
  text_730: "Note: ",
  text_731: "Redemption is not for repaying your loan. To repay your loan, switch to",
  text_732: "Withdraw tab.",
  text_91: "Normal Mode:",
  text_92: "Recovery Mode:",
  text_93: "Recovery Mode Price :",
  text_94: "The dollar value per unit of collateral at which your Trove will drop below a 110% Collateral Ratio and be liquidated. You should ensure you are comfortable with managing your position so that the price of your collateral never reaches this level.",
  text_95: "Liquidation price",
  text_96: "Price",
  text_97: "Currently Burned",
  text_99: "Add",
  text_100: "Buy",
  text_102: "My Wallet",
  text_118: "Market Cap",
  text_119: "Circulating Supply",
  text_127: "Statistics",
  text_128: "TVL",
  text_120: "The amount of LQTY  that are circulating in the market. Formula = (Circulating Supply / Total Supply)*100%",
  text_371: "Total supply",
  text_041: "LUSD in Stability Pool",
  text_863: "Home",
  text_123: "Liquity 生态",
}
export default en_US;  