import React,{useEffect,useState} from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Button,
  Tooltip
} from '@material-ui/core'
import clsx from 'clsx'
import { Flex, Spinner } from "theme-ui";
import { Decimal, Decimalish,Percent, LQTYStake, LiquityStoreState } from "@liquity/lib-base";
import { LiquityStoreUpdate, useLiquityReducer, useLiquitySelector } from "@liquity/lib-react";
import { Transaction, TransactionFunction, useMyTransactionState } from "../../components/Transaction";
import { useLiquity } from "../../hooks/LiquityContext";
import { COIN, GT } from "../../strings";
import Api from '../../utils/http'

import common from '../../utils/common';

import { useTranslation, Trans, Translation } from 'react-i18next'

import DialogPledge from "../../components/new-ui/DialogPledge";
import { StakingGainsAction } from "../../components/Staking/StakingGainsActionNew";

import OverShadomBox from '../../components/new-ui/OverShadomBox'
import icon_t from '../../assets/icon-lqty1.svg'
import tip from '../../assets/tip.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    back: {
      background: '#fff',
      overflow: 'hidden',
      padding:'20px 20px 30px'
    },
    subtitle: {
      marginBottom:'6px',
      fontSize: '24px',
      fontWeight: 600,
      color: 'rgba(116, 93, 223, 1)',
      lineHeight: '30px',
    },
    body2: {
      marginRight:'10px',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(116, 93, 223, 1)',
      lineHeight: '20px'
    },
    subtitle1: {
      marginBottom:'10px',
      fontSize: '14px',
      fontWeight: 400,
      color: '#745DDF',
      lineHeight: '20px'
    },
    button: {
      fontSize: '16px',
      fontWeight: 800,
      color: 'rgba(31, 37, 51, 1)',
      lineHeight: '20px'
    },
    body1: {
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(84, 90, 108, 1)',
      lineHeight: '20px'
    },
    button1: {
      fontSize: '14px',
      fontWeight: 600,
      color: 'rgba(84, 90, 108, 1)',
      lineHeight: '20px'
    },
    containedBtn: {
      minWidth:'100px',
      background: '#1542CD',
      borderRadius: '8px',
      padding:'10px',
      fontSize: '14px',
      // fontWeight: 600,
      color: '#fff',
      lineHeight: '22px',
      '&:hover': {
        background: '#1542CD',
        color: '#fff',
      }
    },
    containedBtn1: {
      background: 'rgba(21, 66, 205, 0.1)',
      borderRadius: '16px',
      padding: '2px',
      color: 'rgba(21, 66, 205, 1)',
      fontSize: '22px',
      minWidth:'48px',
      // fontWeight: 600,
      '&:hover': {
        background: 'rgba(21, 66, 205, 0.1)',
        color: 'rgba(21, 66, 205, 1)',
      }
    },
    subBtn: {
      borderRadius: '8px',
      marginTop:'20px',
      padding: '12px',
      width: '100%',
      // fontWeight: 600,
    },
    outlined: {
      border: '1px solid #1542CD',
      color: 'rgba(21, 66, 205, 1)',
    },
    grid: {
      [theme.breakpoints.up('sm')]: {
        maxWidth:'384px'
      },
    },
    tip: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '17px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '9px',
      border: '1px solid #E3E3F3'
    }
  })
)

const init = ({ lqtyStake }: LiquityStoreState) => ({
  originalStake: lqtyStake,
  editedLQTY: lqtyStake.stakedLQTY,
  changePending: false
});

type StakeManagerState = ReturnType<typeof init>;
type StakeManagerAction =
  | LiquityStoreUpdate
  | { type: "startChange" | "finishChange" | "revert" }
  | { type: "setStake"; newValue: Decimalish };

const reduceWith = (action: StakeManagerAction) => (state: StakeManagerState): StakeManagerState =>
  reduce(state, action);

const finishChange = reduceWith({ type: "finishChange" });
const revert = reduceWith({ type: "revert" });

const reduce = (state: StakeManagerState, action: StakeManagerAction): StakeManagerState => {
  // console.log(state);
  // console.log(action);

  const { originalStake, editedLQTY, changePending } = state;

  switch (action.type) {
    case "startChange":
      return { ...state, changePending: true };

    case "finishChange":
      return { ...state, changePending: false };

    case "setStake":
      return { ...state, editedLQTY: Decimal.from(action.newValue) };

    case "revert":
      return { ...state, editedLQTY: originalStake.stakedLQTY };

    case "updateStore": {
      const {
        stateChange: { lqtyStake: updatedStake }
      } = action;

      if (!updatedStake) {
        return state;
      }

      const newState = { ...state, originalStake: updatedStake };

      const changeCommitted =
        !updatedStake.stakedLQTY.eq(originalStake.stakedLQTY) ||
        updatedStake.collateralGain.lt(originalStake.collateralGain) ||
        updatedStake.lusdGain.lt(originalStake.lusdGain);

      if (changePending && changeCommitted) {
        return finishChange(revert(newState));
      }

      return {
        ...newState,
        editedLQTY: updatedStake.apply(originalStake.whatChanged(editedLQTY))
      };
    }
  }
};

const select = ({
  totalStakedLQTY,
  lqtyStake
}: LiquityStoreState) => ({
  totalStakedLQTY,
  lqtyStake
});

const Pledge: React.FC = () => {
  const classes = useStyles()
  const [{ originalStake, editedLQTY, changePending }, dispatch] = useLiquityReducer(reduce, init);
  const {
    totalStakedLQTY,
    lqtyStake
  } = useLiquitySelector(select);

  let { t, i18n } = useTranslation();

  const [text, setText] = useState<string>(t('text_61'));
  useEffect(() => {
    setText(t('text_61'))
  }, [i18n.language])

  const [open, setOpen] = React.useState(false);
  const [aprPercentage, setAprPercentage] = React.useState<string>('');
  const [openTip, setOpenTip] = React.useState(false);

  const [index, setIndex] = React.useState(0);  // 0加，1减
  
  const poolShare = lqtyStake.stakedLQTY.mulDiv(100, totalStakedLQTY);

  const handleClickIndex = (num: number) => {
    setOpen(true);
    setIndex(num);
  };

  const handleCalculateInit = () => {
    // const params = {
    //   address: account
    // }
    Api.calculate_half_an_hour({}).then((res) => {
      if (res.data.code < 300) {
        const data = res.data.data;
        if (data.current) {
          setAprPercentage(common.numberDecimal(data.current.data.amount,2))
        } else {
          setAprPercentage(common.numberDecimal(data.old.data.amount,2))
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    handleCalculateInit()
  },[])

  return (
    <>
        <Grid item xs={12} md={6} className={classes.grid}>
          <OverShadomBox className={classes.back}>
          <Box display="flex" justifyContent="space-between" alignItems="center" style={{padding:"20px",margin: '-20px -20px 0',backgroundColor:'rgba(210, 214, 220, .08)'}}>
              <img src={icon_t} alt="" style={{height:'48px'}} />
              <Box style={{textAlign:'right'}}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                LQTY
                </Typography>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <Typography variant="body1" className={classes.body2}>
                  {t('text_04')}
                  </Typography>
                  <Typography variant="button" className={classes.button} display="block">
                    {totalStakedLQTY.shorten()} 
                  </Typography>
                </Box>
              </Box>
          </Box>
          <Box display="flex" justifyContent="space-between"  alignItems="center" style={{marginTop:"20px"}}>
                <Typography variant="body1" className={classes.body1}>
                Pool share
                </Typography>
                <Typography variant="button" className={classes.button1} display="block">
                  {/* {originalStake.collateralGain.prettify(4)} */}
                  {poolShare.prettify(4)}%
                </Typography>
            </Box>
          <Box display="flex" justifyContent="space-between"  alignItems="center" style={{marginTop:"20px"}}>
                <Typography variant="body1" className={classes.body1}>
                  {t('text_40')}
                  <Tooltip classes={{
                    tooltip:classes.tip
                   }}
                    onClose={()=>setOpenTip(false)}
                    open={openTip}
                    title={text}
                    interactive placement="top">
                      <img src={tip} onClick={()=>setOpenTip(true)} style={{width:'14px', marginLeft: '2px',marginBottom:'-2px'}} alt=""/>
                  </Tooltip>
                </Typography>
                <Typography variant="button" className={classes.button1} display="block">
                  {aprPercentage?(aprPercentage+'%'):'--'}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between"  alignItems="center" style={{marginTop:"20px"}}>
                <Typography variant="body1" className={classes.body1}>
                ETH{t('text_05')}
                </Typography>
                <Typography variant="button" className={classes.button1} display="block">
                  {/* {originalStake.collateralGain.prettify(4)} */}
                {lqtyStake.collateralGain.prettify(4)}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between"  alignItems="center" style={{marginTop:"20px"}}>
                <Typography variant="body1" className={classes.body1}>
                 LUSD{t('text_06')}
                </Typography>
                <Typography variant="button" className={classes.button1} display="block">
                  {/* {originalStake.lusdGain.prettify()} */}
                  {lqtyStake.lusdGain.prettify()}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between"  alignItems="center" style={{marginTop:"20px"}}>
              <Box>
                <Typography variant="subtitle1"  className={classes.subtitle1} gutterBottom>
                <strong>LQTY</strong> {t('text_07')}
                </Typography>
                <Typography variant="button" className={classes.button} display="block">
                  {editedLQTY.toString(2)}  
                </Typography>
              </Box>
              <Box>
                {!originalStake.isEmpty ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={()=>handleClickIndex(1)}
                      className={classes.containedBtn1}
                      disableElevation>
                    -
                  </Button>
                  <Button variant="contained" onClick={()=>handleClickIndex(0)} style={{marginLeft:'10px'}} className={classes.containedBtn1} disableElevation>
                      +
                  </Button>
                  </>
                ) : (
                  <Button variant="contained" onClick={()=>handleClickIndex(0)} className={classes.containedBtn} disableElevation>
                    {t('text_08')} LQTY
                  </Button>
                )}
                
              </Box>
            </Box>
          {!originalStake.isEmpty && (
            <StakingGainsAction />
              // <Button variant="outlined" className={classes.outlined}>
                // <StakingAction {...{ originalStake, editedLQTY, changePending, dispatch }}></StakingAction>
              // </Button>
            )}
          </OverShadomBox>
        </Grid>

        <DialogPledge open={open} handleClose={() => setOpen(false)} value_index={index} editedL={editedLQTY.toString(2)}></DialogPledge>
    </>
  )
};
export default Pledge;
