import React, { useEffect,useState } from "react";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Hidden,
  makeStyles,
  createStyles,
  withStyles,
  Button,
  Theme,
  Typography,
  List,
  Drawer,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from '@material-ui/core';
import { useLiquitySelector } from "@liquity/lib-react";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquity } from "../../hooks/LiquityContext";

import NavItem from './NavItem';
import icon_lang from '../../assets/icon-lang.png'
import icon_price from '../../assets/icon-lqty1.svg'
import icon_06 from '../../assets/icon-06.svg'
import { useTranslation, Trans, Translation } from 'react-i18next'
import { ChainId, Token, TokenAmount, Pair, WETH, Fetcher, Route, Price } from '@uniswap/sdk'
const LQTY = new Token(ChainId.MAINNET, '0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d', 18)
const USDT = new Token(ChainId.MAINNET, '0xdac17f958d2ee523a2206206994597c13d831ec7', 6)

async function lqtyPrice() {
  const pairDai = await Fetcher.fetchPairData(USDT,WETH[USDT.chainId])
  const routeDai = new Route([pairDai], WETH[USDT.chainId])
  const pairLqty = await Fetcher.fetchPairData(LQTY, WETH[LQTY.chainId])
  const routeLqty = new Route([pairLqty], WETH[LQTY.chainId])
  const lqtyE = routeLqty.midPrice.toSignificant(6);
  const daiE = routeDai.midPrice.toSignificant(6);

  return 1 / Number(lqtyE) * Number(daiE);
  // console.log(routeDai.midPrice.invert().toSignificant(6)) // 202.081
  // console.log(routeLqty.midPrice.invert().toSignificant(6)) // 0.00494851
  // console.log(1/Number(lqtyE)*Number(daiE)) // 0.00494851
}

type TypeNav = {
  onMobileClose: () => void;
  hostCheck: boolean;
  // className: string | undefined;
  openMobile: any;
  handleCheckUi?: () => void;
  // ref: (instance: Element | VirtualElement | null) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktopDrawer: {
      width: 238,
      top: 64,
      height: 'calc(100% - 64px)',
      // boxShadow: '0px 8px 10px 0px rgba(0, 0, 0, 0.14)',
      border: '1px solid #E3E3F3',
      [theme.breakpoints.down('xs')]: {
        top: 48,
        height: 'calc(100% - 48px)',
      },
    },
    avatar: {
      cursor: 'pointer',
      width: 64,
      height: 64
    },
    tabs: {
      // borderLeft: `1px solid ${theme.palette.divider}`,
    },
    tab: {
      paddingLeft: '30px',
      textAlign:'left'
    },
    indicator: {
      left: '0',
      right: 'auto',
      width:'4px',
      backgroundColor:'rgba(21, 66, 205, 1)'
    },
    a: {
      textDecoration: 'none'
    },
    span: {
      display: 'inline-block',
      width: '12px',
      height: '12px',
      marginRight: '15px',
      borderRadius:'50%'
    },
    text: {
      fontSize: '12px',
      fontWeight: 400,
      color: '#1F2533',
      lineHeight: '17px'
    },
    color1: {
      background: '#FFBEE3'
    },
    color2: {
      background: 'rgba(198, 143, 212, 1)'
    },
    color3: {
      background: 'rgba(92, 152, 212, 1)'
    },
    color4: {
      background: 'rgba(69, 171, 227, 1)'
    },
    button: {
      paddingLeft:'30px'
    },
    lang: {
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(84, 90, 108, 1)',
      lineHeight: '20px',
      // paddingTop: '0',
      marginRight:'-8px'
    },
    langImg: {
      width: '20px',
      height: '20px',
      // marginRight:'10px'
    },
    price: {
      color: 'rgba(0, 0, 0, 1)',
      fontWeight: 500,
      // paddingTop: '0',
      marginLeft:'-4px'
    },
    priceImg: {
      width: '24px',
      height: '24px',
      // marginRight:'10px'
    },
    boxNav: {
      width:'100%',
      position: 'absolute',
      bottom: '0',
      left: '0'
    },
    btnRun: {
      width:'100%',
      padding:'16px 20px',
      color: 'rgba(116, 93, 223, 1)',
      fontWeight: 500,
      fontSize: '16px'
    },
    langBtn: {
      // width:'100%',
      padding: '20px',
      borderTop: '1px solid rgba(227, 227, 243, 1)'
    },
    caption_left: {
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '20px'
    },
    caption_right: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '17px'
    },
    active: {  
      backgroundColor: 'rgba(227, 227, 243, .47)!important',
      color: '#545A6C'
    },
    btnTab: {
      position: 'absolute',
      top: '-70px',
      left: '0',
      padding:'10px 14px 10px 20px',
      background: '#FFFFFF',
      boxShadow: '0px 4px 8px 0px rgba(30, 15, 33, 0.14)',
      borderRadius: '0 24px 24px 0',
      border: '2px solid #E3E3F3',
      borderLeft: '0',
      fontSize: '16px',
      fontWeight: 500,
      color: 'rgba(116, 93, 223, 1)',
      lineHeight: '22px'
    }
  })
);

const StyledMenu = withStyles({
  paper: {
    border: 'none',
    background: '#FFFFFF',
    boxShadow: '0px 8px 10px 0px rgba(120, 137, 246, 0.28)',
    borderRadius: '4px'
  },
})((props: MenuProps) => {
  return (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  )
});

const select = ({
  price,
  total,
}: LiquityStoreState) => ({
  price,
  total,
});

const isLang = (str: string) => {
  if (str==='zh_CN') {
    return '中文';
  } else if (str==='zh_HK') {
    return '繁体中文';
  } else if (str==='ES') {
    return 'Español';
  } else if (str==='PT') {
    return 'Português';
  } else if (str==='FI') {
    return 'Français';
  } else if (str==='DE') {
    return 'Deutsche';
  } else if (str==='KO') {
    return '한국어';
  } else {
    return 'EN';
  }
}

const hostUrl = [
  'dapp.liquity.fi',
  'm.liquity.fi'
]

let hrefCheck = false;
const hostname = window.location.hostname;
hostUrl.forEach((item) => {
  if (hostname===item) {
    hrefCheck = true;
  }
})
// const hrefCheck = window.location.hostname === 'm.liquity.fi';
// const hrefCheck = window.location.hostname === 'localhost';

const NavBar:React.FC<TypeNav> = ({
  onMobileClose,
  hostCheck,
  // className,
  openMobile,
  handleCheckUi
}) => {
  const classes = useStyles();
  const {
    price,
    total
  } = useLiquitySelector(select);

  const {
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();

  let { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>('');
  const [lqtyValue, setLqtyValue]= useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();

  let lqtyTokenAddress = addresses["lqtyToken"];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    lqtyPrice().then((res) => {
      setLqtyValue(res.toFixed(2))
    })
  }, [price]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (str: string) => {
    i18n.changeLanguage(str);
    setLanguage(str);
    setAnchorEl(null);
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box style={{ padding: '10px 0' }}>
        
        <NavItem ></NavItem>
        
      </Box>
      <List component="nav" aria-label="contacts">
        <a href="https://docs.liquity.org/" target="_blank"  className={classes.a}>
          <ListItem button className={classes.button}>
            <span className={clsx(classes.span,classes.color1)}/>
            <span className={classes.text}>Doc</span> 
          </ListItem>
        </a>
        <a href={hrefCheck?'https://twitter.com/LiquityProtocol':"https://twitter.com/LiquityF"} target="_blank"  className={classes.a}>
          <ListItem button className={classes.button}>
            <span className={clsx(classes.span,classes.color2)}/>
            <span className={classes.text}>Twitter</span> 
          </ListItem>
        </a>
        <a href={hrefCheck ? 'https://discord.com/invite/2up5U32' : "https://t.me/joinchat/eDzm-Vv_AEFiN2E1"}  className={classes.a} target="_blank">
          <ListItem button className={classes.button}>
            <span className={clsx(classes.span,classes.color2)}/>
            <span className={classes.text}>{hrefCheck ? 'Discrod' : 'Telegram'}</span>
          </ListItem>
        </a>
        <a href="https://docs.liquity.fi/tokeneconomy"  className={classes.a} target="_blank">
          <ListItem button className={classes.button}>
            <span className={clsx(classes.span,classes.color3)}/>
            <span className={classes.text}>LIFI Token</span>
          </ListItem>
        </a>
        {/* <a href=""  className={classes.a}>
          <ListItem button className={classes.button}>
            <span className={clsx(classes.span,classes.color2)}/>
            <span className={classes.text}>Wechat</span> 
          </ListItem>
        </a> */}
      </List>

      <Box
        className={classes.boxNav}>
        {
          hostCheck && (
            <Button onClick={handleCheckUi} className={classes.btnTab}>
              {t('text_63')}
            </Button>
          )
        }
        
        {/* <Button
            startIcon={<img src={icon_06} className={classes.priceImg} alt="" />}
            className={classes.btnRun}
            target="_blank"
            href="https://liquity.fi/runfrontend"
          >
            
          Run Your Frontend
        </Button> */}
        <Box className={classes.langBtn}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* <Button
              startIcon={<img src={icon_price} className={classes.priceImg} alt="" />}
              className={classes.price}
              target="_blank"
              href={`https://info.uniswap.org/#/tokens/0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d`}
          >
            
            $ {lqtyValue||'--'}
          </Button> */}

          <Button
            startIcon={<img src={icon_lang} className={classes.langImg} alt="" />}
            onClick={handleClick}
            className={classes.lang}
          >
            {isLang(i18n.language)}
          </Button>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem selected={isLang(i18n.language)==='EN'} className={isLang(i18n.language)==='EN'? classes.active:''}>
              <ListItemText onClick={()=>handleSelect('en')} primary="EN" />
            </MenuItem>
            <MenuItem selected={isLang(i18n.language)==='中文'}  className={isLang(i18n.language)==='中文'? classes.active:''}>
              <ListItemText onClick={()=>handleSelect('zh_CN')} primary="中文" />
            </MenuItem>
            <MenuItem selected={isLang(i18n.language)==='繁体中文'}  className={isLang(i18n.language)==='繁体中文'? classes.active:''}>
              <ListItemText onClick={()=>handleSelect('zh_HK')} primary="繁体中文" />
            </MenuItem>
            <MenuItem selected={isLang(i18n.language)==='Español'}  className={isLang(i18n.language)==='Español'? classes.active:''}>
              <ListItemText onClick={()=>handleSelect('ES')} primary="Español" />
            </MenuItem>
            <MenuItem selected={isLang(i18n.language)==='Português'}  className={isLang(i18n.language)==='Português'? classes.active:''}>
              <ListItemText onClick={()=>handleSelect('PT')} primary="Português" />
            </MenuItem>
            <MenuItem selected={isLang(i18n.language)==='Français'}  className={isLang(i18n.language)==='Français'? classes.active:''}>
              <ListItemText onClick={()=>handleSelect('FI')} primary="Français" />
            </MenuItem>
            <MenuItem selected={isLang(i18n.language)==='Deutsche'}  className={isLang(i18n.language)==='Deutsche'? classes.active:''}>
              <ListItemText onClick={()=>handleSelect('DE')} primary="Deutsche" />
            </MenuItem>
            <MenuItem selected={isLang(i18n.language)==='한국어'}  className={isLang(i18n.language)==='한국어'? classes.active:''}>
              <ListItemText onClick={()=>handleSelect('KO')} primary="한국어" />
            </MenuItem>
          </StyledMenu>

          {/* <Button
             onClick={handleSelect}
            startIcon={<img src={icon_lang} className={classes.langImg} alt="" />}
            className={classes.lang}
            >
            {i18n.language==='en'?'EN':'中文'}
          </Button> */}
        </Box>
        {/* <Box style={{marginTop:'10px'}} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="caption" className={classes.caption_left} gutterBottom>
          TVL (ETH)
          </Typography>
          <Typography variant="caption" className={classes.caption_right} gutterBottom>
          {Decimal.from(total.collateral).shorten()}(${Decimal.from(total.collateral.mul(price)).shorten()})
          </Typography>
        </Box> */}
        {/* <Box style={{marginTop:'10px'}} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="caption" className={classes.caption_left} gutterBottom>
          TVL (ETH)
          </Typography>
          <Typography variant="caption" className={classes.caption_right} gutterBottom>
          {Decimal.from(total.collateral).prettify(0)}
          </Typography>
        </Box>
        <Box style={{marginTop:'10px'}} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="caption" className={classes.caption_left}>
          TVL (USD)
          </Typography>
          <Typography variant="caption" className={classes.caption_right}>
          ${Decimal.from(total.collateral.mul(price)).prettify(0)}
          </Typography>
          </Box>*/}
        </Box> 
      </Box>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: clsx(classes.desktopDrawer) }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: clsx(classes.desktopDrawer) }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

// NavBar.propTypes = {
//   onMobileClose: PropTypes.func,
//   openMobile: PropTypes.bool
// };

// NavBar.defaultProps = {
//   onMobileClose: () => {},
//   openMobile: false
// };

export default NavBar;
