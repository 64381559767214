import React, { useEffect,useState} from 'react'
import clsx from 'clsx'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Button,
  Typography
} from '@material-ui/core'

import { Decimal,LiquityStoreState,Percent } from '@liquity/lib-base'
import { useLiquitySelector } from '@liquity/lib-react'
import { useLiquity } from "../../../hooks/LiquityContext";
import { useTranslation, Trans, Translation } from 'react-i18next'

import img_chart from '../../../assets/icon-07.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // item_box: {
    //   marginTop:'30px',
    //   background: 'rgba(25, 27, 31, 0.6)',
    //   boxShadow: '6px 8px 10px 0px rgba(12, 1, 47, 0.14)',
    //   borderRadius: '12px',
    //   padding:'20px 24px'
    // },
    overline_text: {
      fontSize: '28px',
      fontWeight: 600,
      color: '#36383C',
      lineHeight: '40px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '36px',
      }
    },
    ratio_chart: {
      textAlign: 'center',
      padding: '50px 30px 10px 30px',
      '&>img': {
        width:'100%',
      }
    },
    ratio_text: {
      marginTop:'10px',
      fontSize: '24px',
      fontWeight: 600,
      color: '#06D394',
      lineHeight: '29px'
    },
    border_pointer: {
      position: 'absolute',
      bottom: '2px',
      left: '50%',
      transform: 'translateX(-50%) rotate(0deg)',
      transition: '.3s all',
      width: '150px',
      height: '2px',
    },
    border: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '70px',
      height: '2px',
      background:'rgba(6, 211, 148, 1)'
    },
    point: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      border:'1px solid rgba(6, 211, 148, 1)'
    }

  })
)

const select = ({
  total,
  price,
  accountBalance,
  lusdBalance,
  lqtyBalance,
}: LiquityStoreState) => ({
  total,
  price,
  accountBalance,
  lusdBalance,
  lqtyBalance,
})

const Ratio: React.FC = () => {
  const classes = useStyles()
  let { t, i18n } = useTranslation();

  const { total, lusdBalance, price } = useLiquitySelector(
    select
  )

  const [color, setColor] = useState<string>('rgba(6, 211, 148, 1)')
  const [status, setStatus] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);

  const feeData = new Percent(total.collateralRatio(price)).prettify();
  
  useEffect(() => {
    if (status === 1) {
      setColor('rgba(245, 153, 23, 1)')
    } else if (status === 2) {
      setColor('rgba(234, 90, 90, 1)')
    } else {
      setColor('rgba(6, 211, 148, 1)')
    }
  }, [status])

  const feeProgress = ()=> {
    let feel = 0
    if (feeData.indexOf('%')) {
      // feel = Number((feeData as any).split('%')[0]) / 100
      feel = Number((feeData as any).split('%')[0])
    } else { 
      feel = Number(feeData)*100
    }
    // feel = 100;
    setProgress(feel / 1000 * 180 + 30)
    if (feel<120) {
      setStatus(2)
    } else if (feel>=120&&feel<150) {
      setStatus(2)
      // setStatus(1)
    } else if (feel>1000) {
      setStatus(0)
      setProgress(180)
    }
  }

  useEffect(() => {
    feeProgress()
  }, [lusdBalance,feeData])
 
  return (
    <>
      {/* <Box className={classes.item_box}> */}
        <Typography className={classes.overline_text}  variant="body1">
          {t('text_38')}
        </Typography>
        <Box className={classes.ratio_chart}>
          <Box style={{position:'relative',display: 'inline'}}>
            <img src={img_chart} style={{width:'100%'}} alt="" />
            <Box className={classes.border_pointer} style={{transform: `translateX(-50%) rotate(${progress}deg)`}}>
              <span className={classes.border}  style={{background:`${color}`}}></span>
              <span className={classes.point}  style={{border:`1px solid ${color}`}}></span>
            </Box>
          </Box>
          
          <Typography className={classes.ratio_text} variant="button"  style={{color:`${color}`}} display="block">
            TCR {feeData}
          </Typography>
        </Box>
      {/* </Box> */}
    </>
  )
}
export default Ratio
