import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  IconButton,
  FormHelperText
} from '@material-ui/core'
import { useTranslation, Trans, Translation } from 'react-i18next'
import { Flex, Spinner } from "theme-ui";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Decimal, Decimalish,Percent, StabilityDeposit, LiquityStoreState } from "@liquity/lib-base";
import { LiquityStoreUpdate, useLiquityReducer, useLiquitySelector } from "@liquity/lib-react";
import RedditTextField from './RedditTextField'
import { Transaction, TransactionFunction, useMyTransactionState } from "../../components/Transaction";
import { useLiquity } from "../../hooks/LiquityContext";
import { COIN, GT } from "../../strings";
import icon_T from '../../assets/icon-lqty.svg'

import {
  selectForStabilityDepositChangeValidation,
  validateStabilityDepositChange
} from "../Stability/validation/validateStabilityDepositChangeNew";
import { StabilityDepositAction } from "../Stability/StabilityDepositAction";
import { ActionDescription } from "../ActionDescription";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      width: '100%',
      marginBttom: '24px',
    },
    textField: {
      width: '100%',
      margin: '0',
    },
    helperText: {
      marginTop: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    subBtn: {
      marginTop: '16px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor: {
      color: '#fff!important',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: '#fff',
      },
    },
    footerBox: {
      marginTop:'5px',
      padding: '0 20px 30px',
      backgroundColor: 'rgba(210, 214, 220, .2)'
    },
    paper: {
      width:'360px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '24px',
      border: '2px solid #E3E3F3'
    }
  })
)

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const init = ({ stabilityDeposit }: LiquityStoreState) => ({
  originalDeposit: stabilityDeposit,
  editedLUSD: stabilityDeposit.currentLUSD,
  changePending: false
});

type StabilityDepositManagerState = ReturnType<typeof init>;
type StabilityDepositManagerAction =
  | LiquityStoreUpdate
  | { type: "startChange" | "finishChange" | "revert" }
  | { type: "setDeposit"; newValue: Decimalish };

const reduceWith = (action: StabilityDepositManagerAction) => (
  state: StabilityDepositManagerState
): StabilityDepositManagerState => reduce(state, action);

const finishChange = reduceWith({ type: "finishChange" });
const revert = reduceWith({ type: "revert" });

const reduce = (
  state: StabilityDepositManagerState,
  action: StabilityDepositManagerAction
): StabilityDepositManagerState => {
  // console.log(state);
  // console.log(action);

  const { originalDeposit, editedLUSD, changePending } = state;

  switch (action.type) {
    case "startChange":
      return { ...state, changePending: true };

    case "finishChange":
      return { ...state, changePending: false };

    case "setDeposit":
      return { ...state, editedLUSD: Decimal.from(action.newValue) };

    case "revert":
      return { ...state, editedLUSD: originalDeposit.currentLUSD };

    case "updateStore": {
      const {
        stateChange: { stabilityDeposit: updatedDeposit }
      } = action;

      if (!updatedDeposit) {
        return state;
      }

      const newState = { ...state, originalDeposit: updatedDeposit };

      const changeCommitted =
        !updatedDeposit.initialLUSD.eq(originalDeposit.initialLUSD) ||
        updatedDeposit.currentLUSD.gt(originalDeposit.currentLUSD) ||
        updatedDeposit.collateralGain.lt(originalDeposit.collateralGain) ||
        updatedDeposit.lqtyReward.lt(originalDeposit.lqtyReward);

      if (changePending && changeCommitted) {
        return finishChange(revert(newState));
      }

      return {
        ...newState,
        editedLUSD: updatedDeposit.apply(originalDeposit.whatChanged(editedLUSD))
      };
    }
  }
};

const select = ({ lusdBalance, total, price }: LiquityStoreState) => ({
  lusdBalance,
  total,
  price
});

type TroveEditorProps = {
  handleClose:() => void;
  value_index: number;
  open: boolean;
  editedL:string
}

const transactionId = "stability-deposit";

const DialogStablePool: React.FC<TroveEditorProps> = ({
  handleClose,
  value_index,
  open,
  editedL
}) => {
  const classes = useStyles()
  let { t, i18n } = useTranslation();
  const [{ originalDeposit, editedLUSD, changePending }, dispatch] = useLiquityReducer(reduce, init);

  const [lusdAmount, setLUSDAmount] = useState<string>('')

  const [editedLUSDs, setEditedLUSDs] = useState<string>(editedLUSD.toString(2))

  const { price, total, lusdBalance } = useLiquitySelector(select)
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLUSDAmount(event.target.value)
    // event.target.value && setLUSDAmount(Decimal.from(event.target.value))
  }

  useEffect(() => {
    setEditedLUSDs(editedLUSD.toString(2))
    setLUSDAmount('')
  }, [lusdBalance])

  useEffect(() => {
    dispatch({
      type: 'setDeposit',
      newValue: Number(editedLUSDs),
    })
  }, [editedLUSDs])

  useEffect(() => {
    const vals = value_index===1?editedL:(Math.floor(Number(lusdBalance)*100)/100).toString()
    if (Number(lusdAmount) > 0&&Number(lusdAmount)<=Number(vals)) {
    // if (Number(lusdAmount) > 0) {
      if (value_index === 1) {
        dispatch({
          type: 'setDeposit',
          newValue: Number(editedLUSDs) - Number(lusdAmount),
        })
      } else {
        dispatch({
          type: 'setDeposit',
          newValue: Number(lusdAmount) + Number(editedLUSDs),
        })
      }
    } else if (Number(lusdAmount)>Number(vals)) {
      if (value_index === 1) {
        dispatch({
          type: 'setDeposit',
          newValue: vals,
        })
      } else {
        dispatch({
          type: 'setDeposit',
          newValue: Number(lusdAmount) + Number(editedLUSDs),
        })
      }
    } else if (!lusdAmount) {
      dispatch({
        type: 'setDeposit',
        newValue: Number(editedLUSDs),
      })
    }
  }, [lusdAmount])

  const handleMaxClick = (str: string) => {
    const dl= value_index===1?editedL:(Math.floor(Number(lusdBalance)*100)/100).toString()
    // const dl = value_index === 1 ? editedL : lusdBalance.toString()
    setLUSDAmount(dl)
  }

  useEffect(() => {
    setLUSDAmount('')
    // setEditedLUSDs(editedLUSD.toString(2))
  }, [value_index,open])

  // 新按钮提交
  const validationContext = useLiquitySelector(selectForStabilityDepositChangeValidation);
  const [validChange, description] = validateStabilityDepositChange(
    originalDeposit,
    editedLUSD,
    validationContext
  );

  const makingNewDeposit = originalDeposit.isEmpty;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper:classes.paper }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle  id="customized-dialog-title" onClose={handleClose}>
        { value_index===1?t('text_32'):t('text_33')}
        </DialogTitle>
        <DialogContent>
          <RedditTextField
            labeltext={ value_index===1?t('text_34'):t('text_35')}
            coin_in="LUSD"
            type='number'
            coin={(<><img src={icon_T} style={{width:'18px',marginRight:'4px'}} alt=""/><Box>LUSD</Box></>)}
            endadornmenttext={ value_index===1?t('text_36'):t('text_18')}
            endadornmentvalue={value_index===1?editedL:lusdBalance.prettify()}
            onChange={handleChange}
            value={lusdAmount}
            isbtn="1"
            btntext="Max"
            btncallback={handleMaxClick}
            // defaultValue={editedLUSD.toString(2)}
            className={clsx(classes.margin, classes.textField)}
            // defaultValue="react-reddit"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ backgroundColor: '#fff' }}
          />
        </DialogContent>
          {/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button> */}
        <Box className={classes.footerBox}>

          {description
            // ??
            // (makingNewDeposit ? (
            //   <ActionDescription>Enter the amount of {COIN} you'd like to deposit.</ActionDescription>
            // ) : (
            //   <ActionDescription>Adjust the {COIN} amount to deposit or withdraw.</ActionDescription>
            // ))
          }
          
          {validChange ? (
            <StabilityDepositAction handleClose={handleClose} transactionId={transactionId} change={validChange}>
              {t('text_48')}
            </StabilityDepositAction>
          ) : (
            <Button disabled className={clsx(classes.subBtn,classes.btnColor)}>{t('text_48')}</Button>
          )}
          {/* <StabilityDepositAction {...{ originalDeposit, editedLUSD, changePending, dispatch,handleClose }}></StabilityDepositAction> */}
          <FormHelperText
            component="div"
            style={{ marginTop: '20px' }}
            className={classes.helperText}
          >
            <Typography variant="body2" gutterBottom>
            {t('text_07')}
            </Typography>
            <Typography variant="body2" gutterBottom>
            {editedL} LUSD
            </Typography>
          </FormHelperText>
        </Box>
         
      </Dialog>
    </>
  )
};

export default DialogStablePool;