import React, { useCallback} from 'react'
import clsx from 'clsx'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  IconButton,
  FormHelperText
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation, Trans, Translation } from 'react-i18next'
import { TipContext } from "./hook/TipContext";
import loading from '../../assets/loading.svg'
import error from '../../assets/error.svg'
import Toast from "../../utils/Toast/index";

import { Transaction, useMyTransactionState } from "../../components/Transaction";
import {
  LiquityStoreState,
} from '@liquity/lib-base';
import { useLiquity } from "../../hooks/LiquityContext";
import { useLiquitySelector } from '@liquity/lib-react'
import { useTroveView } from "../Trove/context/TroveViewContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding:'60px 40px',
      marginTop:'20px',
      background: '#FFFFFF',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.14)',
      borderRadius: '12px'
    },
    captionCard: {
      fontSize: '18px',
      fontWeight: 500,
      color: '#293147',
      lineHeight: '21px'
    },
    overline: {
      marginTop:'12px',
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '16px'
    },
    subBtn1: {
      marginTop: '16px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor1: {
      color: '#fff',
      backgroundColor: 'rgba(21, 66, 205, 1)',
      '&:hover': {
        backgroundColor: 'rgba(21, 66, 205, 1)',
        color: '#fff',
      },
    },
  })
)

const selector = (state: LiquityStoreState) => {
  const { collateralSurplusBalance } = state;
  return {
    collateralSurplusBalance,
    hasSurplusCollateral: !collateralSurplusBalance.isZero
  };
};

type viewType = {
  view: string;
}

export const LiquidatRedeemed: React.FC<viewType> = ({
  view
}) => {
  const {
    liquity: { send: liquity }
  } = useLiquity();

  const { dispatchEvent } = useTroveView();

  const { collateralSurplusBalance,hasSurplusCollateral } = useLiquitySelector(selector);

  let { t, i18n } = useTranslation();
  const classes = useStyles();

  const myTransactionId = "claim-coll-surplus";
  const myTransactionState = useMyTransactionState(myTransactionId);

  const handleOpenTrove = useCallback(() => {
    dispatchEvent("OPEN_TROVE_PRESSED");
  }, [dispatchEvent]);

  return (
    <>
      {
        view === 'LIQUIDATED' ? (
          <>
             <Box className={classes.card} display="flex" alignItems="center" justifyContent="center">
              <Box style={{textAlign:'center'}}>
                <Typography variant="h3" className={classes.captionCard} display="block" gutterBottom>
                Your Trove has been liquidated.
                </Typography>
                <Typography variant="caption" className={classes.overline} display="block" gutterBottom>
                {hasSurplusCollateral
                  ? t('text_68')
                  : "You can borrow LUSD by opening a Trove."}
            {/* {t('text_68')} */}
                </Typography>
                {/* {myTransactionState.type} */}
              </Box>
            </Box>
          </>
        ) : (view === 'REDEEMED' ? (
            <>
            <Box className={classes.card} display="flex" alignItems="center" justifyContent="center">
              <Box style={{textAlign:'center'}}>
                <Typography variant="h3" className={classes.captionCard} display="block" gutterBottom>
            {t('text_67')}
                </Typography>
                <Typography variant="caption" className={classes.overline} display="block" gutterBottom>
                {hasSurplusCollateral
                  ? t('text_68')
                  : "You can borrow LUSD by opening a Trove."}
            {/* {t('text_68')} */}
                </Typography>
                {/* {myTransactionState.type} */}
              </Box>
            </Box>
            </>
          ) : null)
        
      }
      {hasSurplusCollateral && myTransactionState.type !== "waitingForConfirmation" &&
    myTransactionState.type !== "confirmed"
        ? (
          <>
            <Transaction
              id={myTransactionId}
              send={liquity.claimCollateralSurplus.bind(liquity, undefined)}
            >
              <Button className={clsx(classes.subBtn1,classes.btnColor1)}>Claim {collateralSurplusBalance.prettify()} FTM</Button>
            </Transaction>
            </>
                  )
        : (
            <>
            <Button className={clsx(classes.subBtn1,classes.btnColor1)} onClick={handleOpenTrove}>Open Trove</Button>
            </>
          )}
            {/* {t('text_68')} */}
      
    </>
  )
}