import React,{useState,useEffect} from "react";
import { Flex, Container,Box } from "theme-ui";
import {BrowserRouter,Redirect, HashRouter as Router, Switch, Route,useHistory } from "react-router-dom";
import { Wallet } from "@ethersproject/wallet";

import { Decimal, Difference, Trove } from "@liquity/lib-base";
import { LiquityStoreProvider } from "@liquity/lib-react";

import { useLiquity } from "./hooks/LiquityContext";
import { TransactionMonitor } from "./components/Transaction";
import { UserAccount } from "./components/UserAccount";
import { SystemStatsPopup } from "./components/SystemStatsPopup";
import { Header } from "./components/Header";

import { PageSwitcher } from "./pages/PageSwitcher";
import { Farm } from "./pages/Farm";
import { RiskyTrovesPage } from "./pages/RiskyTrovesPage";
import { RedemptionPage } from "./pages/RedemptionPage";
import { MonitorContextTip } from "./components/new-ui/MonitorTip";

import { TroveViewProvider } from "./components/Trove/context/TroveViewProvider";
import { StabilityViewProvider } from "./components/Stability/context/StabilityViewProvider";
import { StakingViewProvider } from "./components/Staking/context/StakingViewProvider";
import { FarmViewProvider } from "./components/Farm/context/FarmViewProvider";

import DashboardIndex from './pages/new-ui/Dashboard/Index'
import Borrowing from './pages/new-ui/Borrowing'
import Liquidation from './pages/new-ui/Liquidation'
import Pledge from './pages/new-ui/Pledge'
import StablePool from './pages/new-ui/StablePool'
import EcoIndex from './pages/new-ui/eco-page/Index'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';

import { useTranslation, Trans, Translation } from 'react-i18next'

import {
  makeStyles,
  createStyles,
  Theme,
  Button,
  IconButton
  // ThemeProvider
} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topBar: {
      marginTop: '0!important',
    },
    '@media(max-width: 768px)': {
      topBar: {
        marginTop: '70px!important',
      }
    },
    bodyTop: {
      marginTop: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginTop: '0!important',
      },
    },
    closeButton: {
      position: 'absolute',
      right: '20px',
      top: '50%',
      transform:'translateY(-50%)',
      color: '#fff'
    }
  })
)

type LiquityFrontendProps = {
  loader?: React.ReactNode;
};
export const RouterBox: React.FC<LiquityFrontendProps> = ({ loader }) => {
  const history = useHistory();
  const classes = useStyles();
  const [openUi, setOpenUi] = useState<boolean>(true);
  const [hostCheck, setHostCheck] = useState<boolean>(false);
  let { t, i18n } = useTranslation();

  useEffect(() => {
    const hostName = window.location.hostname;
    if (hostName==='eth.liquity.fi') {
      setHostCheck(true)
    }
  },[])

  const handleCheckUi = () => {
    setOpenUi(!openUi)
    history.push('/')
  }

  return (
    <>
      {hostCheck && (
        <>
           <Box
        sx={{
          top:0,
          right: 0,
          position: openUi ? 'fixed' : 'relative',
          width:'100%',
          zIndex: openUi ? 1300 : 1,
          backgroundColor: '#745ddf',
          overflow:'hidden',
          // paddingTop: '10px',
          // paddingBottom: '10px',
          color: '#fff',
          fontSize: '18px',
          textAlign: 'center',
          cursor:'pointer'
            }}
            className={!openUi?classes.topBar:''}
        
          >
            <Button
              style={{color:'#fff'}}
              endIcon={<ArrowForwardIosIcon style={{fontSize:"16px"}}/>}
              onClick={handleCheckUi}>
              {t('text_64')}
              {/* {openUi ? 'Use Dev UI' : 'Return to FI UI'} */}
            </Button>

            <IconButton aria-label="close" className={classes.closeButton} onClick={()=>setHostCheck(false)}>
              <CloseIcon />
            </IconButton>
        </Box>
        </>
      )}
     
      {
        openUi ? (
          <>
          <PageSwitcher handleCheckUi={handleCheckUi} openUi={openUi} hostCheck={hostCheck}>
              <Switch>
                    <Route path="/" exact component={DashboardIndex} />
                    <Route path="/eco" component={EcoIndex} />
                    <Route path="/trove" component={Borrowing} />
                    <Route path="/pool" component={Liquidation} />
                    <Route path="/stake" component={Pledge} />
                    <Route path="/liquidation" component={StablePool} />
                    <Redirect from="*" to='/'/>
              </Switch>
          </PageSwitcher>
          </>
        ): (
            <>
                <Flex sx={{ flexDirection: "column", minHeight: "100%",}}>
                  <Header>
                    <UserAccount />
                    <SystemStatsPopup />
                  </Header>

                <Container
                  variant="main"
                  className={classes.bodyTop}
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop:'16px'
                    }}
                  >
                    <Switch>
                      <Route path="/" exact>
                        <PageSwitcher openUi={openUi}  hostCheck={hostCheck}/>
                      </Route>
                      <Route path="/farm">
                        <Farm />
                      </Route>
                      <Route path="/risky-troves">
                        <RiskyTrovesPage />
                      </Route>
                      <Route path="/redemption">
                        <RedemptionPage />
                      </Route>
                    </Switch>
                  </Container>
                </Flex>
          </>
        )
      }
      </>
  );
};
