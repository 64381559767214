import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  IconButton,
  FormHelperText
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation, Trans, Translation } from 'react-i18next'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'

import icon_09 from '../../assets/icon-09.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width:'360px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '24px',
      border: '2px solid #E3E3F3'
    },
    box: {
      padding: '10px',
      borderRadius: '8px',
      border: '1px solid #D2D6DC'
    },
    body2: {
      fontSize: '12px',
      fontWeight: 400,
      color: '#545A6C',
      lineHeight: '17px'
    },
    icon: {
      width: '16px',
      height: '16px',
      marginRight: '4px',
      borderRadius: '50%',
      background:
        'linear-gradient(180deg, #E02020 0%, #FA6400 17%, #F7B500 33%, #6DD400 50%, #0091FF 67%, #6236FF 83%, #B620E0 100%)',
    },
    btn_out: {
      padding:'4px 13px',
      borderRadius: '14px',
      border: '1px solid #1542CD',
      fontSize: '14px',
      fontWeight: 500,
      color: 'rgba(21, 66, 205, 1)',
      lineHeight: '20px'
    },
    detail_btn: {
      marginTop:'10px'
    },
    btn_link: {
      fontSize: '12px',
      fontWeight: 500,
      color: 'rgba(84, 90, 108, 1)',
      lineHeight: '17px'
    },
    footer: {
      padding:'20px',
      background:'rgba(210, 214, 220, .2)',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '17px'
    }
  })
)

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

// const select = ({ lusdBalance}: LiquityStoreState) => ({
//   lusdBalance,
// });

type TroveEditorProps = {
  handleClose:() => void;
  account: string;
  open: boolean;
}

const DialogOut: React.FC<TroveEditorProps> = ({
  handleClose,
  account,
  open
}) => {
  const { connector, library, chainId, activate, deactivate, active, error } = useWeb3React<Web3Provider>()
  const classes = useStyles()
  let { t, i18n } = useTranslation();
  console.log(123, connector,'1',library,'2',chainId,'3',account,'4',activate,'5',deactivate,'6',active,'7',error)
  const handeClick = () => {
    const isMetaMask = (library as any).provider.isMetaMask||false;
    // const isWalletConnect=(library as any).provider.isWalletConnect||false;
    if (isMetaMask) {
      deactivate()
    } else {
      (connector as any).close()
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper:classes.paper }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle  id="customized-dialog-title" onClose={handleClose}>
        {t('text_69')}
        </DialogTitle>
        <DialogContent style={{padding:'4px 20px 24px'}}>
          <Box className={classes.box}>
            <Box display="flex" justifyContent="space-between" alignItems="start" >
              <Box >
                  <Typography variant="body1" className={classes.body2}>
                  {t('text_70')}
                      {/* {t('text_13')} */}
                </Typography>
                <Box
                  component="span"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  style={{marginTop:'6px'}}
                >
                    <Box className={classes.icon}></Box>{account}
                </Box>
              </Box>
              <Button variant="outlined" className={classes.btn_out} onClick={()=>handeClick()}>{t('text_71')}</Button>
            </Box>
            {/* <Box className={classes.detail_btn}>
              <Button className={classes.btn_link} startIcon={<img style={{ width: '12px', height: '12px' }} src={icon_09} alt="" />}>
                复制地址
              </Button>
              <Button className={classes.btn_link} style={{marginLeft:'24px'}} startIcon={<img style={{ width: '12px', height: '12px' }} src={icon_09} alt="" />}>
              查看 Etherscan
              </Button>
            </Box> */}
          </Box>
        </DialogContent>
        {/* <Box className={classes.footer}>
        你交易将展示在这….
        </Box> */}
      </Dialog>
    </>
  )
};

export default DialogOut;