
const FI = {
  text_01:"Déposer",
  text_02: "Retirer",
  text_03: "Rachat ",
  text_04: "Total des participations",
  text_05: "",
  text_06: "",
  text_07: "participation",
  text_08: "participation ",
  text_09: "Emprunter",
  text_091: "Emprunter",
  text_092: "Emprunter",
  text_10: "Pool de stabilité",
  text_11: "participation",
  text_12: "Liquidation",
  text_13: "LQTY restant",
  text_14: "Récompense",
  text_15: "Retirer",
  text_16: "Déposer",
  text_17: "Disponible: ",
  text_18: "Portefeuille: ",
  text_19: "Rembourser",
  text_20: "La dette doit être d'au moins 2000 LUSD.",
  text_21: "Bien que le Ratio de garantie minimum soit de 110 % en fonctionnement normal, il est recommandé de maintenir le Ratio de garantie toujours supérieur à 150 % pour éviter la liquidation en Mode de récupération. Un Ratio de garantie supérieur à 200 % ou 250 % est recommandé pour une sécurité supplémentaire.",
  text_22: "Disponible: ",
  text_23: "Assurez-vous d'avoir suffisamment de LUSD avant de fermer le trove.",
  text_24: "Fermer le trove",
  text_25: "Ces frais couvrent le coût du gas qu'un liquidateur paierait pour liquider votre Trove. Ces frais vous sont remboursés lorsque vous remboursez votre dette.",
  text_26: "Ratio de garantie",
  // text_261: "Ratio de garantie",
  text_261: "Ratio",
  text_27: "Rachat",
  text_28: "Dette",
  text_29: "Rembourser",
  text_30: "Retirer",
  text_31: "Fermer le Trove",
  text_32: "Désinvestir",
  text_33: "Investir",
  text_34: "Retirer",
  text_35: "Investir",
  text_36: "Disponible: ",
  // text_37: "Offre totale de LUSD",
  // text_38: "Ratio de garantie total",
  text_37: "Total LUSD supply",
  text_38: "Total Collateral Ratio",
  text_39: "Prix",
  text_40: " APR ",
  text_41: "Titulaire",
  text_42: "participation (ETH)",
  text_43: "Emprunter (LUSD)",
  text_44: "Ratio de garantie",
  text_45: "Statut",
  text_46: "Liquider",
  text_lp: "Les récompenses LQTY de ce pool dureront six semaines.",
  text_47: "Les récompenses LQTY de ce pool dureront 35 mois.",
  text_48: "Confirmer",
  text_49: "Confirmez",
  text_50: "En attente",
  text_51: "Success",
  text_52: "Transaction Confirmed",
  text_53: "Failure ",
  text_54: "Please try again.",
  text_55: "Error",
  text_56: "Transaction Rejected",
  text_57: "Transaction",
  text_58: "Réserve de liquidation",
  text_59: "La dette doit être d'au moins",
  text_591: ".",
  text_60: "Frais",
  text_61: "An estimate of the LUSD&ETH return on the LQTY staked to this pool.（Calculation in LUSD)",
  text_62: "Liquity Analysis",
  text_63: "Switch to DevUI",
  text_64: "Switch to FiUI",
  text_65: "Redeem",
  text_67: "Your Trove has been redeemed",
  text_68: "Please reclaim your remaining collateral before opening a new Trove.",
  text_69: "Account",
  text_70: "Connected ",
  text_71: "Change",
  text_720: "The total amount of LUSD your Trove will hold. You will need to repay ",
  text_721: " LUSD to reclaim your collateral (200 LUSD Liquidation Reserve excluded).",
  text_730: "Note: ",
  text_731: "Redemption is not for repaying your loan. To repay your loan, switch to",
  text_732: "Withdraw tab.",
  text_91: "Normal Mode:",
  text_92: "Recovery Mode:",
  text_93: "Recovery Mode Price :",
  text_94: "The dollar value per unit of collateral at which your Trove will drop below a 110% Collateral Ratio and be liquidated. You should ensure you are comfortable with managing your position so that the price of your collateral never reaches this level.",
  text_95: "Liquidation price",
  text_96: "Price",
  text_97: "Currently Burned",
  text_99: "Add",
  text_100: "Buy",
  text_102: "My Wallet",
  text_118: "Market Cap",
  text_119: "Circulating Supply",
  text_127: "Statistics",
  text_128: "TVL",
  text_120: "The amount of LQTY  that are circulating in the market. Formula = (Circulating Supply / Total Supply)*100%",
  text_371: "Total supply",
  text_041: "LUSD in Stability Pool",
  text_863: "Home",
  text_123: "Liquity 生态",
}
export default FI;