// import { Button } from "theme-ui";
import { useContext } from 'react'
import {
  Button,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import { TipContext } from "../new-ui/hook/TipContext";
import { useTranslation, Trans, Translation } from 'react-i18next'

import { Decimal } from "@liquity/lib-base";

import { useLiquity } from "../../hooks/LiquityContext";
import { useTransactionFunction } from "../Transaction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subBtn: {
      marginTop: '16px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(21, 66, 205, 1)',
      '&:hover': {
        backgroundColor: 'rgba(21, 66, 205, 1)',
        color: '#fff',
      },
    },
    disabledColor: {
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: '#fff',
      },
    }
  })
)

type RedemptionActionProps = {
  transactionId: string;
  disabled?: boolean;
  lusdAmount: Decimal;
  maxRedemptionRate: Decimal;
};

export const RedemptionAction: React.FC<RedemptionActionProps> = ({
  transactionId,
  disabled,
  lusdAmount,
  maxRedemptionRate
}) => {
  const {
    liquity: { send: liquity }
  } = useLiquity();
  const classes = useStyles()
  let { t, i18n } = useTranslation();

  const Tip = useContext(TipContext);

  const [sendTransaction] = useTransactionFunction(
    transactionId,
    liquity.redeemLUSD.bind(liquity, lusdAmount, maxRedemptionRate)
  );

  const handleClick = () => {
    sendTransaction();
    
    Tip?.dispatchEvent(true)
  }

  return (
    <Button disabled={disabled} className={clsx(classes.subBtn,disabled?classes.disabledColor:classes.btnColor)}  onClick={handleClick}>
      {t('text_48')}
    </Button>
  );
};
