import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import clsx from 'clsx'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Typography
} from '@material-ui/core'

import { Decimal,LiquityStoreState } from '@liquity/lib-base'
import { useLiquitySelector } from '@liquity/lib-react'
import { useTranslation, Trans, Translation } from 'react-i18next'

import img_icon from '../../../assets/icon-wallet.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // item_box: {
    //   marginTop:'30px',
    //   background: 'rgba(25, 27, 31, 0.6)',
    //   boxShadow: '6px 8px 10px 0px rgba(12, 1, 47, 0.14)',
    //   borderRadius: '12px',
    //   padding:'20px 24px'
    // },
    img_icon: {
      fontSize: '28px',
      fontWeight: 600,
      color: '#36383C',
      lineHeight: '40px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '36px',
      }
    },
    overline_text: {
      fontSize: '16px',
      fontWeight: 400,
      color: '#545A6C',
      lineHeight: '20px'
    },
    button_text: {
      marginTop: '4px',
      fontSize: '20px',
      fontWeight: 600,
      color: '#36383C',
      lineHeight: '30px'
    }
  })
)

const select = ({
  lusdBalance,
  lqtyBalance
}: LiquityStoreState) => ({
  lusdBalance,
  lqtyBalance
})

const Ratio: React.FC = () => {
  const classes = useStyles();
  let { t, i18n } = useTranslation();
  const {lusdBalance, lqtyBalance } = useLiquitySelector(select)

  return (
    <>
      {/* <Box className={classes.item_box}> */}
        <Box
          display="flex" justifyContent="flex-start" alignItems="center"
          className={classes.img_icon}
        >
         {t('text_102')}
          <img style={{marginLeft:'16px'}} src={ img_icon } alt="" />
        </Box>
        <Box
          style={{marginTop:'20px'}}
          display="flex" justifyContent="space-between" alignItems="center"
        >
          <Box>
            <Typography className={classes.overline_text}  variant="body1" display="block">
            LQTY
            </Typography>
            <Typography className={classes.button_text} variant="button" display="block">
              {lqtyBalance.prettify()}
            </Typography>
          </Box>

          <Box>
            <Typography className={classes.overline_text}  variant="body1" display="block">
            LUSD
            </Typography>
            <Typography className={classes.button_text} variant="button" display="block">
              {lusdBalance.prettify()}
            </Typography>
          </Box>
        </Box>
      {/* </Box> */}
    </>
  )
}
export default Ratio
