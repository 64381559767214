import React, { useState, useEffect } from 'react'
import {
  Link as RouterLink,
  MemoryRouter as Router,
  LinkProps as RouterLinkProps,
  useHistory,
} from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  TextField,
  TextFieldProps,
  InputAdornment,
  Tooltip,
  Grid,
  OutlinedInputProps,
} from '@material-ui/core'
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress'
import {
  LiquityStoreUpdate,
  useLiquityReducer,
  useLiquitySelector,
} from '@liquity/lib-react'
import {
  LUSD_LIQUIDATION_RESERVE,
  LiquityStoreState,
  Decimal,
  Decimalish,
  Percent,
  Trove,
  TroveChange,
} from '@liquity/lib-base'
import { useTranslation, Trans, Translation } from 'react-i18next'
import tip from '../../assets/tip.svg'

import common from '../../utils/common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circular: {
      position: 'absolute',
      left: 0,
      color: 'rgba(6, 211, 148, 1)',
    },
    bottom: {
      color: 'rgba(210, 214, 220, 1)',
    },
    circle: {
      strokeLinecap: 'round',
    },
    boxTop: {
      padding: '30px 20px 20px',
      background: 'rgba(210, 214, 220, .15)',
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: 600,
      color: '#1F2533',
      lineHeight: '22px',
    },
    caption: {
      fontSize: '12px',
      color: '#1F2533',
      lineHeight: '17px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#745DDF',
      lineHeight: '20px',
      marginBottom: '15px',
      marginTop: '10px',
    },
    tip: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '17px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '9px',
      border: '1px solid #E3E3F3'
    }
  })
)

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; fee: any; status: number}
) {
  const classes = useStyles()
  let { t, i18n } = useTranslation();
  const [openTip, setOpenTip] = React.useState(false);
  const [text, setText] = useState<string>(t('text_21'));
  useEffect(() => {
    setText(t('text_21'))
  }, [i18n.language])
  const [color, setColor] = useState<string>('rgba(6, 211, 148, 1)')
  useEffect(() => {
    if (props.status === 1) {
      setColor('rgba(245, 153, 23, 1)')
    } else if (props.status === 2) {
      setColor('rgba(234, 90, 90, 1)')
    }
  }, [props.status])
  
  // const text_40 = t('text_21');
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size="100px"
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        size="100px"
        className={classes.circular}
        variant="determinate"
        thickness={4}
        style={{color:color}}
        classes={{
          circle: classes.circle,
        }}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box style={{textAlign:'center'}}>
          <Typography variant="caption" component="div" color="textSecondary">
            {`${props.fee}`}
          </Typography>
          <Typography variant="button" style={{marginBottom:'0',fontSize:'12px',maxWidth: '70px'}} display="block" gutterBottom>
            {t('text_261')}
            <Tooltip
              classes={{
                tooltip:classes.tip
              }}
              onClose={()=>setOpenTip(false)}
              open={openTip}
              title={text}
              interactive placement="top">
              <img src={tip}  onClick={()=>setOpenTip(true)} style={{width:'14px', marginLeft: '2px',marginBottom:'-2px'}} alt=""/>
            </Tooltip>
          </Typography>
        </Box>
        
      </Box>
    </Box>
  )
}

// const select = ({ price }: LiquityStoreState) => ({
//   price,
// })

const select = ({
  trove,fees,
  price,
  accountBalance,
  lusdBalance,
  lqtyBalance,
}: LiquityStoreState) => ({
  trove,fees,
  price,
  accountBalance,
  lusdBalance,
  lqtyBalance,
  })

type TroveEditorProps = {
  original: Trove
  edited: Trove
  afterFee: Trove
  borrowingRate: Decimal
  change?: TroveChange<Decimal>
  changePending: boolean
  dispatch: (
    action:
      | { type: 'setCollateral' | 'setDebt'; newValue: Decimalish }
      | { type: 'revert' }
  ) => void
}

const CircularProgressTroves: React.FC<TroveEditorProps> = ({
  children,
  original,
  edited,
  afterFee,
  borrowingRate,
  change,
  changePending,
  dispatch,
}) => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0)
  let { t, i18n } = useTranslation();

  const [openTip, setOpenTip] = React.useState(false);
  const [text, setText] = useState<any>({
    text01: t('text_720'),
    text02: t('text_721')
  });
  useEffect(() => {
    setText({
      text01: t('text_720'),
      text02: t('text_721')
    })
  }, [i18n.language])

  const { trove,fees,accountBalance, lusdBalance, price } = useLiquitySelector(
    select
  )

  const [editedPrice, setEditedPrice] = useState(price.toString(2))
  const [status, setStatus] = useState(0)

  const collateralRatio = !trove.isEmpty
    ? trove.collateralRatio(price)
    : undefined
  const collateralRatioPct = new Percent(
    collateralRatio || { toString: () => '- -' }
  )

  const feeData = collateralRatio?.gt(10)
    ? '× ' + collateralRatio.shorten()
    : collateralRatioPct.prettify()

  const [value, setValue] = useState({
    collateral: edited.collateral.toString(4),
    debt: edited.debt.toString(2),
    feeData: feeData,
  })

  useEffect(() => {
    setEditedPrice(price.toString(2))
  }, [price])
  
  useEffect(() => {
    setValue({
      collateral: edited.collateral.toString(4),
      debt: edited.debt.toString(2),
      feeData: feeData,
    })
    feeProgress()
  }, [accountBalance, lusdBalance])

  const lusdNum = (Number(editedPrice) * Number(trove.collateral)).toFixed(2)

  // const fee = isDebtIncrease
  //   ? feeFrom(trove, new Trove(trove.collateral, trove.debt.add(debtIncreaseAmount)), borrowingRate)
  //   : Decimal.ZERO;
  
  // const totalDebt = trove.netDebt.add(LUSD_LIQUIDATION_RESERVE).add(fee);
  // const fee = afterFee.subtract(edited).debt.nonZero
  const feeProgress = ()=> {
    let feel = 0
    if (feeData.indexOf('%')) {
      // feel = Number((feeData as any).split('%')[0]) / 100
      feel = Number((feeData as any).split('%')[0])
    } else { 
      feel = Number(feeData)*100
    }
      setProgress(feel / 360 * 100)
      if (feel<120) {
        setStatus(2)
      } else if (feel>=120&&feel<150) {
        setStatus(1)
      }
  }

  React.useEffect(() => {
    feeProgress()
  }, [trove.debt])

  return (
    <Box className={classes.boxTop}>
      <Grid container spacing={2} justify="space-between">
        <Grid item>
          <Box>
            <Typography
              variant="body2"
              component="p"
              className={classes.body2}
              gutterBottom
            >
              {t('text_16')}(ETH)
            </Typography>

            <Typography variant="subtitle2" className={classes.subtitle2}>
              {common.numberDecimal(Number(trove.collateral),4)}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              className={classes.caption}
            >
              ≈ {lusdNum} LUSD
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <CircularProgressWithLabel status={status} value={progress} fee={feeData} />
        </Grid>
        <Grid item>
          <Box>
            <Typography
              variant="body2"
              component="p"
              className={classes.body2}
              gutterBottom
            >
              {t('text_28')}(LUSD)
              <Tooltip
              classes={{
                tooltip:classes.tip
              }}
              onClose={()=>setOpenTip(false)}
              open={openTip}
              title={text.text01+(Number(trove.debt)>0?trove.debt.sub(Decimal.from(200)).prettify(2):'0')+text.text02}
              interactive placement="top">
              <img src={tip}  onClick={()=>setOpenTip(true)} style={{width:'14px', marginLeft: '2px',marginBottom:'-2px'}} alt=""/>
            </Tooltip>
            </Typography>

            <Typography variant="subtitle2" className={classes.subtitle2}>
              {trove.debt.prettify(2)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CircularProgressTroves
