import React, { useEffect} from 'react'
import clsx from 'clsx'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  FormControl,
  Input,
  InputAdornment,
  TextField,
  Card,
  CardContent,
  OutlinedInput,
  fade,
  TextFieldProps,
  OutlinedInputProps,
  InputLabel,
  Button,
  Tabs,
  Tab,
} from '@material-ui/core'
import {
  LUSD_LIQUIDATION_RESERVE,
  LiquityStoreState,
  Decimal,
  Decimalish,
  LUSD_MINIMUM_DEBT,
  Trove
} from '@liquity/lib-base'
import {
  LiquityStoreUpdate,
  useLiquityReducer,
  useLiquitySelector,
} from '@liquity/lib-react'
import { useTranslation, Trans, Translation } from 'react-i18next'
import OverShadomBox from '../../components/new-ui/OverShadomBox'
import SystemStatsCard from '../../components/new-ui/SystemStatsCard'
import CircularProgressTroves from '../../components/new-ui/CircularProgressTroves'
import TroveAdjusting from '../../components/new-ui/TroveAdjusting'
import TroveOpen from '../../components/new-ui/TroveOpen'

import ChangeETH from '../../components/new-ui/ChangeETH'
// import { TroveAction } from '../../components/TroveAction'
import { TroveAction } from '../../components/Trove/TroveActionNew'
import { CollateralSurplusAction } from '../../components/CollateralSurplusAction'
import {
  selectForTroveChangeValidation,
  validateTroveChange
} from "../../components/Trove/validation/validateTroveChangeNew";
import { ActionDescription } from "../../components/ActionDescription";

import { useTroveView } from "../../components/Trove/context/TroveViewContext";

import icon_01 from '../../assets/icon-01.png'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
    },
    back: {
      background: '#fff',
      overflow: 'hidden',
      // padding: '30px 20px',
      // height: '100px',
    },
    box: {
      marginTop: '20px',
      [theme.breakpoints.down('xs')]: {
        marginTop: '0',
      },
    },
    boxForm: {
      padding: '0 20px 40px',
    },
    selected: {
      color: 'rgba(31, 37, 51, 1)',
    },
    TabRoot: {
      minWidth: 'auto',
      flex: 1
    },
    indicators: {
      backgroundColor: 'rgba(41, 49, 71, 1)',
    },
    grid: {
      [theme.breakpoints.up('xs')]: {
        maxWidth:'444px'
      },
    },
    subBtn: {
      marginTop: '16px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: '#fff',
      },
    },
  })
)

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const init = ({ trove }: LiquityStoreState) => ({
  original: trove,
  edited: new Trove(trove.collateral, trove.debt),
  changePending: false,
  debtDirty: false,
  addedMinimumDebt: false
});

type TroveManagerState = ReturnType<typeof init>;
type TroveManagerAction =
  | LiquityStoreUpdate
  | { type: "startChange" | "finishChange" | "revert" | "addMinimumDebt" | "removeMinimumDebt" }
  | { type: "setCollateral" | "setDebt"; newValue: Decimalish };

const reduceWith = (action: TroveManagerAction) => (state: TroveManagerState): TroveManagerState =>
  reduce(state, action);

const addMinimumDebt = reduceWith({ type: "addMinimumDebt" });
const removeMinimumDebt = reduceWith({ type: "removeMinimumDebt" });
const finishChange = reduceWith({ type: "finishChange" });
const revert = reduceWith({ type: "revert" });

const reduce = (state: TroveManagerState, action: TroveManagerAction): TroveManagerState => {
  // console.log(state);
  // console.log(action);

  const { original, edited, changePending, debtDirty, addedMinimumDebt } = state;

  switch (action.type) {
    case "startChange":
      return { ...state, changePending: true };

    case "finishChange":
      return { ...state, changePending: false };

    case "setCollateral": {
      const newCollateral = Decimal.from(action.newValue);

      const newState = { ...state, edited: edited.setCollateral(newCollateral) };

      if (!debtDirty) {
        if (edited.isEmpty && newCollateral.nonZero) {
          return addMinimumDebt(newState);
        }
        if (addedMinimumDebt && newCollateral.isZero) {
          return removeMinimumDebt(newState);
        }
      }

      return newState;
    }

    case "setDebt":
      return { ...state, edited: edited.setDebt(action.newValue), debtDirty: true };

    case "addMinimumDebt":
      return {
        ...state,
        edited: edited.setDebt(LUSD_MINIMUM_DEBT),
        addedMinimumDebt: true
      };

    case "removeMinimumDebt":
      return {
        ...state,
        edited: edited.setDebt(0),
        addedMinimumDebt: false
      };

    case "revert":
      return {
        ...state,
        edited: new Trove(original.collateral, original.debt),
        debtDirty: false,
        addedMinimumDebt: false
      };

    case "updateStore": {
      const {
        newState: { trove },
        stateChange: { troveBeforeRedistribution: changeCommitted }
      } = action;

      const newState = { ...state, original: trove };

      if (changePending && changeCommitted) {
        return finishChange(revert(newState));
      }

      const change = original.whatChanged(edited, 0);

      if (
        (change?.type === "creation" && !trove.isEmpty) ||
        (change?.type === "closure" && trove.isEmpty)
      ) {
        return revert(newState);
      }

      return { ...newState, edited: trove.apply(change, 0) };
    }
  }
};

const select = (state: LiquityStoreState) => ({
  fees: state.fees,
  trove: state.trove,
  validationContext: selectForTroveChangeValidation(state)
  // redeemed: !collateralSurplusBalance.isZero,
})
const transactionIdPrefix = "trove-";

const Borrowing: React.FC = () => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const { view } = useTroveView();

  let { t, i18n } = useTranslation();

  const [{ original, edited, changePending }, dispatch] = useLiquityReducer(
    reduce,
    init
  )
  const {trove, fees ,validationContext} = useLiquitySelector(select)
  
  const borrowingRate = fees.borrowingRate()
  const change = original.whatChanged(edited, borrowingRate)
  const afterFee = original.apply(change, borrowingRate)
  const maxBorrowingRate = borrowingRate.add(0.005); // TODO slippage tolerance

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeTab = (newValue:number) => {
    setValue(newValue)
  }

  // useEffect(() => {
  //   // if (collateral !== undefined) {
  //     dispatch({ type: "setCollateral", newValue: Decimal.ZERO });
  //   // }
  //   // if (debt !== undefined) {
  //     dispatch({ type: "setDebt", newValue: Decimal.ZERO });
  //   // }
  // }, [dispatch]);

  const [validChange, description] = validateTroveChange(
    original,
    edited,
    borrowingRate,
    validationContext
  );

  const openingNewTrove = original.isEmpty;

  return (
    <>
      
      <Grid
        container
        spacing={3}
        className={classes.box}
        justify="center"
      >
        <Grid item xs={12}  className={classes.grid}>
        <SystemStatsCard></SystemStatsCard>
          <OverShadomBox className={classes.back}>
            <Box>
              <CircularProgressTroves
                {...{
                  original,
                  edited,
                  afterFee,
                  borrowingRate,
                  change,
                  changePending,
                  dispatch,
                }}
              ></CircularProgressTroves>
              <Box className={classes.boxForm}>
                <Box
                  style={{ borderBottom: '1px solid rgba(210, 214, 220, 1)' }}
                >
                  <Tabs
                    scrollButtons="off"
                    value={value}
                    onChange={handleChange}
                    // indicatorColor="primary"
                    // textColor="primary"
                    classes={{ indicator: classes.indicators }}
                    centered
                  >
                    <Tab
                      classes={{
                        root:classes.TabRoot
                      }}
                      // classes={{ selected: classes.selected }}
                      label={t('text_01')}
                    />
                    <Tab
                      classes={{
                        root:classes.TabRoot
                      }}
                      label={t('text_02')} />
                    <Tab
                      classes={{
                        root:classes.TabRoot
                      }}
                      label={t('text_03')} />
                  </Tabs>
                </Box>
                {
                  value !== 2 ? (
                    <>
                      {
                        view === 'ACTIVE' || view === 'ADJUSTING' ? (
                          <TroveAdjusting
                            {...{
                              original,
                              edited,
                              afterFee,
                              borrowingRate,
                              change,
                              changePending,
                              dispatch,
                              value,
                              validChange
                            }}
                          >
                          </TroveAdjusting>
                        ): (
                            <TroveOpen
                            {...{
                              original,
                              edited,
                              afterFee,
                              borrowingRate,
                              change,
                              changePending,
                              dispatch,
                              value,
                              validChange
                            }}
                            ></TroveOpen>
                        )
                      }
                      </>
                      ): (
                         <>
                        <ChangeETH handleChangeTab={ handleChangeTab }/>
                      </>
                  )
                }
                
                  
              </Box>
            </Box>
          </OverShadomBox>
          
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6}>
          <OverShadomBox className={classes.back}>
            <ChangeETH
              {...{
                borrowingRate,
              }}
            ></ChangeETH>
          </OverShadomBox>
        </Grid> */}
      </Grid>
    </>
  )
}
export default Borrowing
