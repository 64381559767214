
const zh_HK = {
  text_01:"抵押&借款",
  text_02: "贖回&還款",
  text_03: "兌換 ",
  text_04: "全網總質押數",
  text_05: " 收益",
  text_06: " 獎勵",
  text_07: "已質押",
  text_08: "質押 ",
  text_09: "借貸",
  text_091: "借貸",
  text_092: "收到",
  text_10: "穩定池",
  text_11: "質押",
  text_12: "清算",
  text_13: "剩余獎勵LQTY",
  text_14: "收益",
  text_15: "贖回",
  text_16: "抵押",
  text_17: "可取：",
  text_18: "錢包：",
  text_19: "還款",
  text_20: "至少借貸2000 LUSD ",
  text_21: "在正常操作中最低抵押物比率為110％，但建議將抵押物比率始終保持在150％以上，以避免在恢復模式下清算。參考安全的值為200%-250%。",
  text_22: "可借：",
  text_23: "贖回所有抵押需要確保足夠的LUSD",
  text_24: "一鍵贖回抵押",
  text_25: "當開啟抵押訂單時，合約會暫扣200 LUSD作為清算GAS費用。當你贖回所有抵押物時，該費用會自動抵扣還款金額。",
  text_26: "抵押率",
  text_261: "抵押率",
  text_27: "兌換",
  text_28: "借貸",
  text_29: "歸還",
  text_30: "取出",
  text_31: "立即贖回",
  text_32: "提取質押",
  text_33: "添加質押",
  text_34: "提取",
  text_35: "添加",
  text_36: "可用：",
  text_37: "LUSD 總供應量",
  text_38: "全網抵押率",
  text_39: "價格",
  text_40: " 年化",
  text_41: "擁有者",
  text_42: "質押 (ETH)",
  text_43: "借貸 (LUSD)",
  text_44: "抵押率",
  text_45: "操作",
  text_46: "清算",
  text_lp: "該APY為UNI-LP挖取LQTY獎勵的預估統計,LP挖礦獎勵時間為6周。",
  text_47: "該APY僅為穩定池質押時獲得LQTY預估統計,該池持續35個月。",
  text_48: "確認",
  text_49: "確認交易",
  text_50: "待處理交易",
  text_51: "成功",
  text_52: "交易確認",
  text_53: "失敗",
  text_54: "請再次嘗試",
  text_55: "錯誤",
  text_56: "交易被拒絕",
  text_57: "交易廣播",
  text_58: "清算押金",
  text_59: "最低借款",
  text_591: ".",
  text_60: "手續費",
  text_61: "此APR為質押LQTY的估算年華數據，計算方式以LUSD為單位。",
  text_62: "Liquity 分析",
  text_63: "切換 DevUI",
  text_64: "切換 FiUI",
  text_65: "兑换",
  text_67: "你的抵押物已被系統兌換！",
  text_68: "在開啟新的抵押前，請取出系統中保留的抵押物。",
  text_69: "账户",
  text_70: "链接",
  text_71: "更换",
  text_720: "这是您当前持有的总债务金额。如需赎回所有抵押物，只需要偿还",
  text_721: "LUSD。（其中200LUSD的清算押金将被直接抵用）",
  text_730: "注意：",
  text_731: "该功能不是偿还债务或撤出抵押物，需要偿还或撤出请切换至",
  text_732: "赎回&还款",
  text_91: "正常模式",
  text_92: "恢复模式",
  text_93: "恢复模式触发价：",
  text_94: "当抵押物价格为此时，您的借贷抵押率将跌至110%，面临清算风险。请时刻注意管理你的头寸。",
  text_95: "清算价格",
  text_96: "价格",
  text_97: "销毁",
  text_99: "添加",
  text_100: "购买",
  text_102: "我的钱包",
  text_118: "流动市值",
  text_119: "流通量",
  text_127: "数据面板",
  text_128: "总锁仓量",
  text_120: "市场中流通的LQTY数量和占比。公式=（流通量/总量）*100%",
  text_371: "总供应量",
  text_041: "稳定池质押",
  text_863: "首页",
  text_123: "Liquity 生态",
}
export default zh_HK;