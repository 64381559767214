import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from "i18next";
import enUsTrans from "../locale/en_US";
import zhCnTrans from "../locale/zh_CN";
import zhHKTrans from "../locale/zh_HK";
import ESTrans from "../locale/es_LN";
import PTTrans from "../locale/pt_LN";
import FITrans from "../locale/fi_LN";
import DETrans from "../locale/de_LN";
import KOTrans from "../locale/ko_LN";

import {
  initReactI18next
} from 'react-i18next';

i18n.use(LanguageDetector) //嗅探当前浏览器语言
.use(initReactI18next) //init i18next
.init({
  //引入资源文件
  resources: {
    en: {
      translation: enUsTrans,
    },
    zh_CN: {
      translation: zhCnTrans,
    },
    zh_HK: {
      translation: zhHKTrans,
    },
    ES: {
      translation: ESTrans,
    },
    PT: {
      translation: PTTrans,
    },
    FI: {
      translation: FITrans,
    },
    DE: {
      translation: DETrans,
    },
    KO: {
      translation: KOTrans,
    }
  },
  //选择默认语言，选择内容为上述配置中的key，即en/zh
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

export default i18n;