import React,{useContext} from "react";
import clsx from 'clsx'
import {
  Button,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { TipContext } from "../../new-ui/hook/TipContext";
import { useLiquity } from "../../../hooks/LiquityContext";
import { useTransactionFunction } from "../../Transaction";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subBtn: {
      borderRadius: '8px',
      marginTop:'20px',
      padding: '12px',
      fontSize: '16px',
      width:'100%',
      // fontWeight: 500,
    },
    outlined: {
      border: '1px solid #1542CD',
      color: 'rgba(21, 66, 205, 1)',
    },
  })
)
type ClaimAndMoveProps = {
  disabled?: boolean;
};

export const ClaimAndMove: React.FC<ClaimAndMoveProps> = ({ disabled, children }) => {
  const { liquity } = useLiquity();
  const classes = useStyles()
  const Tip = useContext(TipContext);

  const [sendTransaction] = useTransactionFunction(
    "stability-deposit",
    liquity.send.transferCollateralGainToTrove.bind(liquity.send)
  );

  const handleClick = () => {
    sendTransaction();
    
    Tip?.dispatchEvent(true)
  }

  return (
    <Button
      variant="outlined"
      // sx={{ mt: 3, width: "100%" }}
      onClick={handleClick}
      disabled={disabled}
      className={clsx(classes.subBtn,classes.outlined)}
    >
      {children}
    </Button>
  );
};
