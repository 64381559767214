import { useEffect, useState } from "react";
import { AddressZero } from "@ethersproject/constants";

import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { useHistory } from "react-router-dom";

import { useLiquity } from "../hooks/LiquityContext";

import { Container } from "theme-ui";
import { Dashboard } from "./Dashboard";
import { UnregisteredFrontend } from "./UnregisteredFrontend";
import { FrontendRegistration } from "./FrontendRegistration";
import { FrontendRegistrationSuccess } from "./FrontendRegistrationSuccess";

import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Box,
  Button,
  Typography
  // ThemeProvider
} from '@material-ui/core'
import clsx from 'clsx'
import NavBar from '../components/new-ui/NavBar'
import { TopBar } from '../components/new-ui/TopBar'
import icon_ac from '../assets/icon-ac.png';

const selectFrontend = ({ frontend }: LiquityStoreState) => frontend;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // background:
      //   'linear-gradient(198deg, rgba(155, 88, 243, 0.17) 0%, rgba(53, 78, 247, 0.06) 45%, rgba(255, 255, 255, 0) 100%)',
      // backgroundColor: theme.palette.background.dark,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: 64,
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 238,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 48,
      },
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      // background: 'linear-gradient(198deg, rgba(155, 88, 243, 0.17) 0%, rgba(53, 78, 247, 0.06) 45%, rgba(255, 255, 255, 0) 100%)',
      boxShadow: 'rgb(0 0 0 / 14%) 0px 0px 30px 5px inset',
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
      paddingBottom: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'normal',
      margin: '0',
    },
    vision: {
      position: 'absolute',
      top: '50%',
      // right: '20%',
      left: '50%',
      // transform: 'translateY(-50%)',
      transform: 'translate(-50%,-50%)',
      fontSize: '12px',
      fontWeight: 400,
      color: '#545A6C',
      lineHeight: '17px',
      // [theme.breakpoints.down('xs')]: {
      //   right: '50%',
      //   top: '-16px',
      //   transform:'translateX(50%)',
      // },
      // '&:after': {
      //   content: '',
      //   position: 'absolute',
      //   left: '0',
      //   top: '50%',
      //   transform:'translateY(-50%)',

      // }
    },
    vc: {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      background: '#BED3FF',
      marginRight: '4px'
    },
    // ui_top_bar: {
    //   top: '36px',
    //   // [theme.breakpoints.down('xs')]: {
    //   //   right: '50%',
    //   //   top: '-16px',
    //   //   transform:'translateX(50%)',
    //   // },
    // },
    // ui_nav_bar: {
    //   top: '100px',
    //   height: 'calc(100% - 100px)',
    //   [theme.breakpoints.down('xs')]: {
    //     top: '84px',
    //     height: 'calc(100% - 84px)',
    //   },
    // },
    // bodyTop: {
    //   paddingTop: '100px',
    //   [theme.breakpoints.down('xs')]: {
    //     paddingTop: '84px',
    //   },
    // }
  })
)

const GitHubCommit: React.FC<{ children?: string }> = ({ children }) =>
  children?.match(/[0-9a-f]{40}/) ? (
    <Button href={`https://github.com/liquity/dev/commit/${children}`}>{children.substr(0, 7)}</Button>
  ) : (
    <>unknown</>
  );

type OpenType = {
  openUi: boolean;
  hostCheck: boolean;
  handleCheckUi?: () => void;
}

export const PageSwitcher: React.FC<OpenType> = ({ openUi, hostCheck, handleCheckUi, children }) => {
  const {
    account,
    config: { frontendTag },
    liquity
  } = useLiquity();
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false)

  const frontend = useLiquitySelector(selectFrontend);
  const unregistered = frontendTag !== AddressZero && frontend.status === "unregistered";

  const [registering, setRegistering] = useState(false);

  // const [openUi, setOpenUi] = useState<boolean>(true);
  // const history = useHistory();
  // console.log(1111,history)
  useEffect(() => {
    if (unregistered) {
      setRegistering(true);
    }
  }, [unregistered]);

  const onMobileclick = (b: boolean) => {
    setMobileNavOpen(b)
  }

  let dom: any;
  if (registering || unregistered) {
    if (frontend.status === "registered") {
      dom = <FrontendRegistrationSuccess onDismiss={() => setRegistering(false)} />;
    } else if (account === frontendTag) {
      dom = <FrontendRegistration />;
    } else {
      dom = <UnregisteredFrontend />;
    }
    return (
      <Container
        variant="main"
        sx={{
          display: "flex",
          flexGrow: 1,
          marginTop: '100px!important',
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {dom}
      </Container>
    )
  } else {
    // return <Dashboard />;
    return (
      <>
        {
          openUi ? (
            <Box className={classes.root}>

              <TopBar
                // className={hostCheck ? classes.ui_top_bar : ''}
                mobileStatues={isMobileNavOpen} onMobileclick={onMobileclick} />

              <NavBar
                hostCheck={hostCheck}
                handleCheckUi={handleCheckUi}
                // className={hostCheck?classes.ui_nav_bar:''}
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
              />

              <Box className={clsx(classes.wrapper)}>
                <Box className={classes.contentContainer}>
                  <Grid container spacing={3} className={classes.content}>
                    <Grid item xs={12} sm={12} md={12} style={{ position: 'relative', paddingBottom: '80px' }}>
                      {children}
                      <Box
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          /* transform: translateX(-50%); */
                          width: '100%',
                          textAlign: 'center'
                        }}>
                        {/* <Button startIcon={<img src={icon_ac} alt="" style={{ width: '22px',height:'22px' }}/>}>MrBlock Community</Button> */}
                        <Box className={classes.vision} display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="body1" className={classes.vc}></Typography>
                          V.1.1.00
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Dashboard />
            </>
          )
        }

      </>
    )
  }
};
