
const PT = {
  text_01:"Depositar",
  text_02: "Retirar",
  text_03: "Resgate ",
  text_04: "Total do stake",
  text_05: "",
  text_06: "",
  text_07: "STAKED",
  text_08: "Stake ",
  text_09: "Pedir emprestado",
  text_091: "Pedir emprestado",
  text_092: "Pedir emprestado",
  text_10: "Pool de estabilidade",
  text_11: "Stake",
  text_12: "Liquidação",
  text_13: "LQTY restante",
  text_14: "Recompensa",
  text_15: "Retirar",
  text_16: "Depositar",
  text_17: "Disponível: ",
  text_18: "Carteira: ",
  text_19: "Pagar de novo",
  text_20: "O débito deve ser de pelo menos 2.000 LUSD",
  text_21: "Ainda que a taxa mínima de colateralização seja 110% durante operações normais, é recomendado manter a taxa de colateralização sempre acima dos 150% para evitar liquidação sob o Modo de Recuperação. Uma taxa de colateralização acima de 200% ou 250% é recomendada para segurança adicional.",
  text_22: "Disponível: ",
  text_23: "Certifique-se de que há LUSD suficientes antes de fechar o trove",
  text_24: "Fechar trove",
  text_25: "Esta taxa cobre o custo de gás que um liquidante pagaria para liquidar o seu trove. Esta taxa é reembolsada quando você paga sua dívida.",
  text_26: "Taxa de colateralização",
  // text_261: "Taxa de colateralização",
  text_261: "Ratio",
  text_27: "Resgate",
  text_28: "Débito",
  text_29: "Pagar de novo",
  text_30: "Retirar",
  text_31: "Fechar trove",
  text_32: "Desfazer stake",
  text_33: "Fazer stake",
  text_34: "Retirar",
  text_35: "Fazer",
  text_36: "Disponível: ",
  // text_37: "Fornecimento total de LUSD",
  // text_38: "Taxa de colateralização total",
  text_37: "Total LUSD supply",
  text_38: "Total Collateral Ratio",
  text_39: "Preço",
  text_40: " APR",
  text_41: "Holder",
  text_42: "Fazer stake do (ETH)",
  text_43: "Débito (LUSD)",
  text_44: "Taxa de colateralização",
  text_45: "Estado",
  text_46: "Liquidar",
  text_lp: "As recompensas do LQTY neste pool durarão 6 semanas.",
  text_47: "As recompensas do LQTY neste pool durarão 35 meses.",
  text_48: "Confirmar",
  text_49: "Confirmar",
  text_50: "Pendente",
  text_51: "Success",
  text_52: "Transaction Confirmed",
  text_53: "Failure ",
  text_54: "Please try again.",
  text_55: "Error",
  text_56: "Transaction Rejected",
  text_57: "Transação",
  text_58: "Reserva de liquidação",
  text_59: "O débito deve ser de pelo menos",
  text_591: ".",
  text_60: "Taxa",
  text_61: "An estimate of the LUSD&ETH return on the LQTY staked to this pool.（Calculation in LUSD)",
  text_62: "Liquity Analysis",
  text_63: "Switch to DevUI",
  text_64: "Switch to FiUI",
  text_65: "Redeem",
  text_67: "Your Trove has been redeemed",
  text_68: "Please reclaim your remaining collateral before opening a new Trove.",
  text_69: "Account",
  text_70: "Connected ",
  text_71: "Change",
  text_720: "The total amount of LUSD your Trove will hold. You will need to repay ",
  text_721: " LUSD to reclaim your collateral (200 LUSD Liquidation Reserve excluded).",
  text_730: "Note: ",
  text_731: "Redemption is not for repaying your loan. To repay your loan, switch to",
  text_732: "Withdraw tab.",
  text_91: "Normal Mode:",
  text_92: "Recovery Mode:",
  text_93: "Recovery Mode Price :",
  text_94: "The dollar value per unit of collateral at which your Trove will drop below a 110% Collateral Ratio and be liquidated. You should ensure you are comfortable with managing your position so that the price of your collateral never reaches this level.",
  text_95: "Liquidation price",
  text_96: "Price",
  text_97: "Currently Burned",
  text_99: "Add",
  text_100: "Buy",
  text_102: "My Wallet",
  text_118: "Market Cap",
  text_119: "Circulating Supply",
  text_127: "Statistics",
  text_128: "TVL",
  text_120: "The amount of LQTY  that are circulating in the market. Formula = (Circulating Supply / Total Supply)*100%",
  text_371: "Total supply",
  text_041: "LUSD in Stability Pool",
  text_863: "Home",
  text_123: "Liquity 生态",
}
export default PT;