import ReactBoolean,{useState,useEffect} from 'react';
import { makeStyles,Box,Tooltip,Button,Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { useTranslation, Trans, Translation } from 'react-i18next'

import {
  Percent,
  MINIMUM_COLLATERAL_RATIO,
  CRITICAL_COLLATERAL_RATIO,
  UserTrove
} from "@liquity/lib-base";
import { BlockPolledLiquityStoreState } from "@liquity/lib-ethers";
import { useLiquitySelector } from "@liquity/lib-react";
import { useLiquity } from "../../hooks/LiquityContext";
import { shortenAddress } from "../../utils/shortenAddress";
import { Transaction } from "../../components/Transaction";
import CopyToClipboard from "react-copy-to-clipboard";

interface Column {
  id?: 'ownerAddress' | 'collateral' | 'debt';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'ownerAddress', label: 'text_41', minWidth: 170 },
  { id: 'collateral', label: 'text_42', minWidth: 170 },
  {
    id: 'debt',
    label: 'text_43',
    minWidth: 130,
    // align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    label: 'text_44',
    minWidth: 130,
    // align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    label: 'text_45',
    minWidth: 130,
    align: 'right',
    format: (value: number) => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding:'20px'
  },
  container: {
    // maxHeight: 440,
  },
  head: {
    backgroundColor:'rgba(250, 250, 255, 1)'
  },
  success: {
    color:'#28c081'
  },
  warning: {
    color:'rgba(245, 153, 23, 1)'
  },
  danger: {
    color:'#dc2c10'
  },
  copy: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#745DDF',
    lineHeight: '20px'
  }
});

const select = ({ numberOfTroves, price, blockTag }: BlockPolledLiquityStoreState) => ({
  numberOfTroves,
  price,
  blockTag
});

// const pageSize = 10;

export default function StickyHeadTable() {
  let { t, i18n } = useTranslation();
  const classes = useStyles();
  const { blockTag, numberOfTroves, price } = useLiquitySelector(select);
  const { liquity } = useLiquity();

  const [page, setPage] = useState(0);
  const [troves, setTroves] = useState<UserTrove[]>();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState({});
  // const [rowsPerPage, setRowsPerPage] = useState(10);

  const numberOfPages = Math.ceil(numberOfTroves / rowsPerPage) || 1;
  const clampedPage = Math.min(page, numberOfPages - 1);

  const nextPage = () => {
    if (clampedPage < numberOfPages - 1) {
      setPage(clampedPage + 1);
    }
  };

  const previousPage = () => {
    if (clampedPage > 0) {
      setPage(clampedPage - 1);
    }
  };

  useEffect(() => {
    if (page !== clampedPage) {
      setPage(clampedPage);
    }
  }, [page, clampedPage]);
  
  useEffect(() => {
    let mounted = true;

    setLoading(true);

    liquity
      .getTroves(
        {
          first: rowsPerPage,
          sortedBy: "ascendingCollateralRatio",
          startingAt: clampedPage * rowsPerPage
        },
        { blockTag }
      )
      .then(troves => {
        if (mounted) {
          setTroves(troves);
          setLoading(false);
        }
      });

    return () => {
      mounted = false;
    };
    // Omit blockTag from deps on purpose
    // eslint-disable-next-line
  }, [liquity, clampedPage, rowsPerPage, reload]);

  const [copied, setCopied] = useState<string>();

  useEffect(() => {
    if (copied !== undefined) {
      let cancelled = false;

      setTimeout(() => {
        if (!cancelled) {
          setCopied(undefined);
        }
      }, 2000);

      return () => {
        cancelled = true;
      };
    }
  }, [copied]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  classes={{head:classes.head}}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {t(column.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
            {
              !troves || troves.length === 0 ? null : (
                troves.map((row) => {
                  return (
                    <TableBody>
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.ownerAddress}>
                      <TableCell>
                        <Tooltip title={row.ownerAddress}>
                          <CopyToClipboard
                            text={row.ownerAddress}
                            onCopy={() => setCopied(row.ownerAddress)}
                          >
                            <Button
                              endIcon={<FilterNoneIcon style={{color:'#000',fontSize:'14px'}}/>}
                              className={classes.copy}
                              style={{color: '#745DDF'}}
                            >
                              {shortenAddress(row.ownerAddress)}
                            </Button>
                          </CopyToClipboard>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {row.collateral.prettify(4)}
                      </TableCell>
                      <TableCell>
                        {row.debt.prettify()}
                      </TableCell>
                      <TableCell>
                        {(collateralRatio => (
                            <Typography
                            variant="button"
                            className={
                              collateralRatio.gt(CRITICAL_COLLATERAL_RATIO)
                                ? classes.success
                                : collateralRatio.gt(MINIMUM_COLLATERAL_RATIO)
                                ? classes.warning
                                : classes.danger
                            }
                          >
                            {collateralRatio?.gt(10)
                              ? "× " + collateralRatio.shorten()
                              : new Percent(collateralRatio).prettify()}
                          </Typography>
                        ))(row.collateralRatio(price))}
                      </TableCell>
                      <TableCell style={{textAlign:'right'}}>
                      <Transaction
                          id={`liquidate-${row.ownerAddress}`}
                          tooltip="Liquidate"
                          requires={[
                            [
                              row.collateralRatioIsBelowMinimum(price),
                              "Collateral ratio not low enough"
                            ],
                            [numberOfTroves > 1, "Can't liquidate when only one Trove exists"]
                          ]}
                          send={liquity.send.liquidate.bind(liquity.send, row.ownerAddress)}
                        >
                          {
                            row.collateralRatioIsBelowMinimum(price) || numberOfTroves > 1 ? (
                              // <Tooltip title={
                              //   "Collateral ratio not low enough" 
                              //   // numberOfTroves > 1&&"Can't liquidate when only one Trove exists"
                              // }>
                              <Tooltip title="Collateral ratio not low enough" 
                                // numberOfTroves > 1&&"Can't liquidate when only one Trove exists"
                              >
                                <Button variant="contained">
                                  {t('text_46')}
                                </Button>
                              </Tooltip>
                            ) : (
                              <Button variant="contained" color="primary">
                                Liquidate
                              </Button>
                            )
                          }
                          
                        </Transaction>
                      </TableCell>
                      </TableRow>
                  </TableBody>
                  );
                })
              )
            }
        </Table>
        {
          !troves || troves.length === 0 ? (
            <Box style={{padding:'20px',fontSize: '14px',textAlign: "center",width:'100%'}}>
              {!troves ? "Loading..." : "There are no Troves yet"}
            </Box>
          ) : null
        }
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={numberOfTroves}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}