import React,{useState} from 'react'
import RedditTextField from './RedditTextField'
import { Transaction } from "../../components/Transaction";
import {
  Box,
  Button,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  InputAdornment,
  fade,
  OutlinedInputProps,
  TextFieldProps,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation, Trans, Translation } from 'react-i18next'
import { useLiquity } from "../../hooks/LiquityContext";
import { useLiquitySelector } from '@liquity/lib-react'
import { LiquityStoreState } from '@liquity/lib-base'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      padding:'20px 20px 0 20px'
    },
    margin: {
      margin: theme.spacing(1),
      width: '100%',
      marginBttom: '24px',
    },
    textField: {
      width: '100%',
      margin: '0',
    },
    subBtn: {
      minWidth:'100px',
      marginLeft:'10px',
      padding: '10px 0',
      whiteSpace: 'nowrap'
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(21, 66, 205, 1)',
      '&:hover': {
        backgroundColor: 'rgba(21, 66, 205, 1)',
        color: '#fff',
      },
    },
    h3: {
      fontSize: '24px',
      fontWeight: 600,
      color: '#1F2533',
      lineHeight: '33px'
    }
  })
)

const useStylesReddit = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding:'0 12px',
      border: '1px solid #e2e2e1',
      display: 'inline-flex',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: '#fff!important',
      position: 'relative',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&$focused': {
        backgroundColor: '#fff',
        // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
      '& > input': {
        padding: '12px',
      },
    },
    focused: {},
  })
)

function TextFieldPro(props:TextFieldProps) {
  const classes = useStylesReddit()
  let { t, i18n } = useTranslation();
  return (<TextField
    InputProps={{
      classes,
      disableUnderline: true,
      startAdornment: <InputAdornment position="start">{t('text_12')}</InputAdornment>,
      endAdornment:<InputAdornment position="end">Troves</InputAdornment>
    } as Partial<OutlinedInputProps>
    }
    {...props}
    />)
}

const select = ({
  accountBalance,
}: LiquityStoreState) => ({
  accountBalance,
})

const Liquidate: React.FC = () => { 
  const classes = useStyles()
  let { t, i18n } = useTranslation();
  const {
    liquity: { send: liquity }
  } = useLiquity();
  const [numberOfTrovesToLiquidate, setNumberOfTrovesToLiquidate] = useState("90");
  const { accountBalance} = useLiquitySelector(
    select
  )

  return (
    <>
      <Box
        className={classes.box}
      display="flex"
      justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap">
      <Typography variant="h3" className={classes.h3}>
        Liquidate
      </Typography>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        // flexWrap="wrap"
      >
          <TextFieldPro
            type="number"
            onChange={e => setNumberOfTrovesToLiquidate(e.target.value)}
            value={numberOfTrovesToLiquidate}
            className={clsx(classes.margin, classes.textField)}
            id="mui-theme-provider-outlined-input"
        />
        {/* <RedditTextField
            labeltext="清算"
            coin="ETH"
            endadornmenttext='钱包：'
            endadornmentvalue={accountBalance.prettify()}
            onChange={e => setNumberOfTrovesToLiquidate(e.target.value)}
            value={numberOfTrovesToLiquidate}
            // defaultValue={editedLUSD.toString(2)}
            className={clsx(classes.margin, classes.textField)}
            // defaultValue="react-reddit"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ backgroundColor: '#fff' }}
        /> */}
        <Transaction
          id="batch-liquidate"
          // tooltip="Liquidate"
          tooltipPlacement="bottom"
          send={overrides => {
            if (!numberOfTrovesToLiquidate) {
              throw new Error("Invalid number");
            }
            return liquity.liquidateUpTo(parseInt(numberOfTrovesToLiquidate, 10), overrides);
          }}
        >
              <Button disableRipple className={clsx(classes.subBtn, classes.btnColor)}>
              {t('text_12')}
              </Button>
        </Transaction>
        </Box>
      </Box>
    </>
  )
}

export default Liquidate