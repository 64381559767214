import React from 'react'
import clsx from 'clsx'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography
} from '@material-ui/core'
import OverShadomBox from '../../components/new-ui/OverShadomBox'
import RiskiestTroves from '../../components/new-ui/RiskiestTroves'
import Liquidate from '../../components/new-ui/Liquidate'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
    },
    back: {
      background: '#fff',
      overflow: 'hidden',
      // padding: '30px 20px',
      // height: '100px',
    },
    box: {
      marginTop: '20px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '0',
      },
    },
    boxForm: {
      padding: '0 20px 40px',
    },
    selected: {
      color: 'rgba(31, 37, 51, 1)',
    },
    indicators: {
      backgroundColor: 'rgba(41, 49, 71, 1)',
    },
    h3: {
      marginBottom:'30px',
      fontSize: '24px',
      fontWeight: 600,
      color: '#1F2533',
      lineHeight: '33px'
    },
    grid: {
      [theme.breakpoints.up('md')]: {
        maxWidth:'820px'
      },
    }
  })
)


const StablePool: React.FC = () => {
  const classes = useStyles()
  return (
    <>
      <Grid
        container
        spacing={3}
        className={classes.box}
        justify="center"
      >
        {/* <Grid item xs={12} sm={4} md={4}>
          <Typography variant="h3" className={classes.h3} gutterBottom>
            Liquidate
          </Typography>
          <OverShadomBox className={classes.back}>
          <Liquidate></Liquidate>
          </OverShadomBox>
        </Grid> */}
        <Grid item xs={12} md={10} className={classes.grid}>
          {/* <Typography variant="h3" className={classes.h3}  gutterBottom>
          Riskiest Troves 
          </Typography> */}
          <OverShadomBox className={classes.back}>
            <Liquidate></Liquidate>
            <RiskiestTroves></RiskiestTroves>
          </OverShadomBox>
        </Grid>
      </Grid>
    </>
  )
};

export default StablePool;