
const KO = {
  text_01:"입금",
  text_02: "출금",
  text_03: "리딤 ",
  text_04: "총 스테이킹",
  text_05: "",
  text_06: "",
  text_07: "스테이킹",
  text_08: "스테이킹 ",
  text_09: "대출",
  text_091: "대출",
  text_092: "대출",
  text_10: "스태빌리티 풀",
  text_11: "스테이킹",
  text_12: "청산",
  text_13: "남은 LQTY",
  text_14: "리워드",
  text_15: "출금",
  text_16: "입금",
  text_17: "이용가능: ",
  text_18: "월렛: ",
  text_19: "상환",
  text_20: "부채는 최소 2,000 LUSD 이상이어야 합니다.",
  text_21: "일반적인 운용 시 최소 담보 비율은 110%입니다만, 리커버리 모드에서의 청산을 방지하기 위해 담보 비율은 항상 150% 이상을 유지하기를 권고합니다. 추가적인 안전성을 원할 경우 담보 비율은 200% 또는 250% 이상을 권장합니다.",
  text_22: "이용가능: ",
  text_23: "Trove를 닫기 전에 충분한 LUSD가 있는지 확인해 주십시오",
  text_24: "Trove 닫기",
  text_25: "수수료는 귀하의 Trove를 청산하기 위해 청산자가 지불해야할 가스 비용을 커버합니다. 귀하의 부채를 상환하실 때 본 수수료 비용은 반환됩니다.",
  text_26: "담보 비율",
  text_261: "담보 비율",
  text_27: "리딤",
  text_28: "부채",
  text_29: "상환",
  text_30: "인출",
  text_31: "Trove 닫기",
  text_32: "스테이킹 취소",
  text_33: "스테이킹하기",
  text_34: "인출",
  text_35: "담보",
  text_36: "이용가능: ",
  text_37: "총 LUSD 공급",
  text_38: "총 담보 비율",
  text_39: "가격",
  text_40: " APR",
  text_41: "소유자",
  text_42: "담보(ETH)",
  text_43: "부채(LUSD)",
  text_44: "담보 비율",
  text_45: "상태",
  text_46: "청산하기",
  text_lp: "본 풀의 LQTY 리워드는 6주 간 유지됩니다.",
  text_47: "본 풀의 LQTY 리워드는 35개월 간 유지됩니다.",
  text_48: "확인",
  text_49: "확인",
  text_50: "보류 중",
  text_51: "Success",
  text_52: "Transaction Confirmed",
  text_53: "Failure ",
  text_54: "Please try again.",
  text_55: "Error",
  text_56: "Transaction Rejected",
  text_57: "트랜잭션",
  text_58: "청산 준비금",
  text_59: "부채는 최소",
  text_591: " 이상이어야 합니다.",
  text_60: "수수료",
  text_61: "An estimate of the LUSD&ETH return on the LQTY staked to this pool.（Calculation in LUSD)",
  text_62: "Liquity Analysis",
  text_63: "Switch to DevUI",
  text_64: "Switch to FiUI",
  text_65: "Redeem",
  text_67: "Your Trove has been redeemed",
  text_68: "Please reclaim your remaining collateral before opening a new Trove.",
  text_69: "Account",
  text_70: "Connected ",
  text_71: "Change",
  text_720: "The total amount of LUSD your Trove will hold. You will need to repay ",
  text_721: " LUSD to reclaim your collateral (200 LUSD Liquidation Reserve excluded).",
  text_730: "Note: ",
  text_731: "Redemption is not for repaying your loan. To repay your loan, switch to",
  text_732: "Withdraw tab.",
  text_91: "Normal Mode:",
  text_92: "Recovery Mode:",
  text_93: "Recovery Mode Price :",
  text_94: "The dollar value per unit of collateral at which your Trove will drop below a 110% Collateral Ratio and be liquidated. You should ensure you are comfortable with managing your position so that the price of your collateral never reaches this level.",
  text_95: "Liquidation price",
  text_96: "Price",
  text_97: "Currently Burned",
  text_99: "Add",
  text_100: "Buy",
  text_102: "My Wallet",
  text_118: "Market Cap",
  text_119: "Circulating Supply",
  text_127: "Statistics",
  text_128: "TVL",
  text_120: "The amount of LQTY  that are circulating in the market. Formula = (Circulating Supply / Total Supply)*100%",
  text_371: "Total supply",
  text_041: "LUSD in Stability Pool",
  text_863: "Home",
  text_123: "Liquity 生态",
}
export default KO;