import { useContext } from 'react'
import {
  Button,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import { TipContext } from "../new-ui/hook/TipContext";

import { Decimal, TroveChange } from "@liquity/lib-base";

import { useLiquity } from "../../hooks/LiquityContext";
import { useTransactionFunction } from "../Transaction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subBtn: {
      marginTop: '16px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(21, 66, 205, 1)',
      '&:hover': {
        backgroundColor: 'rgba(21, 66, 205, 1)',
        color: '#fff',
      },
    },
  })
)

type TroveActionProps = {
  handleClose?:() => void;
  transactionId: string;
  change: Exclude<TroveChange<Decimal>, { type: "invalidCreation" }>;
  maxBorrowingRate: Decimal;
};

export const TroveAction: React.FC<TroveActionProps> = ({
  children,
  handleClose,
  transactionId,
  change,
  maxBorrowingRate
}) => {
  const { liquity } = useLiquity();
  const classes = useStyles()
  const Tip = useContext(TipContext);

  const [sendTransaction] = useTransactionFunction(
    transactionId,
    change.type === "creation"
      ? liquity.send.openTrove.bind(liquity.send, change.params, maxBorrowingRate)
      : change.type === "closure"
      ? liquity.send.closeTrove.bind(liquity.send)
      : liquity.send.adjustTrove.bind(liquity.send, change.params, maxBorrowingRate)
  );

  const handleClick = () => {
    sendTransaction();
    
    if (handleClose) {
      handleClose();
    }
    
    Tip?.dispatchEvent(true)
  }

  return <Button onClick={handleClick} className={clsx(classes.subBtn,classes.btnColor)}>{children}</Button>;
};
