import React, { useEffect,useState} from 'react'
import clsx from 'clsx'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Fade,
  Typography,
  Button,
  Tab,
} from '@material-ui/core';
import { useTranslation, Trans, Translation } from 'react-i18next'
import { useLiquitySelector } from "@liquity/lib-react";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquity } from "../../../hooks/LiquityContext";
import {
  contract_address
} from '../../../eth-contract/ContractApi'

import httpApi from '../../../utils/http';

import img_icon from '../../../assets/icon-lqty1.svg';
import lusd from '../../../assets/lusd.png';
import icon_curve from '../../../assets/icon-curve.png';
import icon_visor from '../../../assets/icon-visor.png';
import icon_rari from '../../../assets/icon-rari.png';
import contract_me from '../../../assets/contract-me.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    board_box: {
      margin: '0 auto',
      marginTop: '30px',
      flexWrap: 'wrap',
      maxWidth: '1512px',
      [theme.breakpoints.down('lg')]: {
        width:'1000px',
        marginTop: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        width:'100%',
      }
    },
    box_gm:{
      width:'100%',
      margin:'0 auto',
      [theme.breakpoints.down('lg')]: {
        width:'1000px',
      },
      [theme.breakpoints.down('sm')]: {
        width:'100%',
      }
    },
    data_flex: {
      marginLeft: '-30px',
      flexWrap: 'wrap',
      // [theme.breakpoints.down('lg')]: {
      //   padding:'0 12px',
      // },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
        padding:'0',
      }
    },
    item_box: {
      width:'484px',
      marginTop:'30px',
      marginLeft: '30px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '12px',
      border: '2px solid #E3E3F3',
      padding: '20px 24px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 12px',
        width:'100%',
        marginLeft: '0',
      }
    },
    farm_header: {
      marginTop:'30px',
      padding:'20px 30px',
      background: 'linear-gradient(137deg, #745DDF 0%, #042272 100%)',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '24px',
      [theme.breakpoints.down('sm')]: {
        padding:'10px 20px',
        marginTop:'20px',
      }
    },
    h3_text:{
      fontSize: '36px',
      fontWeight: 600,
      color: '#fff',
      lineHeight: '50px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px',
        lineHeight: '36px',
      }
    },
    h6_text: {
      marginTop: '10px',
      fontSize: '14px',
      color: 'rgba(255, 255, 255, .8)',
      lineHeight: '20px',
    },
    btn_color_text:{
      padding: '10px 20px',
      fontSize: '14px',
      color: '#FFFFFF',
      lineHeight: '20px',
      borderRadius: '10px',
      border: '1px solid #C1B4FF'
    },
    tabs_root: {
      minHeight: '36px',
    },
    tab_root: {
      minHeight: '36px',
      minWidth:'auto',
      zIndex: 10,
      color:'rgba(240, 246, 251, 1)'
    },
    selected_color: {
      color:'rgba(27, 29, 37, 1)'
    },
    MuiTabs_flexContainer: {
      minHeight:'36px',
      background: '#545A6C',
      borderRadius: '22px',
      display: 'inline-block'
    },
    indicators: {
      backgroundColor: 'rgba(193, 180, 255, 1)',
      height: '100%',
      borderRadius: '22px'
    },
    text_tab:{
      cursor: 'pointer',
      marginTop: '30px',
      padding:'10px 30px',
      borderRadius: '10px',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      marginLeft:'30px',
      '&:first-child':{
        marginLeft:'0'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft:'10px',
        padding:'8px 20px',
      }
    },
    default_color:{
      background: '#E3E3EE',
      color: '#8F9295',
      // opacity: 0.37
    },
    active_color:{
      background: 'rgba(116, 93, 223, 1)',
      color: 'rgba(255, 255, 255, 1)',
      opacity: 1
    },
    btn_coin_text:{
      padding: '4px 20px',
      fontSize: '16px',
      fontWeight: 500,
      color: 'rgba(116, 93, 223, 1)',
      lineHeight: '22px',
      borderRadius: '4px',
      border: '1px solid rgba(116, 93, 223, 1)'
    },
    eco_img:{
      width: '48px',
      height: '48px',
      marginRight:'10px'
    },
    coin_img:{
      width: '24px',
      height: '24px',
      marginRight:'8px'
    },
    other_text:{
      fontSize: '16px',
      color: 'rgba(170, 174, 179, 1)',
      lineHeight: '22px',
    },
    coin_text:{
      fontSize: '18px',
      color: 'rgba(32, 35, 43, 1)',
      fontWeight: 500,
      lineHeight: '26px',
    },
    item_text:{
      textAlign:'center',
      margintop:'6px',
      fontSize: '12px',
      color: 'rgba(170, 174, 179, 1)',
      lineHeight: '16px',
    },
    item_title:{
      fontSize: '24px',
      color: 'rgba(32, 35, 43, 1)',
      fontWeight: 500,
      lineHeight: '30px',
    },
    desc_text:{
      fontSize: '14px',
      color: 'rgba(27, 29, 37, 1)',
      lineHeight: '20px',
    },
    title:{
      fontSize: '26px',
      color: '#20232B',
      fontWeight: 500,
      lineHeight: '40px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '30px',
      }
    }
  })
)

const visor_address = '0xd930ab15c8078ebae4ac8da1098a81583603f7ce';

const convertMantissaToAPY = (mantissa: any, dayRange: number) => {
  return (Math.pow((mantissa / 1e18) * 6500 + 1, dayRange) - 1) * 100;
};

let FusePoolAssets:any={};

const select = ({
  price,
}: LiquityStoreState) => ({
  price,
});

const EcoIndex: React.FC = () => {
  const classes = useStyles()
  const {
    meContract,
  } = useLiquity();

  const {
    price,
  } = useLiquitySelector(select);

  let { t, i18n } = useTranslation();
  const [checked, setChecked] = useState(true);
  const [value, setValue] = React.useState('0');

  const [curveApy, setCurveApy] = React.useState('');
  const [curveTvl, setCurveTvl] = React.useState(0);

  const [visorApy, setVisorApy] = React.useState('');
  const [visorTvl, setVisorTvl] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    httpApi.raw_stats_apys({}).then((res:any) => {
        const data = res.data;
        // setCurveApy((data.apy['day']['lusd']*100).toFixed(2));
        const base_apy=data.apy['day']['lusd']*100;

        const snxRewards = 365 * (data.apy['month']['lusd'] * data.apy['total']['lusd'] / 1e18)/7*5.600594632457/((+data.apy['day']['lusd']) * (+data.apy['week']['lusd'])/1e36) * 100
        
        setCurveTvl(data.volume['lusd'])

        httpApi.simple_price({}).then((res:any) => {
          const price_crv = res.data['curve-dao-token']['usd'];
        
          meContract.contractEth.get_virtual_price().then((price:any) => {
           const prices = Number(Decimal.fromBigNumberString(price));
           meContract.contractGauge.working_supply().then((supply:any) => {
              const supplys = Number(Decimal.fromBigNumberString(supply));
              meContract.contractGaugeController.gauge_relative_weight(contract_address.crv_gauge).then((weight:any) => {
                const weights = Number(Decimal.fromBigNumberString(weight));
                let rate = (7.327853447857531 * weights * 31536000 / supplys * 0.4) / prices;
                setCurveApy((rate * price_crv * 100 * 2.5 + base_apy).toFixed(2))
              })
            })
          })
        })
    }).catch((err:any) => {
      console.log(err)
    })

    httpApi.hypervisors_allData({}).then((res:any) => {
      const data = res.data[visor_address];
      setVisorApy((data['returns']['daily']['feeApy']*100).toFixed(2));
      setVisorTvl(data.tvlUSD)
    }).catch((err:any) => {
      console.log(err)
    })

    // Rari Capital DAO
    meContract.contractFusePoolLens.getPoolAssetsWithData('0x814b02C1ebc9164972D888495927fe1697F0Fb4c').then((res:any) => {
      res.forEach(((item:any)=>{
        if(item.cToken==='0x4e457722B68F8adC887F5De85b8410021Bd698AC'){
          FusePoolAssets = item;
        }
      }))
    })
    
  },[])

const totalSupply=FusePoolAssets.totalSupply&&Decimal.from((FusePoolAssets.totalSupply * FusePoolAssets.underlyingPrice) / 1e36).mul(price).add(Decimal.from((FusePoolAssets.totalBorrow * FusePoolAssets.underlyingPrice) / 1e36).mul(price)).shorten()
  return (
    <>
      <Box className={classes.board_box}>
        <Box className={classes.farm_header}>
            <Typography variant="h3" className={classes.h3_text} gutterBottom>
            探索Liquity 生态，发现更多场景
            </Typography>
            <Button 
            className={classes.btn_color_text}
            component="a"
            href="https://t.me/joinchat/eDzm-Vv_AEFiN2E1"
            startIcon={<img style={{marginRight:'2px'}} src={ contract_me } alt="" />  }
            >
            发现更多生态，联系我们
            </Button>
            <Typography variant="h6" className={classes.h6_text}>
            所有场景非投资建议，请自行评估风险
            </Typography>
        </Box>
        
        <Box display="flex" justifyContent="flex-start"  alignItems="center">
          <Box className={clsx(classes.text_tab,value==='0'?classes.active_color:classes.default_color)}>
          ALL 生态
          </Box>
          <Box className={clsx(classes.text_tab,value==='1'?classes.active_color:classes.default_color)}>
          swap
          </Box>
          <Box className={clsx(classes.text_tab,value==='2'?classes.active_color:classes.default_color)}>
          Lend
          </Box>
        </Box>
        
        <Box className={classes.box_gm}>
          <Box display="flex" justifyContent="start" alignItems="start" className={classes.data_flex}>
            <Fade in={checked} {...(checked ? { timeout: 100 } : {})}>
              <Box className={classes.item_box}>
                <Box
                  display="flex" justifyContent="space-between" alignItems="center"
                >
                  <Box className={classes.title} display="flex" justifyContent="flex-start" alignItems="center">
                    <img className={classes.eco_img} src={ icon_curve } alt="" />
                    Curve
                  </Box>
                  <Button 
                    className={classes.btn_coin_text}
                    href="https://curve.fi/lusd"
                    target="_blank"
                  >
                  访问
                  </Button>
                </Box>
                <Box style={{marginTop:'30px'}} display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" justifyContent="flex-start">
                    <img className={classes.coin_img} src={lusd} alt="" />
                    <Box className={classes.other_text}>
                      <Typography variant="h6" className={classes.coin_text}>
                      LUSD
                      </Typography>
                      + 3crv
                    </Box>
                  </Box>

                  <Box className={classes.item_text}>
                    <Typography variant="h6" className={classes.item_title}>
                    {curveApy||'--'}%
                    </Typography>
                    APY
                  </Box>

                  <Box className={classes.item_text}>
                    <Typography variant="h6" className={classes.item_title}>
                    {Decimal.from(curveTvl).shorten()||'--'}
                    </Typography>
                    TVL
                  </Box>
                </Box>

                <Box style={{marginTop:'30px'}} className={classes.desc_text}>
                  Liquidity
                  <br/>
                  Liquity 应用场景说明，在这个应用中，LUSD可以如何使用
                </Box>
              </Box>
            </Fade>

            <Fade in={checked} {...(checked ? { timeout: 100 } : {})}>
              <Box className={classes.item_box}>
                <Box
                  display="flex" justifyContent="space-between" alignItems="center"
                >
                  <Box className={classes.title} display="flex" justifyContent="flex-start" alignItems="center">
                    <img className={classes.eco_img} src={ icon_visor } alt="" />
                    VISOR
                  </Box>
                  <Button 
                    className={classes.btn_coin_text}
                    href="https://vault.visor.finance/dashboard"
                    target="_blank"
                  >
                  访问
                  </Button>
                </Box>
                <Box style={{marginTop:'30px'}} display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" justifyContent="flex-start">
                    <img className={classes.coin_img} src={img_icon} alt="" />
                    <Box className={classes.other_text}>
                      <Typography variant="h6" className={classes.coin_text}>
                      LQTY
                      </Typography>
                      + WETH
                    </Box>
                  </Box>

                  <Box className={classes.item_text}>
                    <Typography variant="h6" className={classes.item_title}>
                    {visorApy||'--'}%
                    </Typography>
                    APY
                  </Box>

                  <Box className={classes.item_text}>
                    <Typography variant="h6" className={classes.item_title}>
                    {Decimal.from(visorTvl).shorten()||'--'}
                    </Typography>
                    TVL
                  </Box>
                </Box>

                <Box style={{marginTop:'30px'}} className={classes.desc_text}>
                  Liquidity
                  <br/>
                  Liquity 应用场景说明，在这个应用中，LUSD可以如何使用
                </Box>
              </Box>
            </Fade>

            <Fade in={checked} {...(checked ? { timeout: 100 } : {})}>
              <Box className={classes.item_box}>
                <Box
                  display="flex" justifyContent="space-between" alignItems="center"
                >
                  <Box className={classes.title} display="flex" justifyContent="flex-start" alignItems="center">
                    <img className={classes.eco_img} src={ icon_rari } alt="" />
                    Rari Capital DAO
                  </Box>
                  <Button 
                    className={classes.btn_coin_text}
                    href="https://v2.rari.capital/fuse/pool/6"
                    target="_blank"
                  >
                  访问
                  </Button>
                </Box>
                <Box style={{marginTop:'30px'}} display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" justifyContent="flex-start">
                    <img className={classes.coin_img} src={lusd} alt="" />
                    <Box className={classes.other_text}>
                      <Typography variant="h6" className={classes.coin_text}>
                      LUSD
                      </Typography>
                      + 3crv
                    </Box>
                  </Box>

                  <Box className={classes.item_text}>
                    <Typography variant="h6" className={classes.item_title}>
                    {(convertMantissaToAPY(FusePoolAssets.supplyRatePerBlock,365)+convertMantissaToAPY(FusePoolAssets.borrowRatePerBlock, 365)).toFixed(2)||'--'}%
                    </Typography>
                    APY
                  </Box>

                  <Box className={classes.item_text}>
                    <Typography variant="h6" className={classes.item_title}>
                    {totalSupply||'--'}
                    </Typography>
                    TVL
                  </Box>
                </Box>

                <Box style={{marginTop:'30px'}} className={classes.desc_text}>
                  Liquidity
                  <br/>
                  Liquity 应用场景说明，在这个应用中，LUSD可以如何使用
                </Box>
              </Box>
            </Fade>
            
          </Box>
        </Box>
      </Box>
    </>
  )
}
export default EcoIndex
