import React, { useState, useEffect } from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Tooltip
} from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation, Trans, Translation } from 'react-i18next'
import OverShadomBox from './OverShadomBox'
import { Decimal, Percent, LiquityStoreState } from '@liquity/lib-base'
import { useLiquitySelector } from '@liquity/lib-react'
import { useLiquity } from '../../hooks/LiquityContext'

import icon_01 from '../../assets/icon-01.png'
import tip from '../../assets/tip.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    back: {
      background: 'rgba(255, 255, 255, 0.5)',
      // background: `linear-gradient(56deg, #2F77E8 0%, #1542CD 100%)`,
      // height: '100px',
      marginBottom: '20px',
    },
    box: {
      padding:'10px 16px',
      borderRadius: '24px',
      textAlign: 'center',
      // overflow:'hidden',
      width: '100%',
      height: '100%',
      // background: `Url(${icon_01}) no-repeat center`,
      backgroundSize: '100% 100%',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 600,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '26px',
      marginTop: '10px',
      // marginBottom: '18px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '25px',
      },
    },
    caption: {
      fontSize: '12px',
      // fontWeight: 600,
      color: 'rgba(84, 90, 108, 1)',
      lineHeight: '16px',
      [theme.breakpoints.up('sm')]: {
        lineHeight: '20px',
      },
    },
    tip: {
      padding:'12px 16px',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '17px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '9px',
      border: '1px solid #E3E3F3'
    },
    tip_eth_box: {
      display: 'inline-block',
      marginRight:'6px',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      background:'rgba(6, 211, 148, 1)'
    },
    tip_eth_box1: {
      display: 'inline-block',
      marginRight:'6px',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      background:'rgba(246, 95, 79, 1)'
    },
    tip_text1: {
      fontSize: '12px',
      fontWeight: 500,
      color: '#293147',
      lineHeight: '14px',
    },
    tip_text2: {
      fontSize: '12px',
      fontWeight: 400,
      color: '#293147',
      lineHeight: '14px',
    }
    // grid: {
    //   [theme.breakpoints.up('md')]: {
    //     maxWidth:'400px'
    //   },
    // }
  })
)

const selectBalances = ({
  accountBalance,
  lusdBalance,
  lqtyBalance,
}: LiquityStoreState) => ({
  accountBalance,
  lusdBalance,
  lqtyBalance,
})

const select = ({
  numberOfTroves,
  price,
  total,
  lusdInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLQTY,
}: LiquityStoreState) => ({
  numberOfTroves,
  price,
  total,
  lusdInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLQTY,
})

const selectPrice = ({ price }: LiquityStoreState) => price

const SystemStatsCard: React.FC = () => {
  const classes = useStyles()
  let { t, i18n } = useTranslation();
  const {
    liquity: {
      connection: {
        version: contractsVersion,
        deploymentDate,
        _priceFeedIsTestnet: canSetPrice,
      },
    },
  } = useLiquity()

  const {
    numberOfTroves,
    lusdInStabilityPool,
    total,
    borrowingRate,
    redemptionRate,
    totalStakedLQTY,
    price
  } = useLiquitySelector(select)

  const lusdInStabilityPoolPct =
    total.debt.nonZero && new Percent(lusdInStabilityPool.div(total.debt))
  const totalCollateralRatioPct = new Percent(total.collateralRatio(price))
  const borrowingFeePct = new Percent(borrowingRate)
  const redemptionFeePct = new Percent(redemptionRate)

  const price1 = useLiquitySelector(selectPrice)
  const [editedPrice, setEditedPrice] = useState(price1.toString(2))

  const [openTip, setOpenTip] = React.useState(false);

  const totalMul = total.debt.mul(Decimal.from(1.5));
  const ethPrice = totalMul.div(total.collateral);
  const ratio = Number(total.collateralRatio(price));

  useEffect(() => {
    setEditedPrice(price1.toString(2))
  }, [price])

  return (
    <>
      <OverShadomBox className={classes.back}>
        <Box className={classes.box}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* <Grid container spacing={3}> */}
            {/* <Grid item xs={4}> */}
            <Box>
              <Typography
                  variant="caption"
                  className={classes.caption}
                  display="block"
                >
                {t('text_37')}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.subtitle2}
              >
                {total.debt.shorten()}
              </Typography>
            </Box>
            {/* </Grid> */}
            {/* <Grid item xs={4}> */}
            <Box>
              <Typography
                  variant="caption"
                  className={classes.caption}
                  display="block"
              >
                {t('text_38')}
                <Tooltip
                  classes={{
                    tooltip:classes.tip
                  }}
                  onClose={()=>setOpenTip(false)}
                  open={openTip}
                  title={
                    <Box>
                      <Box style={{marginBottom:'10px'}}>
                        <Typography variant="caption" className={classes.tip_eth_box}></Typography>
                        <Typography variant="caption"  className={classes.tip_text1} style={{marginRight:'12px'}}>{t('text_91')}</Typography>
                        <Typography variant="caption" className={classes.tip_text2}>TCR{'>'}150%</Typography>
                      </Box>
                      <Box style={{ marginBottom: '10px' }}>
                        <Typography variant="caption" className={classes.tip_eth_box1}></Typography>
                        <Typography variant="caption" className={classes.tip_text1}>{t('text_92')}</Typography>
                        <Typography variant="caption" className={classes.tip_text2}>TCR{'<'}150%</Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption" className={classes.tip_text1}>{t('text_93')}</Typography>
                        <Typography variant="caption" className={classes.tip_text2}>  ≈ ${ethPrice.prettify(0)}</Typography>
                      </Box>
                    </Box>
                  }
                  interactive placement="top">
                  <img src={tip}  onClick={()=>setOpenTip(true)} style={{width:'14px', marginLeft: '2px',marginBottom:'-2px'}} alt=""/>
                </Tooltip>
              </Typography>
              <Box
                className={classes.subtitle2}
                display="flex" justifyContent="flex-start" alignItems="center"
              >
                {
                  ratio > 1.5 ? (
                    <Typography variant="caption" className={classes.tip_eth_box}></Typography>
                  ): (
                    <Typography variant="caption" className={classes.tip_eth_box1}></Typography>
                  )
                }
              
                {totalCollateralRatioPct.prettify()}
              </Box>
            </Box>
            {/* </Grid>
            <Grid item xs={4}> */}
            <Box>
              <Typography
                variant="caption"
                className={classes.caption}
                display="block"
              >
                ETH {t('text_39')}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.subtitle2}
              >
                ${editedPrice}
              </Typography>
              </Box>
            {/* </Grid>
          </Grid> */}
            </Box>
        </Box>
      </OverShadomBox>
    </>
  )
}
export default SystemStatsCard
