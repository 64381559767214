import { Box, Flex, Text } from "theme-ui";

import { Icon } from "./Icon";

export const ErrorDescription: React.FC = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",

      // mb: [2, 3],
      // p: 3,
      fontSize:'12px',
      marginTop: '10px',
      color:'rgba(234, 90, 90, 1)'
      // border: 1,
      // borderRadius: "8px",
      // borderColor: "danger",
      // boxShadow: 2,
      // bg: "rgb(234, 90, 90,.05)"
    }}
  >
    <Flex sx={{ alignItems: "center" }}>
      {/* <Icon name="exclamation-triangle" size="lg" /> */}
      <Text>{children}</Text>
    </Flex>
  </Box>
);
