import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import clsx from 'clsx'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Button,
  Typography
} from '@material-ui/core';
import { useLiquitySelector } from "@liquity/lib-react";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquity } from "../../../hooks/LiquityContext";

import { fetchLqtyPrice } from "../../../components/Stability/context/fetchLqtyPrice";

import { contract_address } from '../../../eth-contract/ContractApi'
import { useTranslation, Trans, Translation } from 'react-i18next'

import { ethers } from "ethers";

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import Api from '../../../utils/http'
import common from '../../../utils/common';

// import EChartsReact from 'echarts-for-react';
// import * as echarts from 'echarts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // item_box: {
    //   background: 'rgba(25, 27, 31, 0.6)',
    //   boxShadow: '6px 8px 10px 0px rgba(12, 1, 47, 0.14)',
    //   borderRadius: '12px',
    //   padding:'20px 24px'
    // },
    img_icon: {
      fontSize: '28px',
      fontWeight: 600,
      color: '#36383C',
      lineHeight: '40px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '36px',
      }
    },
    overline_text: {
      fontSize: '18px',
      fontWeight: 400,
      color: '#545A6C',
      lineHeight: '20px'
    },
    button_text: {
      fontSize: 20,
      fontWeight: 600,
      color: '#36383C',
    }
  })
)

// const options = {
//   title: {
//     text: '$ 3243',
//     padding: [20,0],
//     textStyle: {
//       top: 20,
//       fontSize: 20,
//       fontWeight: 600,
//       color: '#FFFFFF',
//     //   lineHeight: '38px'
//     }
//   },
//   grid: { top: 0, right: -10, bottom: 30, left: -10 },
//   xAxis: {
//     type: "category",
//     axisTick: {
//         show: false
//     },
//     axisLine: {
//       show: false,
//       lineStyle: {
//         color: 'rgba(84, 90, 108, 1)'
//       }
//     },
//     axisLabel: {
//         interval: 0,
//         rotate: 45
//     },
//     data: ['', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', ''],
//   },
//   yAxis: {
//     show: false
//   },
//   series: [
//     {
//       data: [820, 932, 901, 934, 1290, 1330, 1320],
//       type: 'line',
//       showSymbol: false,
//       lineStyle: { color:'rgba(135, 109, 255, 1)'},
//       areaStyle: {
//         normal: {
//           color: new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
//             offset: 0, color: '#221170' // 0% 处的颜色
//           }, {
//               offset: 1, color: 'rgba(127, 98, 215, 0)' // 100% 处的颜色
//           }]
//           )
//         }
//       },
//     },
//   ],
//   tooltip: {
//     trigger: 'axis',
//   },
// };
const select = ({
  price,
  total,
  numberOfTroves,
}: LiquityStoreState) => ({
  price,
  total,
  numberOfTroves,
});

const Tvl: React.FC = () => {
  const classes = useStyles()
  const {
    price,
    total,
    numberOfTroves,
  } = useLiquitySelector(select);

  const {
    meContract,
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();

  let { t, i18n } = useTranslation();

  // const [revenue,setRevenue] = useState<string>('');

  // const [lqtyTotal, setLqtyTotal] = useState<string>('');
  
  // const [lqtyPrice, setLqtyPrice] = useState<Decimal | undefined>(undefined);

  // let lqtyTokenAddress = addresses["lqtyToken"];
  // let lusdTokenAddress = addresses["lusdToken"];

  // useEffect((): any => {
  //   meContract.lqtyToken.totalSupply().then((res:any) => {
  //     setLqtyTotal(common.numberDecimal(1000000000-Number(Decimal.from(ethers.utils.formatUnits(res, 18))), 2))
  //   })
  // }, [price])
  
  return (
    <>
      <Box
          display="flex" justifyContent="flex-start" alignItems="center"
          className={classes.img_icon}
        >
         {t('text_127')}
      </Box>
      <Box style={{marginTop:'16px'}} display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.overline_text}  variant="body1">
         Troves
        </Typography>
        <Typography className={classes.button_text} variant="button">
          {numberOfTroves}
        </Typography>
      </Box>

      <Box style={{marginTop:'16px'}} display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.overline_text}  variant="body1">
        ETH Staked
        </Typography>
        <Typography className={classes.button_text} variant="button">
          {total.collateral
          .shorten()}
        </Typography>
      </Box>

      <Box style={{marginTop:'10px'}} display="flex" justifyContent="space-between" alignItems="center">
        {/* <Box display="flex" justifyContent="space-between"> */}
        <Typography className={classes.overline_text}  variant="body1">
        {t('text_128')}
        </Typography>
        <Typography className={classes.button_text} variant="button">
          ${Decimal.from(total.collateral.mul(price))
          .shorten()}
        </Typography>
        {/* <EChartsReact option={options} /> */}
        
        {/* <Page></Page> */}
        {/* </Box> */}
      </Box>

      {/* <Box style={{marginTop:'10px'}} display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={classes.overline_text}  variant="body1">
         Farms TVL
        </Typography>
        <Typography className={classes.button_text} variant="button">
          ${totalAmount1.add(totalAmount2).shorten()}
        </Typography>
      </Box> */}
    </>
  )
}
export default Tvl
