import React, { useEffect, useReducer } from "react";
import { useWeb3React } from "@web3-react/core";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { Text, Flex, Link, Box } from "theme-ui";
import {
  Button,
  makeStyles,
  createStyles,
  Theme, } from '@material-ui/core'

import { injectedConnector } from "../connectors/injectedConnector";
import { walletConnectConnectorConf,walletlink } from "../connectors/walletConnectConnector";
import { useAuthorizedConnection } from "../hooks/useAuthorizedConnection";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

import common from '../utils/common';

import { RetryDialog } from "./RetryDialog";
import { WalletTopBar } from "./new-ui/WalletTopBar";
import { ConnectionConfirmationDialog } from "./ConnectionConfirmationDialog";
import { MetaMaskIcon } from "./MetaMaskIcon";
import { Icon } from "./Icon";
import { Modal } from "./Modal";
import { WalletTip } from "./new-ui/MonitorTip";
import walletConnect from '../assets/walletConnect.svg'
import MetaMaskIconImg from '../assets/MetaMaskIcon.svg'
import loading from '../assets/loading.svg'
import imToken from '../assets/imToken-Avatar.png'
import wallet_link from '../assets/wallet-link.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    caption: {
      fontSize: '12px',
      // fontWeight: 600,
      color: 'rgba(84, 90, 108, 1)',
      lineHeight: '16px',
      [theme.breakpoints.up('sm')]: {
        lineHeight: '20px',
      },
    },
    btn: {
      cursor: 'pointer',
      padding:'10px',
      textAlign:'center',
      width: '100%',
      borderRadius: '24px',
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: 'rgba(227, 227, 243, 0.4)',
      },
    },
    content: {
      height: 'calc(100vh - 64px)',
      top: '64px',
      position: 'absolute',
      width: '100%',
      zIndex: 0,
      // minHeight: '800px',
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100vh - 48px)',
        top: '48px',
        minHeight: '800px',
      }
    },
    subBtn: {
      marginTop: '20px',
      width: '100%',
      padding: '13px 0',
      background: '#1542CD',
      color: '#fff',
      '&:hover': {
        background: '#1542CD',
        color: '#fff',
      }
    },
    circular_box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '76px',
      height: '76px',
      margin:'20px auto!important',
      backgroundColor: '#745DDF',
      borderRadius: '50%',
    },
    "@keyframes turn": {
      "0%": {
        transform:'rotate(0deg)'
      },
      "25%": {
        transform:'rotate(90deg)'
      },
      "50%": {
        transform:'rotate(180deg)'
      },
      "75%": {
        transform:'rotate(270deg)'
      },
      "100%": {
        transform: "rotate(360deg)"
      }
    },
    loadingImg: {
      width: '24px',
      height: '24px',
      animation:'$turn 1s linear infinite'
    },
    Box1: {
      padding: '8px 16px',
      background: '#FFFFFF',
    },
    Box2: {
      display: 'block',
      fontSize: '26px',
      fontWeight: 500,
      color: '#293147',
      lineHeight: '38px'
    },
    Box3: {
      display: 'block',
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(84, 90, 108, 1)',
      lineHeight: '24px'
    }
  })
)

interface MaybeHasMetaMask {
  ethereum?: {
    isMetaMask?: boolean;
  };
}

type ConnectionState =
  | { type: "inactive" }
  | {
      type: "activating" | "active" | "rejectedByUser" | "alreadyPending" | "failed";
      connector: AbstractConnector;
    };

type ConnectionAction =
  | { type: "startActivating"; connector: AbstractConnector }
  | { type: "fail"; error: Error }
  | { type: "finishActivating" | "retry" | "cancel" | "deactivate" };

const connectionReducer: React.Reducer<ConnectionState, ConnectionAction> = (state, action) => {
  switch (action.type) {
    case "startActivating":
      return {
        type: "activating",
        connector: action.connector
      };
    case "finishActivating":
      return {
        type: "active",
        connector: state.type === "inactive" ? injectedConnector : state.connector
      };
    case "fail":
      if (state.type !== "inactive") {
        return {
          type: action.error.message.match(/user rejected/i)
            ? "rejectedByUser"
            : action.error.message.match(/already pending/i)
            ? "alreadyPending"
            : "failed",
          connector: state.connector
        };
      }
      break;
    case "retry":
      if (state.type !== "inactive") {
        return {
          type: "activating",
          connector: state.connector
        };
      }
      break;
    case "cancel":
      return {
        type: "inactive"
      };
    case "deactivate":
      return {
        type: "inactive"
      };
  }

  console.warn("Ignoring connectionReducer action:");
  console.log(action);
  console.log("  in state:");
  console.log(2323,state);

  return state;
};

const detectMetaMask = () => (window as MaybeHasMetaMask).ethereum?.isMetaMask ?? false;

type walletBoxBtnType = {
  img: any;
  handleDispatch: () => void;
  title: string;
  text: string;
}

const WalletBoxBtn: React.FC<walletBoxBtnType> =({
  img,
  handleDispatch,
  title,
  text
}) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.Box1}
      sx={{
        borderBottom: '1px solid rgba(216, 216, 216, 1)',
      }}>
      <Box
        className={classes.btn}
        onClick={handleDispatch}
      >
        {/* <MetaMaskIcon/> */}
        <img style={{ width: '64px', height: '64px' }} src={img} alt="" />
        <Box className={classes.Box2}>{title}</Box>
        <Box className={classes.Box3}>
          {text}
        </Box>
      </Box>
    </Box>
  )
};

const wallet = [
  {
    img:MetaMaskIconImg,
    title:'MetaMask',
    text:'Connect to your MetaMask Wallet',
    connector:injectedConnector,
    isRef:false
  },
  // {
  //   img:walletConnect,
  //   title:'WalletConnect',
  //   text:'Scan with WalletConnect to connect',
  //   connector:walletConnectConnectors,
  //   isRef:true
  // },
  // {
  //   img:walletConnect,
  //   title:'MetaMask',
  //   text:'Connect to your MetaMask Wallet',
  //   connector:walletConnectConnectors,
  //   isRef:true
  // },
  {
    img:MetaMaskIconImg,
    title:'MetaMask',
    text:'Connect to your MetaMask Wallet',
    connector:injectedConnector,
    isRef:false
  }
]


type WalletConnectorProps = {
  loader?: React.ReactNode;
};

type ConnectType = {
  type: string;
  ref: string;
}

const connectText = ['injectedConnector', 'walletConnectConnector','walletlink'];

const isMobile = common.isMobile();

export const WalletConnector: React.FC<WalletConnectorProps> = ({ children, loader }) => {
  const { activate, deactivate, active, error } = useWeb3React<unknown>();
  const triedAuthorizedConnection = useAuthorizedConnection();
  const [connectionState, dispatch] = useReducer(connectionReducer, { type: "inactive" });
  const isMetaMask = detectMetaMask();
  const classes = useStyles()

  const [connect, setConnect] = React.useState<ConnectType>({
    type: '',
    ref:''
  });

  const walletConnectConnectors = new WalletConnectConnector(walletConnectConnectorConf);

  useEffect(() => {
    if (connectText[1] === connect.type && connectText[1] === connect.ref) {
      activate(walletConnectConnectors);
      setConnect({
        ...connect,
        ref:''
      })
    }
  }, [connect, connectionState]);

  useEffect(() => {
    if (error) {
      dispatch({ type: "fail", error });
      deactivate();
    }
  }, [error, deactivate]);

  useEffect(() => {
    if (active) {
      dispatch({ type: "finishActivating" });
    } else {
      dispatch({ type: "deactivate" });
    }
  }, [active]);

  if (!triedAuthorizedConnection) {
    return <>{loader}</>;
  }

  if (connectionState.type === "active") {
    return <>{children}</>;
  }

  const isImToken = (window as any).imToken;

  const imTokens = !!isImToken||isMobile;

  const handleClickImtoken = () => {
    if (isMobile&&!isImToken) {
      setConnect({
        type:'walletConnectConnector',
        ref:''
      })
      dispatch({ type: "startActivating", connector: walletConnectConnectors });
      activate(walletConnectConnectors);
    } else {
      setConnect({
        type:'injectedConnector',
        ref:''
      })
      dispatch({ type: 'startActivating', connector: injectedConnector })
      activate(injectedConnector)
    }
  }

  return (
    <>
      <WalletTopBar></WalletTopBar>
      <Flex className={classes.content} sx={{ justifyContent: "center", alignItems: "center",boxShadow: 'rgb(0 0 0 / 14%) 0px 0px 30px 5px inset', }}>
        <Box
          sx={{
            maxWidth: '500px',
            minWidth: '300px',
            background: '#FFFFFF',
            boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
            borderRadius: '24px',
            border: '2px solid #E3E3F3',
            overflow:'hidden'
          }}>
          {/* {wallet.map((item) => (
            <>
              <WalletBoxBtn
                handleDispatch={() => {
                  item.isRef&&setConnect({
                    type:'walletConnectConnector',
                    ref:''
                  })
                  dispatch({ type: "startActivating", connector: item.connector });
                  activate(item.connector);
                }}
                img={item.img}
                title={item.title}
                text={item.text}
              />
            </>
          ))} */}
          <Box
            className={classes.Box1}
            sx={{
            borderBottom:'1px solid rgba(216, 216, 216, 1)',
          }}>
            <Box
              className={classes.btn}
              onClick={handleClickImtoken}
            >
              {/* <MetaMaskIcon/> */}
              <img style={{width:'64px',height:'64px'}} src={imTokens?imToken:MetaMaskIconImg} alt=""/>
              <Box
                className={classes.Box2}
               >{imTokens?'imtoken':'MetaMask'}</Box>
              <Box
                className={classes.Box3}
               >
                {imTokens ?'Connect to your imtoken Wallet' :'Connect to your MetaMask Wallet'}
                </Box>
            </Box>
          </Box>
          <Box
            className={classes.Box1}
            sx={{
              borderBottom:'1px solid rgba(216, 216, 216, 1)',
          }}>
            <Box
              className={classes.btn}
              onClick={() => {
                setConnect({
                  type:'walletConnectConnector',
                  ref:''
                })
                dispatch({ type: "startActivating", connector: walletConnectConnectors });
                activate(walletConnectConnectors);
              }}
            >
              <img style={{width:'64px',height:'64px'}} src={walletConnect} alt=""/>
              <Box
                className={classes.Box2}
                >
                WalletConnect
              </Box>
              <Box
                className={classes.Box3}
                >
                Scan with WalletConnect to connect
                </Box>
            </Box>
          </Box>

          <Box
            className={classes.Box1}
            sx={{
            borderBottom:'1px solid rgba(216, 216, 216, 1)',
          }}>
            <Box
              className={classes.btn}
              onClick={() => {
                setConnect({
                  type:'walletlink',
                  ref:''
                })
                dispatch({ type: "startActivating", connector: walletlink });
                activate(walletlink);
              }}
            >
              <img style={{width:'64px',height:'64px'}} src={wallet_link} alt=""/>
              <Box
                className={classes.Box2}>
                Coinbase Wallet
              </Box>
              <Box className={classes.Box3}>
                Connect to your Coinbase Wallet
                </Box>
            </Box>
          </Box>

          {
            isMobile && (
              <>
                <Box
                  className={classes.Box1}
            sx={{
            borderBottom:'1px solid rgba(216, 216, 216, 1)',
          }}>
            <Box
              className={classes.btn}
              onClick={() => {
                setConnect({
                  type:'injectedConnector',
                  ref:''
                })
                dispatch({ type: 'startActivating', connector: injectedConnector })
                activate(injectedConnector)
              }}
            >
              {/* <MetaMaskIcon/> */}
              <img style={{width:'64px',height:'64px'}} src={MetaMaskIconImg} alt=""/>
                    <Box
                      className={classes.Box2}
                      >MetaMask</Box>
                    <Box
                      className={classes.Box3}
                     >
                Connect to your MetaMask Wallet
                </Box>
            </Box>
          </Box>
              </>
            )
          }
          
        </Box>
        {/* {isMetaMask ? (
          <Button
            onClick={() => {
              dispatch({ type: 'startActivating', connector: injectedConnector })
              activate(injectedConnector)
            }}
          >
              <>
                <MetaMaskIcon />
                <Box sx={{ ml: 2 }}>Connect to MetaMask</Box>
              </>
            ) : (
              <>
                <Icon name="plug" size="lg" />
                <Box sx={{ ml: 2 }}>Connect wallet</Box>
              </>
            )
          </Button>
        ) : (
          <Button
            sx={{ mt: 3 }}
            onClick={() => {
              dispatch({ type: "startActivating", connector: walletConnectConnector });
              activate(walletConnectConnector);
            }}
          >
             <MetaMaskIcon />
              <Box sx={{ ml: 2 }}>Connect to MetaMask</Box>
          </Button>
        )} */}
      </Flex>

      {connectionState.type === "failed" && (
        <WalletTip
            // title={isMetaMask ? "Failed to connect to MetaMask" : "Failed to connect wallet"}
            title="Failed to connect wallet"
          onCancel={() => dispatch({ type: "cancel" })}
        >
          <Box sx={{
              marginTop:'20px',
              fontSize: '14px',
              fontWeight: 400,
              color: '#1F2533',
            lineHeight: '20px'
          }}>
              Please switch to ETH network.
               {/* You might need to install MetaMask or use a different browser. */}
             </Box>
             <Link sx={{ lineHeight: 3 }} href="https://metamask.io/download.html" target="_blank">
               Learn more <Icon size="xs" name="external-link-alt" />
            </Link>
          <Button
            onClick={() => {
              common.clearAllStorage();
              setConnect({
                ...connect,
                ref:connect.type
              })
              dispatch({ type: "retry" });
              activate(connectionState.connector);
            }} className={classes.subBtn}> Retry</Button>
        </WalletTip>
        // <Modal>
        //   <RetryDialog
        //     title={isMetaMask ? "Failed to connect to MetaMask" : "Failed to connect wallet"}
        //     onCancel={() => dispatch({ type: "cancel" })}
        //     onRetry={() => {
        //       dispatch({ type: "retry" });
        //       activate(connectionState.connector);
        //     }}
        //   >
        //     <Box sx={{ textAlign: "center" }}>
        //       You might need to install MetaMask or use a different browser.
        //     </Box>
        //     <Link sx={{ lineHeight: 3 }} href="https://metamask.io/download.html" target="_blank">
        //       Learn more <Icon size="xs" name="external-link-alt" />
        //     </Link>
        //   </RetryDialog>
        // </Modal>
      )}

      {connectionState.type === "activating" && (
        <WalletTip
          // title={
          //   isMetaMask ? "Confirm connection in MetaMask" : "Confirm connection in your wallet"
          // }
          title="Confirm connection in your wallet"
          onCancel={() => {
            dispatch({ type: "cancel" })
            }}
        >
            <Box className={classes.circular_box}>
              <img src={loading} className={classes.loadingImg} alt=""/>
            </Box>
            <Box sx={{
                // marginTop:'20px',
                fontSize: '14px',
                fontWeight: 400,
                color: '#1F2533',
            lineHeight: '20px'
          }}>
            Confirm the request that&apos;s just appeared.
                If you can&apos;t see a request, you might have to open your wallet.
            </Box>
        </WalletTip>
        // <Modal>
        //   <ConnectionConfirmationDialog
        //     title={
        //       isMetaMask ? "Confirm connection in MetaMask" : "Confirm connection in your wallet"
        //     }
        //     icon={isMetaMask ? <MetaMaskIcon /> : <Icon name="wallet" size="lg" />}
        //     onCancel={() => dispatch({ type: "cancel" })}
        //   >
        //     <Text sx={{ textAlign: "center" }}>
        //       Confirm the request that&apos;s just appeared.
        //       {isMetaMask ? (
        //         <> If you can&apos;t see a request, open your MetaMask extension via your browser.</>
        //       ) : (
        //         <> If you can&apos;t see a request, you might have to open your wallet.</>
        //       )}
        //     </Text>
        //   </ConnectionConfirmationDialog>
        // </Modal>
      )}

      {connectionState.type === "rejectedByUser" && (
        <WalletTip
          title='Cancel connection?'
          onCancel={() => {
            dispatch({ type: "cancel" })
          }}
        >
          <Box sx={{
              marginTop:'20px',
              fontSize: '14px',
              fontWeight: 400,
              color: '#1F2533',
              lineHeight: '20px' }}>
              To use Liquity, you need to connect your Ethereum account.
            </Box>
              <Button
              onClick={() => {
                common.clearAllStorage();
                setConnect({
                  ...connect,
                  ref:connect.type
                })
                  dispatch({ type: "retry" });
                  activate(connectionState.connector);
                }}
              className={classes.subBtn}> Retry</Button>
        </WalletTip>
        // <Modal>
        //   <RetryDialog
        //     title="Cancel connection?"
        //     onCancel={() => dispatch({ type: "cancel" })}
        //     onRetry={() => {
        //       dispatch({ type: "retry" });
        //       activate(connectionState.connector);
        //     }}
        //   >
        //     <Text>To use Liquity, you need to connect your Ethereum account.</Text>
        //   </RetryDialog>
        // </Modal>
      )}

      {connectionState.type === "alreadyPending" && (
         <WalletTip
         title='Connection already requested'
         onCancel={() => {
          dispatch({ type: "cancel" })
        }}
     >
       <Box sx={{
           marginTop:'20px',
           fontSize: '14px',
           fontWeight: 400,
           color: '#1F2533',
           lineHeight: '20px' }}>
           Please check your wallet and accept the connection request before retrying.
         </Box>
           <Button
           onClick={() => {
              common.clearAllStorage();
               dispatch({ type: "retry" });
               activate(connectionState.connector);
             }}
           className={classes.subBtn}> Retry</Button>
     </WalletTip>
        // <Modal>
        //   <RetryDialog
        //     title="Connection already requested"
        //     onCancel={() => dispatch({ type: "cancel" })}
        //     onRetry={() => {
        //       dispatch({ type: "retry" });
        //       activate(connectionState.connector);
        //     }}
        //   >
        //     <Text>Please check your wallet and accept the connection request before retrying.</Text>
        //   </RetryDialog>
        // </Modal>
      )}
    </>
  );
};
