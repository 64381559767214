import { createContext, useContext } from "react";

type ContextType = {
  status: boolean;
  dispatchEvent: (val:boolean) => void;
};

export const TipContext = createContext<ContextType | null>(null);

export const useTipViewContext = (): ContextType => {
  const context: ContextType | null = useContext(TipContext);

  if (context === null) {
    throw new Error("You must add a <TipContextProvider> into the React tree");
  }

  return context;
};