import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  IconButton,
  FormHelperText
} from '@material-ui/core'
import { Flex, Spinner } from "theme-ui";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Decimal, Decimalish,Percent,LQTYStakeChange, LQTYStake, LiquityStoreState } from "@liquity/lib-base";
import { LiquityStoreUpdate, useLiquityReducer, useLiquitySelector } from "@liquity/lib-react";
import RedditTextField from './RedditTextField'
import { Transaction, TransactionFunction, useMyTransactionState } from "../../components/Transaction";
import { useLiquity } from "../../hooks/LiquityContext";
import { COIN, GT } from "../../strings";
import { useTranslation, Trans, Translation } from 'react-i18next'

import { ActionDescription, Amount } from "../ActionDescriptionNew";
import { StakingManagerAction } from "../Staking/StakingManagerAction";
import { ErrorDescription } from "../ErrorDescriptionNew";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      width: '100%',
      marginBttom: '24px',
    },
    textField: {
      width: '100%',
      margin: '0',
    },
    helperText: {
      marginTop: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    subBtn: {
      marginTop: '16px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: '#fff',
      },
    },
    footerBox: {
      marginTop:'5px',
      padding: '0 20px 30px',
      backgroundColor: 'rgba(210, 214, 220, .2)'
    },
    paper: {
      width:'360px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '24px',
      border: '2px solid #E3E3F3'
    }
  })
)

type StakingManagerActionDescriptionProps = {
  originalStake: LQTYStake;
  change: LQTYStakeChange<Decimal>;
};

const StakingManagerActionDescription: React.FC<StakingManagerActionDescriptionProps> = ({
  originalStake,
  change
}) => {
  const stakeLQTY = change.stakeLQTY?.prettify().concat(" ", GT);
  const unstakeLQTY = change.unstakeLQTY?.prettify().concat(" ", GT);
  const collateralGain = originalStake.collateralGain.nonZero?.prettify(4).concat(" ETH");
  const lusdGain = originalStake.lusdGain.nonZero?.prettify().concat(" ", COIN);

  if (originalStake.isEmpty && stakeLQTY) {
    return (
      <ActionDescription>
        You are staking <Amount>{stakeLQTY}</Amount>.
      </ActionDescription>
    );
  }

  return (
    <ActionDescription>
      {stakeLQTY && (
        <>
          You are adding <Amount>{stakeLQTY}</Amount> to your stake
        </>
      )}
      {unstakeLQTY && (
        <>
          You are withdrawing <Amount>{unstakeLQTY}</Amount> to your wallet
        </>
      )}
      {(collateralGain || lusdGain) && (
        <>
          {" "}
          and claiming{" "}
          {collateralGain && lusdGain ? (
            <>
              <Amount>{collateralGain}</Amount> and <Amount>{lusdGain}</Amount>
            </>
          ) : (
            <>
              <Amount>{collateralGain ?? lusdGain}</Amount>
            </>
          )}
        </>
      )}
      .
    </ActionDescription>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const init = ({ lqtyStake }: LiquityStoreState) => ({
  originalStake: lqtyStake,
  editedLQTY: lqtyStake.stakedLQTY,
  changePending: false
});

type StakeManagerState = ReturnType<typeof init>;
type StakeManagerAction =
  | LiquityStoreUpdate
  | { type: "startChange" | "finishChange" | "revert" }
  | { type: "setStake"; newValue: Decimalish };

const reduceWith = (action: StakeManagerAction) => (state: StakeManagerState): StakeManagerState =>
  reduce(state, action);

const finishChange = reduceWith({ type: "finishChange" });
const revert = reduceWith({ type: "revert" });

const reduce = (state: StakeManagerState, action: StakeManagerAction): StakeManagerState => {
  // console.log(state);
  // console.log(action);

  const { originalStake, editedLQTY, changePending } = state;

  switch (action.type) {
    case "startChange":
      return { ...state, changePending: true };

    case "finishChange":
      return { ...state, changePending: false };

    case "setStake":
      return { ...state, editedLQTY: Decimal.from(action.newValue) };

    case "revert":
      return { ...state, editedLQTY: originalStake.stakedLQTY };

    case "updateStore": {
      const {
        stateChange: { lqtyStake: updatedStake }
      } = action;

      if (!updatedStake) {
        return state;
      }

      const newState = { ...state, originalStake: updatedStake };

      const changeCommitted =
        !updatedStake.stakedLQTY.eq(originalStake.stakedLQTY) ||
        updatedStake.collateralGain.lt(originalStake.collateralGain) ||
        updatedStake.lusdGain.lt(originalStake.lusdGain);

      if (changePending && changeCommitted) {
        return finishChange(revert(newState));
      }

      return {
        ...newState,
        editedLQTY: updatedStake.apply(originalStake.whatChanged(editedLQTY))
      };
    }
  }
};

const select = ({ lqtyBalance, total, price }: LiquityStoreState) => ({
  lqtyBalance,
  total,
  price
});

type TroveEditorProps = {
  handleClose:() => void;
  value_index: number;
  open: boolean;
  editedL:string
}

const DialogStablePool: React.FC<TroveEditorProps> = ({
  handleClose,
  value_index,
  open,
  editedL
}) => {
  const classes = useStyles()
  let { t, i18n } = useTranslation();
  const [{ originalStake, editedLQTY, changePending }, dispatch] = useLiquityReducer(reduce, init);

  const [lqtyAmount, setLQTYAmount] = useState<string>('')

  const [editedLQTYs, seteditedLQTYs] = useState<string>(editedLQTY.toString(2))

  const { price, total, lqtyBalance } = useLiquitySelector(select)
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLQTYAmount(event.target.value)
    // event.target.value && setLUSDAmount(Decimal.from(event.target.value))
  }

  useEffect(() => {
    seteditedLQTYs(editedLQTY.toString(2))
    setLQTYAmount('')
  }, [lqtyBalance])

  useEffect(() => {
    dispatch({
      type: 'setStake',
      newValue: Number(editedLQTYs),
    })
  }, [editedLQTYs])

  useEffect(() => {
    const vals = value_index === 1 ? editedL : lqtyBalance

    if (Number(lqtyAmount) > 0 && Number(lqtyAmount) <= Number(vals)) {
    // if (Number(lqtyAmount) > 0) {
      if (value_index===1) {
        dispatch({
          type: 'setStake',
          newValue: Number(editedLQTYs) - Number(lqtyAmount),
        })
      } else {
        dispatch({
          type: 'setStake',
          newValue: Number(lqtyAmount) + Number(editedLQTYs),
        })
      }
    } else if (Number(lqtyAmount) > Number(vals)) {
      if (value_index === 1) {
        dispatch({
          type: 'setStake',
          newValue: vals,
        })
      } else {
        dispatch({
          type: 'setStake',
          newValue: Number(lqtyAmount) + Number(editedLQTYs),
        })
      }
    } else if (!Number(lqtyAmount)) {
      dispatch({
        type: 'setStake',
        newValue: Number(editedLQTYs),
      })
    }
  }, [lqtyAmount])

  const handleMaxClick = (str: string) => {
    const dl= value_index===1?editedL:(Math.floor(Number(lqtyBalance)*100)/100).toString()
    setLQTYAmount(dl)
  }

  useEffect(() => {
    setLQTYAmount('')
    // seteditedLQTYs(editedLQTY.toString(2))
  }, [value_index,open])

  // 提交
  const change = originalStake.whatChanged(editedLQTY);
  const [validChange, description] = !change
    ? [undefined, undefined]
    : change.stakeLQTY?.gt(lqtyBalance)
    ? [
        undefined,
        <ErrorDescription>
          The amount you're trying to stake exceeds your balance by{" "}
          <Amount>
            {change.stakeLQTY.sub(lqtyBalance).prettify()} {GT}
          </Amount>
          .
        </ErrorDescription>
      ]
      : [change, undefined];
    // : [change, <StakingManagerActionDescription originalStake={originalStake} change={change} />];

  const makingNewStake = originalStake.isEmpty;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper:classes.paper }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle  id="customized-dialog-title" onClose={handleClose}>
        { value_index===1?t('text_32'):t('text_33')}
        </DialogTitle>
        <DialogContent>
          <RedditTextField
            labeltext={ value_index===1?t('text_34'):t('text_35')}
            coin="LQTY"
            type='number'
            endadornmenttext={ value_index===1?t('text_36'):t('text_18')}
            endadornmentvalue={value_index===1?editedL:lqtyBalance.prettify()}
            onChange={handleChange}
            value={lqtyAmount}
            isbtn="1"
            btntext="Max"
            btncallback={handleMaxClick}
            // defaultValue={editedLUSD.toString(2)}
            className={clsx(classes.margin, classes.textField)}
            // defaultValue="react-reddit"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ backgroundColor: '#fff' }}
          />
        </DialogContent>
          {/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button> */}
        <Box className={classes.footerBox}>

          {description
          //   ??
          // (makingNewStake ? (
          //   <ActionDescription>Enter the amount of {GT} you'd like to stake.</ActionDescription>
          // ) : (
          //   <ActionDescription>Adjust the {GT} amount to stake or withdraw.</ActionDescription>
          //   ))
          }
          
          {validChange ? (
            <StakingManagerAction handleClose={handleClose} change={validChange}>{t('text_48')}</StakingManagerAction>
          ) : (
            <Button disabled className={clsx(classes.subBtn,classes.btnColor)}>{t('text_48')}</Button>
          )}

          {/* <StakingAction {...{ originalStake, editedLQTY, changePending, dispatch }}></StakingAction> */}
          <FormHelperText
            component="div"
            style={{ marginTop: '20px' }}
            className={classes.helperText}
          >
            <Typography variant="body2" gutterBottom>
            {t('text_07')}
            </Typography>
            <Typography variant="body2" gutterBottom>
            {editedL} LQTY
            </Typography>
          </FormHelperText>
        </Box>
         
      </Dialog>
    </>
  )
};

export default DialogStablePool;