import qs from 'qs';

export default {
  getPostParams: (obj: any) => {
    return qs.stringify(obj);
  },
  /**
   * 数字或字符串精确到小数点后几位
   * @param  val 参数指传过来的数值
   * @param  num 参数指精确到几位
   */
   numberDecimal(val: string|number, num: number): any {
    const numIs = Math.abs(num);
    const nums = Math.pow(10, numIs);
    if (typeof val === 'string') {
      const index = val.indexOf('.');
      if (index >= 0) {
        const floatingRight = val.substring(index + 1, index + 1 + numIs);
        const floatLeft = val.substring(0, index);
        if (floatingRight) {
          return floatLeft + '.' + floatingRight;
        } else {
          return val;
        }
      } else {
        // return Number(val).toFixed(numIs);
        return val;
      }
    } else if (typeof val === 'number') {
      const downValue = Math.floor(val * nums) / nums;
      return downValue.toFixed(numIs);
    }
  },
   /**
     * 判断是否是手机访问
     * @return {Boolean} [description]
     */
    isMobile: function() {
      var u = navigator.userAgent,
          Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"),
          mobile = false;
      for (var v = 0; v < Agents.length; v++) {
          if (u.indexOf(Agents[v]) > -1) {
              mobile = true;
          }
      }
      return mobile
  },
   /**
   * 清除cookie和本地数据localstorage
   * @return {Boolean} [description]
   */
    clearAllStorage: () => {
      localStorage.clear();
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);  
      if(keys) {  
          for(var i = keys.length; i--;)  
              document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()  
      }  
    }  
}