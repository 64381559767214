import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  IconButton,
  FormHelperText
} from '@material-ui/core'
import { Flex, Spinner } from "theme-ui";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  CRITICAL_COLLATERAL_RATIO,
  MINIMUM_COLLATERAL_RATIO,
  Difference,
  LiquityStoreState,
  Percent,
  Trove,
  TroveChange,
  Decimal,
  Decimalish,
} from '@liquity/lib-base'
import { useTranslation, Trans, Translation } from 'react-i18next'
// import { Decimal, Decimalish,Percent, LQTYStake, LiquityStoreState,Trove } from "@liquity/lib-base";
import { LiquityStoreUpdate, useLiquityReducer, useLiquitySelector } from "@liquity/lib-react";
import common from '../../utils/common';

import {
  selectForTroveChangeValidation,
  validateTroveChange
} from "../Trove/validation/validateTroveChangeNew";
import { TroveAction } from "../Trove/TroveActionNew";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      width: '100%',
      marginBttom: '24px',
    },
    textField: {
      width: '100%',
      margin: '0',
    },
    helperText: {
      marginTop: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    subBtn: {
      marginTop: '16px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: '#fff',
      },
    },
    contentBox: {
      padding: '0 20px 30px',
      backgroundColor: 'rgba(210, 214, 220, .2)'
    },
    paper: {
      width:'360px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '24px',
      border: '2px solid #E3E3F3'
    },
    leftText: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#1F2533',
      lineHeight: '20px'
    },
    rightText: {
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(84, 90, 108, 1)',
      lineHeight: '20px'
    }
  })
)

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const select = (state: LiquityStoreState) => ({
  price: state.price,
  trove: state.trove,
  validationContext: selectForTroveChangeValidation(state)
});

// type TroveEditorProps = {
//   handleClose:() => void;
//   open: boolean;
// }

// const select = ({ accountBalance, lusdBalance,collateralSurplusBalance,price }: LiquityStoreState) => ({
//   accountBalance,
//   lusdBalance,
//   redeemed: !collateralSurplusBalance.isZero,
//   price
// })

type TroveEditorProps = {
  handleClose:() => void;
  open: boolean;
  original: Trove
  edited: Trove
  afterFee: Trove
  borrowingRate: Decimal
  change?: TroveChange<Decimal>
  changePending: boolean
  dispatch: (
    action:
      | { type: 'setCollateral' | 'setDebt'; newValue: Decimalish }
      | { type: 'revert' }
  ) => void
}

const transactionId = "close-trove";

const DialogBorrow: React.FC<TroveEditorProps> = ({
  handleClose,
  open,
  original,
  edited,
  afterFee,
  borrowingRate,
  change,
  changePending,
  dispatch,
}) => {
  const classes = useStyles()
  let { t, i18n } = useTranslation();

  const {trove,validationContext } = useLiquitySelector(select)
  
  const [value, setValue] = useState({
    collateral: edited.collateral.toString(4),
    debt: edited.debt.toString(2),
  })

  const maxBorrowingRate = borrowingRate.add(0.005); // TODO slippage tolerance

  const [validChange, description] = validateTroveChange(
    original,
    edited,
    borrowingRate,
    validationContext
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper:classes.paper }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle  id="customized-dialog-title" onClose={handleClose}>
        {t('text_24')}
        </DialogTitle>
        <DialogContent className={classes.contentBox}>
          <Box>
              <FormHelperText
                component="div"
                style={{ marginTop: '20px' }}
                className={classes.helperText}
              >
                <Typography variant="body2" className={classes.leftText} gutterBottom>
                {t('text_29')}
                </Typography>
                <Typography variant="body2" className={classes.rightText} gutterBottom>
                <strong style={{marginRight:'4px'}}>{Number(trove.debt)>0 ? trove.netDebt.prettify(2):0}</strong>  LUSD
                </Typography>
              </FormHelperText>
          </Box>
          <Box style={{marginBottom:'16px'}}>
              <FormHelperText
                component="div"
                style={{ marginTop: '20px' }}
                className={classes.helperText}
              >
                <Typography variant="body2" className={classes.leftText} gutterBottom>
                {t('text_30')}
                </Typography>
                <Typography variant="body2" className={classes.rightText} gutterBottom>
                <strong style={{marginRight:'4px'}}>{trove.collateral.prettify(4)}</strong>  ETH
                </Typography>
              </FormHelperText>
          </Box>

          {description }

          {validChange ? (
          <TroveAction
            transactionId={transactionId}
            change={validChange}
            handleClose={handleClose}
            maxBorrowingRate={maxBorrowingRate}
          >
            {t('text_31')}
          </TroveAction>
          ) : (
            <Button disabled className={clsx(classes.subBtn,classes.btnColor)}>{t('text_48')}</Button>
          )}
          
        </DialogContent>
          
         
      </Dialog>
    </>
  )
};

export default DialogBorrow;