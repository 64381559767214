import React, { useEffect,useState} from 'react'
import clsx from 'clsx'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Button,
  Typography,
  Tooltip
} from '@material-ui/core'
import { Decimal, Decimalish,Percent, StabilityDeposit, LiquityStoreState } from "@liquity/lib-base";
import { LiquityStoreUpdate, useLiquityReducer, useLiquitySelector } from "@liquity/lib-react";
import { Link as RouterLink } from 'react-router-dom';

import { useLiquity } from "../../../hooks/LiquityContext";

// import EChartsReact from 'echarts-for-react';
import { ethers } from "ethers";

import common from '../../../utils/common';

import { contract_address } from '../../../eth-contract/ContractApi'

import { useTranslation, Trans, Translation } from 'react-i18next'

import { ChainId, Token, TokenAmount, Pair, WETH, Fetcher, Route, Price } from '@uniswap/sdk'

import img_icon from '../../../assets/icon-lqty1.svg';
import img_icon_09 from '../../../assets/icon-09.svg';
import icon_mask from '../../../assets/icon-mask.svg';
import icon_12 from '../../../assets/icon-12.svg';
import tip from '../../../assets/tip.svg'

// const uniLink = (lusdAddress: string) => `https://app.sushi.com/swap?inputCurrency=${contract_address.usdtToken}&outputCurrency=${lusdAddress}`;
const uniLink = (lusdAddress: string) => `https://app.uniswap.org/#/swap?inputCurrency=${lusdAddress}`;
const host = window.location.origin;
const img_pusd = `${host}/img/icon-lqty.png`;


const LQTY = new Token(ChainId.MAINNET, '0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d', 18)
const USDT = new Token(ChainId.MAINNET, '0xdac17f958d2ee523a2206206994597c13d831ec7', 6)

async function lqtyPriceApi() {
  const pairDai = await Fetcher.fetchPairData(USDT,WETH[USDT.chainId])
  const routeDai = new Route([pairDai], WETH[USDT.chainId])
  const pairLqty = await Fetcher.fetchPairData(LQTY, WETH[LQTY.chainId])
  const routeLqty = new Route([pairLqty], WETH[LQTY.chainId])
  const lqtyE = routeLqty.midPrice.toSignificant(6);
  const daiE = routeDai.midPrice.toSignificant(6);

  return 1 / Number(lqtyE) * Number(daiE);
  // console.log(routeDai.midPrice.invert().toSignificant(6)) // 202.081
  // console.log(routeLqty.midPrice.invert().toSignificant(6)) // 0.00494851
  // console.log(1/Number(lqtyE)*Number(daiE)) // 0.00494851
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // item_box: {
    //   marginTop:'30px',
    //   background: 'rgba(25, 27, 31, 0.6)',
    //   boxShadow: '6px 8px 10px 0px rgba(12, 1, 47, 0.14)',
    //   borderRadius: '12px',
    //   padding:'20px 24px'
    // },
    img_icon: {
      fontSize: '28px',
      fontWeight: 600,
      color: '#36383C',
      lineHeight: '40px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '36px',
      }
    },
    overline_text: {
      fontSize: '16px',
      fontWeight: 400,
      color: '#545A6C',
      lineHeight: '20px'
    },
    button_text: {
      marginLeft: '10px',
      fontSize: '16px',
      fontWeight: 600,
      color: '#36383C',
      lineHeight: '20px'
    },
    btn_icon: {
      fontSize: '16px',
      fontWeight: 600,
      color: '#1542CD',
      lineHeight: '20px',
      // '&:last-child': {
      //   marginLeft:'20px',
      // }
    },
    price_text: {
      fontSize: '16px',
      fontWeight: 400,
      color: 'rgba(119, 138, 166, 1)',
      lineHeight: '20px',
    },
    price_value_text: {
      marginLeft:'20px',
      fontSize: '18px',
      fontWeight: 600,
      color: '#745DDF',
      lineHeight: '20px',
    },
    tip: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '17px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '9px',
      // border: '1px solid #E3E3F3'
    }
  })
)

const options = {
  grid: { top: 0, right: 0, bottom: -30, left: 0 },
  xAxis: {
    type: 'category',
    show: false,
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  },
  yAxis: {
    show: false
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: 'line',
      showSymbol: false,
      // smooth: true,
    },
  ],
};

const select = ({
  totalStakedLQTY,
  price,
  remainingLiquidityMiningLQTYReward,
}: LiquityStoreState) => ({
  totalStakedLQTY,
  price,
  remainingLiquidityMiningLQTYReward,
});

type TotalType = {
  MultiSig1: Decimal;
  MultiSig2: Decimal;
  MultiSig3: Decimal;
  communityIssuance: Decimal;
  XHalfLife: Decimal;
  amount: Decimal;
}

const Pqy: React.FC = () => {
  const classes = useStyles()
  const {
    totalStakedLQTY,
    price,
    remainingLiquidityMiningLQTYReward,
  } = useLiquitySelector(select);

  const {
    meContract,
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();

  let { t, i18n } = useTranslation();

  const [openTip1, setOpenTip1] = React.useState(false);
  const [openTip, setOpenTip] = React.useState(false);
  const [text, setText] = useState<any>({
    text_120: t('text_120'),
    text_121: t('text_121')
  });
  useEffect(() => {
    setText({
      text_120: t('text_120'),
      text_121: t('text_121')
    })
  }, [i18n.language]);

  const [lqtyTotal, setLqtyTotal]= useState<string>('');
  const [lqtyPrice, setLqtyPrice] = useState<string>('');

  // const [LuckUp, setLuckUp] = useState<any>({
  //   'LuckUp0':Decimal.from(0),
  //   'LuckUp1':Decimal.from(0),
  //   'LuckUp2':Decimal.from(0),
  //   'LuckUp3':Decimal.from(0),
  //   'LuckUp4':Decimal.from(0),
  //   'LuckUp5':Decimal.from(0),
  //   'LuckUp6':Decimal.from(0),
  //   'LuckUp7':Decimal.from(0),
  //   'LuckUp8':Decimal.from(0),
  //   'LuckUp9':Decimal.from(0),
  //   'LuckUp10':Decimal.from(0),
  //   'LuckUp11':Decimal.from(0),
  //   'LuckUp12':Decimal.from(0),
  //   'LuckUp13':Decimal.from(0),
  //   'LuckUp14':Decimal.from(0),
  //   'LuckUp15':Decimal.from(0),
  //   'LuckUp16':Decimal.from(0),
  //   'LuckUp17':Decimal.from(0),
  //   'LuckUp18':Decimal.from(0),
  //   'LuckUp19':Decimal.from(0),
  //   'LuckUp20':Decimal.from(0),
  //   'LuckUp21':Decimal.from(0),
  //   'LuckUp22':Decimal.from(0),
  //   'LuckUp23':Decimal.from(0),
  //   'LuckUp24':Decimal.from(0),
  //   'LuckUp25':Decimal.from(0),
  //   'LuckUp26':Decimal.from(0),
  //   'LuckUp27':Decimal.from(0),
  //   'LuckUp28':Decimal.from(0),
  //   'LuckUp29':Decimal.from(0),
  //   'LuckUp30':Decimal.from(0),
  // });

  const [LuckUp, setLuckUp] = useState<Decimal>(Decimal.from(0));
  const [LuckUp0, setLuckUp0] = useState<Decimal>(Decimal.from(0));
  const [LuckUp1, setLuckUp1] = useState<Decimal>(Decimal.from(0));
  const [LuckUp2, setLuckUp2] = useState<Decimal>(Decimal.from(0));
  const [LuckUp3, setLuckUp3] = useState<Decimal>(Decimal.from(0));
  const [LuckUp4, setLuckUp4] = useState<Decimal>(Decimal.from(0));
  const [LuckUp5, setLuckUp5] = useState<Decimal>(Decimal.from(0));
  const [LuckUp6, setLuckUp6] = useState<Decimal>(Decimal.from(0));
  const [LuckUp7, setLuckUp7] = useState<Decimal>(Decimal.from(0));
  const [LuckUp8, setLuckUp8] = useState<Decimal>(Decimal.from(0));
  const [LuckUp9, setLuckUp9] = useState<Decimal>(Decimal.from(0));
  const [LuckUp10, setLuckUp10] = useState<Decimal>(Decimal.from(0));
  const [LuckUp11, setLuckUp11] = useState<Decimal>(Decimal.from(0));
  const [LuckUp12, setLuckUp12] = useState<Decimal>(Decimal.from(0));
  const [LuckUp13, setLuckUp13] = useState<Decimal>(Decimal.from(0));
  const [LuckUp14, setLuckUp14] = useState<Decimal>(Decimal.from(0));
  const [LuckUp15, setLuckUp15] = useState<Decimal>(Decimal.from(0));
  const [LuckUp16, setLuckUp16] = useState<Decimal>(Decimal.from(0));
  const [LuckUp17, setLuckUp17] = useState<Decimal>(Decimal.from(0));
  const [LuckUp18, setLuckUp18] = useState<Decimal>(Decimal.from(0));
  const [LuckUp19, setLuckUp19] = useState<Decimal>(Decimal.from(0));
  const [LuckUp20, setLuckUp20] = useState<Decimal>(Decimal.from(0));
  const [LuckUp21, setLuckUp21] = useState<Decimal>(Decimal.from(0));
  const [LuckUp22, setLuckUp22] = useState<Decimal>(Decimal.from(0));
  const [LuckUp23, setLuckUp23] = useState<Decimal>(Decimal.from(0));
  const [LuckUp24, setLuckUp24] = useState<Decimal>(Decimal.from(0));
  const [LuckUp25, setLuckUp25] = useState<Decimal>(Decimal.from(0));
  const [LuckUp26, setLuckUp26] = useState<Decimal>(Decimal.from(0));
  const [LuckUp27, setLuckUp27] = useState<Decimal>(Decimal.from(0));
  const [LuckUp28, setLuckUp28] = useState<Decimal>(Decimal.from(0));
  const [LuckUp29, setLuckUp29] = useState<Decimal>(Decimal.from(0));
  const [LuckUp30, setLuckUp30] = useState<Decimal>(Decimal.from(0));

  const [MultiSig1, setMultiSig1] = useState<Decimal>(Decimal.from(0));
  const [MultiSig2, setMultiSig2] = useState<Decimal>(Decimal.from(0));
  const [MultiSig3, setMultiSig3] = useState<Decimal>(Decimal.from(0));
  const [communityIssuance, setCommunityIssuance] = useState<Decimal>(Decimal.from(0));
  const [communityIssuanceUsdc, setCommunityIssuanceUsdc] = useState<Decimal>(Decimal.from(0));
  const [communityIssuanceEth, setCommunityIssuanceEth] = useState<Decimal>(Decimal.from(0));
  const [remainingLiquidityMiningLQTYRewardFarm, setRemainingLiquidityMiningLQTYRewardFarm] = useState<Decimal>(Decimal.from(0));
  const [XHalfLife, setXHalfLife] = useState<Decimal>(Decimal.from(0));
  const [amount, setAmount] = useState<Decimal>(Decimal.from(0));

  const lqtyTokenAddress = addresses["lqtyToken"];
  // let lusdTokenAddress = addresses["lusdToken"];

  const handleAddCoinLqty = () => {
    (window as any).ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', 
        options: {
          address: lqtyTokenAddress, 
          symbol: 'LQTY',
          decimals: 18,
          image: img_pusd, 
        },
      },
    });
  }

  useEffect(() => {
    meContract.lqtyToken.totalSupply().then((res:any) => {
      setLqtyTotal(common.numberDecimal(100000000-Number(Decimal.from(ethers.utils.formatUnits(res, 18))), 2))
    })

    meContract.lqtyToken.balanceOf(contract_address.multiTroveGetter).then((res:any) => {
      setMultiSig1(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })

    // meContract.lqtyToken.balanceOf(contract_address.MultiSigForPUSDReward).then((res:any) => {
    //   setMultiSig2(Decimal.from(ethers.utils.formatUnits(res, 18)))
    // })

    meContract.lqtyToken.balanceOf(addresses["unipool"]).then((res:any) => {
      setMultiSig3(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })

    meContract.lqtyToken.balanceOf(addresses["communityIssuance"]).then((res:any) => {
      setCommunityIssuance(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })

    meContract.lqtyToken.balanceOf(contract_address.XHalfLife).then((res:any) => {
      setXHalfLife(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })

    meContract.lqtyToken.balanceOf(contract_address.LuckUp).then((res:any) => {
      // console.log('up',ethers.utils.formatUnits(res, 18))
      setLuckUp(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp0).then((res:any) => {
      // console.log('0',ethers.utils.formatUnits(res, 18))
      setLuckUp0(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp1).then((res:any) => {
      // console.log(1,ethers.utils.formatUnits(res, 18))
      setLuckUp1(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp2).then((res:any) => {
      // console.log(2,ethers.utils.formatUnits(res, 18))
      setLuckUp2(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp3).then((res:any) => {
      // console.log(3,ethers.utils.formatUnits(res, 18))
      setLuckUp3(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })

    meContract.lqtyToken.balanceOf(contract_address.LuckUp4).then((res:any) => {
      // console.log(4,ethers.utils.formatUnits(res, 18))
      setLuckUp4(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp5).then((res:any) => {
      // console.log(5,ethers.utils.formatUnits(res, 18))
      setLuckUp5(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp6).then((res:any) => {
      // console.log(6,ethers.utils.formatUnits(res, 18))
      setLuckUp6(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp7).then((res:any) => {
      // console.log(7,ethers.utils.formatUnits(res, 18))
      setLuckUp7(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp8).then((res:any) => {
      // console.log(8,ethers.utils.formatUnits(res, 18))
      setLuckUp8(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp9).then((res:any) => {
      // console.log(9,ethers.utils.formatUnits(res, 18))
      setLuckUp9(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp10).then((res:any) => {
      // console.log(10,ethers.utils.formatUnits(res, 18))
      setLuckUp10(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp11).then((res:any) => {
      // console.log(11,ethers.utils.formatUnits(res, 18))
      setLuckUp11(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp12).then((res:any) => {
      // console.log(12,ethers.utils.formatUnits(res, 18))
      setLuckUp12(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp13).then((res:any) => {
      // console.log(13,ethers.utils.formatUnits(res, 18))
      setLuckUp13(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp14).then((res:any) => {
      // console.log(14,ethers.utils.formatUnits(res, 18))
      setLuckUp14(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp15).then((res:any) => {
      // console.log(15,ethers.utils.formatUnits(res, 18))
      setLuckUp15(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp16).then((res:any) => {
      // console.log(16,ethers.utils.formatUnits(res, 18))
      setLuckUp16(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp17).then((res:any) => {
      // console.log(17,ethers.utils.formatUnits(res, 18))
      setLuckUp17(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp18).then((res:any) => {
      // console.log(18,ethers.utils.formatUnits(res, 18))
      setLuckUp18(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp19).then((res:any) => {
      // console.log(19,ethers.utils.formatUnits(res, 18))
      setLuckUp19(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp20).then((res:any) => {
      // console.log(20,ethers.utils.formatUnits(res, 18))
      setLuckUp20(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp21).then((res:any) => {
      // console.log(21,ethers.utils.formatUnits(res, 18))
      setLuckUp21(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp22).then((res:any) => {
      // console.log(22,ethers.utils.formatUnits(res, 18))
      setLuckUp22(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp23).then((res:any) => {
      // console.log(23,ethers.utils.formatUnits(res, 18))
      setLuckUp23(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp24).then((res:any) => {
      // console.log(24,ethers.utils.formatUnits(res, 18))
      setLuckUp24(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp25).then((res:any) => {
      // console.log(25,ethers.utils.formatUnits(res, 18))
      setLuckUp25(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp26).then((res:any) => {
      // console.log(26,ethers.utils.formatUnits(res, 18))
      setLuckUp26(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp27).then((res:any) => {
      // console.log(27,ethers.utils.formatUnits(res, 18))
      setLuckUp27(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp28).then((res:any) => {
      // console.log(28,ethers.utils.formatUnits(res, 18))
      setLuckUp28(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })
    meContract.lqtyToken.balanceOf(contract_address.LuckUp29).then((res:any) => {
      // console.log(29,ethers.utils.formatUnits(res, 18))
      setLuckUp29(Decimal.from(ethers.utils.formatUnits(res, 18)))
    })

    // contract_address_luck.forEach((item,index)=>{
    //   meContract.lqtyToken.balanceOf(item).then((res:any) => {
    //     setLuckUp(
    //       (prevState:any) => {
    //         return { 
    //           ...prevState,
    //            ...{ `LuckUp${index}`: Decimal.from(ethers.utils.formatUnits(res, 18)) } 
    //           }
    //         }
    //     )

    //     // const set:React.Dispatch<React.SetStateAction<Decimal>>|any =`setLuckUp${index}`;
    //     // console.log(12233,set)
    //     // set(Decimal.from(ethers.utils.formatUnits(res, 18)))
    //   })
    // })
  }, []);

  

  useEffect(() => {
    lqtyPriceApi().then((res:any) => {
      setLqtyPrice(res.toFixed(2))
    })
  }, [price]);

  // 获取lqty总市值
  useEffect(() => {
    if (lqtyTotal) {
      // console.log(33333,Number(LuckUp.add(LuckUp0).add(LuckUp1).add(LuckUp2).add(LuckUp3).add(LuckUp4)
      // .add(LuckUp5).add(LuckUp6).add(LuckUp7).add(LuckUp8).add(LuckUp9).add(LuckUp10)
      // .add(LuckUp11).add(LuckUp12).add(LuckUp13).add(LuckUp14).add(LuckUp15).add(LuckUp16)
      // .add(LuckUp17).add(LuckUp18).add(LuckUp19).add(LuckUp20).add(LuckUp21).add(LuckUp22)
      // .add(LuckUp23).add(LuckUp24).add(LuckUp25).add(LuckUp26).add(LuckUp27).add(LuckUp28)
      // .add(LuckUp29)));

      // console.log(44444,Number(Decimal.from(lqtyTotal)
      // .add(MultiSig1)
      // .add(MultiSig2)
      // .add(MultiSig3)
      // .add(XHalfLife).add(communityIssuance)
      // .add(communityIssuanceUsdc)
      // .add(communityIssuanceEth)
      // .add(remainingLiquidityMiningLQTYRewardFarm)
      // .add(remainingLiquidityMiningLQTYReward)))

      setAmount(
        Decimal.from(100000000)
          .sub(Decimal.from(lqtyTotal)
            .add(MultiSig1)
            .add(MultiSig2)
            .add(MultiSig3)
            .add(XHalfLife)
            .add(LuckUp).add(LuckUp0).add(LuckUp1).add(LuckUp2).add(LuckUp3).add(LuckUp4)
            .add(LuckUp5).add(LuckUp6).add(LuckUp7).add(LuckUp8).add(LuckUp9).add(LuckUp10)
            .add(LuckUp11).add(LuckUp12).add(LuckUp13).add(LuckUp14).add(LuckUp15).add(LuckUp16)
            .add(LuckUp17).add(LuckUp18).add(LuckUp19).add(LuckUp20).add(LuckUp21).add(LuckUp22)
            .add(LuckUp23).add(LuckUp24).add(LuckUp25).add(LuckUp26).add(LuckUp27).add(LuckUp28)
            .add(LuckUp29)
            .add(communityIssuance)
            .add(communityIssuanceUsdc)
            .add(communityIssuanceEth)
            .add(remainingLiquidityMiningLQTYRewardFarm)
            .add(remainingLiquidityMiningLQTYReward))
      )
    }
  }, [
    MultiSig1,
    MultiSig2,
    MultiSig3,
    XHalfLife,
    LuckUp,LuckUp1,LuckUp2,LuckUp3,LuckUp4,LuckUp5,
    LuckUp6,LuckUp7,LuckUp8,LuckUp9,LuckUp10,LuckUp11,
    LuckUp12,LuckUp13,LuckUp14,LuckUp15,LuckUp16,LuckUp17,
    LuckUp18,LuckUp19,LuckUp20,LuckUp21,LuckUp22,LuckUp23,
    LuckUp24,LuckUp25,LuckUp26,LuckUp27,LuckUp28,LuckUp29,
    communityIssuance,
    communityIssuanceUsdc,
    communityIssuanceEth,
    remainingLiquidityMiningLQTYRewardFarm,
    remainingLiquidityMiningLQTYReward,
    lqtyTotal
  ])
  
  // useEffect(() => {
  //   meContract.contractEth.getAmountsOut(ethers.utils.parseUnits('1',18),[lqtyTokenAddress,contract_address.usdtToken]).then((res:any) => {
  //     setLqtyPrice(common.numberDecimal(ethers.utils.formatUnits(res[1], 6), 4))
  //   })
  // }, [price]);
  
  return (
    <>
      {/* <Box className={classes.item_box}> */}
        <Box display="flex" justifyContent="space-between">
          <Box flex="1">
            <Box
               display="flex" justifyContent="space-between" alignItems="center"
              className={classes.img_icon}
          >
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <img style={{marginRight:'16px'}} src={ img_icon } alt="" />
                LQTY
              </Box>
            
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Typography className={classes.price_text}  variant="body1">
                {t('text_96')}
                </Typography>
                <Typography className={classes.price_value_text} style={{maxWidth:'100px',overflow:'hidden'}} variant="button" >
                $ {lqtyPrice||'--'}
                </Typography>
              </Box>
            </Box>

            <Box style={{marginTop:'16px'}} display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={classes.overline_text}  variant="body1">
               Total Supply 
              </Typography>
              <Typography className={classes.button_text} variant="button" >
              100M
              </Typography>
            </Box>

            <Box style={{marginTop:'16px'}} display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={classes.overline_text}  variant="body1">
               {t('text_119')}
               <Tooltip
                  classes={{
                    tooltip:classes.tip
                  }}
                  onClose={()=>setOpenTip(false)}
                  open={openTip}
                  title={text.text_120}
                  interactive placement="top">
                  <img src={tip}  onClick={()=>setOpenTip(true)} style={{width:'16px', marginLeft: '4px',marginBottom:'-2px'}} alt=""/>
                </Tooltip>
              </Typography>
              <Typography className={classes.button_text} variant="button" >
              {amount.shorten()}  ({amount.div(Decimal.from(100000000)).mul(Decimal.from(100)).toString(2)}%)
              </Typography>
            </Box>
            <Box style={{marginTop:'16px'}} display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={classes.overline_text}  variant="body1">
               {t('text_118')}
              </Typography>
              <Typography className={classes.button_text} variant="button" >
              ${lqtyPrice && Decimal.from(100000000).mul(Decimal.from(lqtyPrice)).shorten()}
              {/* ({amount.div(Decimal.from(1000000000)).mul(Decimal.from(100)).toString(2)}%) */}
              </Typography>
            </Box>
            {/* <Box style={{marginTop:'16px'}} display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={classes.overline_text}  variant="body1">
              {t('text_97')}
              </Typography>
              <Typography className={classes.button_text} variant="button" >
              {lqtyTotal && Decimal.from(lqtyTotal).shorten()} ({lqtyTotal && amount.nonZero && Decimal.from(lqtyTotal).div(amount).mul(Decimal.from(100)).toString(2)}%)
              <Tooltip
              classes={{
                tooltip:classes.tip
              }}
              onClose={()=>setOpenTip1(false)}
              open={openTip1}
              title={text.text_121}
              interactive placement="top">
              <img src={tip}  onClick={()=>setOpenTip1(true)} style={{width:'16px', marginLeft: '4px',marginBottom:'-2px'}} alt=""/>
            </Tooltip>
              </Typography>
            </Box> */}

            <Box style={{ marginTop: '16px' }}>
              <Button
                className={classes.btn_icon}
                onClick={handleAddCoinLqty}
                startIcon={<img style={{marginRight:'2px'}} src={ icon_mask } alt="" />  }
              >
                {t('text_99')}
              </Button>
              <Button
                className={classes.btn_icon}
              href={uniLink(lqtyTokenAddress)}
              style={{marginLeft:'20px'}}
                target="_blank"
                startIcon={<img style={{marginRight:'2px'}} src={ img_icon_09 } alt="" />  }
              >
              {t('text_100')}
            </Button>
            <Button
              className={classes.btn_icon}
              style={{ marginLeft: '20px' }}
              component={RouterLink}
                // href={uniLink(lqtyTokenAddress)}
                to="/stake"
                startIcon={<img style={{marginRight:'2px'}} src={ icon_12 } alt="" />  }
              >
              {t('text_11')}
              </Button>
            </Box>
          </Box>
          {/* <Box style={{marginTop:'12px'}}> */}
            
            {/* <EChartsReact option={options} style={{ height: 30,marginTop:'20px' }}/> */}
          {/* </Box> */}
        </Box>
      {/* </Box> */}
    </>
  )
}
export default Pqy
