import React, { useEffect,useState} from 'react'
import clsx from 'clsx'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Fade,
} from '@material-ui/core';
import Pqy from './Pqy';
import Pusd from './Pusd';
import Wallet from './Wallet';
import Ratio from './Ratio';
import Tvl from './Tvl';

import { fetchLqtyPrice } from "../../../components/Stability/context/fetchLqtyPrice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    board_box: {
      marginTop: '50px',
      flexWrap: 'wrap',
      [theme.breakpoints.down('md')]: {
        marginTop: '20px',
      }
    },
    data_flex: {
      width: '440px',
      minWidth:'440px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth:'300px',
        // order: 1,
      }
    },
    chart_box: {
      marginLeft: '30px',
      minWidth:'440px',
      [theme.breakpoints.down('sm')]: {
        // maxWidth:'440px',
        minWidth:'300px',
        width:'100%',
        marginLeft: '0',
        marginTop:'30px'
      },
      // [theme.breakpoints.down('sm')]: {
      //   maxWidth:'440px',
      //   marginLeft: '0',
      //   width:'100%',
      //   marginTop:'20px'
      // }
    },
    item_box: {
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '12px',
      border: '2px solid #E3E3F3',
      padding: '20px 24px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 12px',
      }
    }
  })
)

const DashboardIndex: React.FC = () => {
  const classes = useStyles()
  const [checked, setChecked] = useState(true);
  
  // const [open, setOpen] = useState(true);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const { lqtyPriceUSD } = await fetchLqtyPrice('0x6DEA81C8171D0bA574754EF6F8b412F2Ed88c54D');
  //       console.log(123,Number(lqtyPriceUSD))
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   })();
  // },[])
  
  return (
    <>
      <Box className={classes.board_box} display="flex" justifyContent="center">
        <Box className={classes.data_flex}>

          <Fade in={checked} {...(checked ? { timeout: 100 } : {})}>
            <Box className={classes.item_box}>
              <Pusd/>
            </Box>
          </Fade>

          <Fade
            in={checked}
            style={{ transformOrigin: '0 0 0' }}
            {...(checked ? { timeout: 500 } : {})}
          >
            <Box className={classes.item_box} style={{marginTop:'30px'}}>
              <Pqy/>
            </Box> 
          </Fade>

          <Fade
            in={checked}
            style={{ transformOrigin: '0 0 0' }}
            {...(checked ? { timeout: 1000 } : {})}
          >
            <Box className={classes.item_box} style={{marginTop:'30px'}}>
              <Wallet/>
            </Box>
          </Fade>
          
        </Box>
        <Box className={classes.chart_box}>

          <Fade in={checked} {...(checked ? { timeout: 500 } : {})}>
            <Box className={classes.item_box}>
              <Tvl/>
            </Box>
          </Fade>

          <Fade
            in={checked}
            style={{ transformOrigin: '0 0 0' }}
            {...(checked ? { timeout: 1500 } : {})}
          >
            <Box className={classes.item_box} style={{marginTop:'30px'}}>
              <Ratio/>
            </Box>
          </Fade>

        </Box>

        {/* <RpcLinkLine  open={open} handleClose={() => setOpen(false)}></RpcLinkLine> */}
      </Box>

      
    </>
  )
}
export default DashboardIndex
