
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
const POLLING_INTERVAL = 12000
const RPC_URLS = {
  1:'https://mainnet.infura.io/v3/3a4aa05a8a394ada85ed7bafda88107e',
}

export const walletConnectConnectorConf ={
  rpc: {
    1: 'https://mainnet.infura.io/v3/3a4aa05a8a394ada85ed7bafda88107e',
    // 1: 'https://mainnet.infura.io/v3/84842078b09946638c03157f83405213',
    // 42: "https://kovan.infura.io/v3/ad9cef41c9c844a7b54d10be24d416e5",
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL
};

export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[1],
  appName: 'web3-react example'
})