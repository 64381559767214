
const DE = {
  text_01:"Einzahlen",
  text_02: "Abheben",
  text_03: "Einlösung ",
  text_04: "Insgesamt gestaket",
  text_05: "",
  text_06: "",
  text_07: "STAKED",
  text_08: "Stake ",
  text_09: "Leihen",
  text_091: "Leihen",
  text_092: "Leihen",
  text_10: "Stabilitätspool",
  text_11: "Stake",
  text_12: "Liquidation",
  text_13: "LQTY verbleibend",
  text_14: "Belohnung",
  text_15: "Abheben",
  text_16: "Einzahlen",
  text_17: "Verfügbar: ",
  text_18: "Wallet: ",
  text_19: "Zurückzahlen",
  text_20: "Die Verschuldung muss mindestens 2000 LUSD betragen.",
  text_21: "Die minimale Besicherungsquote beträgt zwar 110 % im Normalbetrieb, es wird jedoch empfohlen, die Besicherungsquote immer über 150 % zu halten, um eine Liquidation im Eintreibungsmodus zu vermeiden. Für zusätzliche Sicherheit wird eine Besicherungsquote von über 200 % oder 250 % empfohlen.",
  text_22: "Verfügbar: ",
  text_23: "Stellen Sie sicher, dass genügend LUSD vorhanden ist, bevor Sie den Trove schließen.",
  text_24: "Trove schließen",
  text_25: "Diese Gebühr deckt die Gaskosten, die ein Liquidator für die Liquidation Ihres Trove zahlen müsste. Sie erhalten diese Gebühr zurück, wenn Sie Ihre Schuld begleichen.",
  text_26: "Besicherungsquote",
  // text_261: "Besicherungsquote",
  text_261: "Ratio",
  text_27: "Einlösung",
  text_28: "Schuld",
  text_29: "Zurückzahlen",
  text_30: "Abheben",
  text_31: "Trove schließen",
  text_32: "Freigeben",
  text_33: "Staken",
  text_34: "Abheben",
  text_35: "Staken",
  text_36: "Verfügbar: ",
  // text_37: "LUSD-Gesamtanzahl",
  // text_38: "Gesamtbesicherungsquote",
  text_37: "Total LUSD supply",
  text_38: "Total Collateral Ratio",
  text_39: "Preis",
  text_40: " APR",
  text_41: "Inhaber",
  text_42: "Sicherheit (ETH)",
  text_43: "Schuld (LUSD)",
  text_44: "Besicherungsquote",
  text_45: "Status",
  text_46: "Liquidieren",
  text_lp: "Die LQTY-Belohnungen in diesem Pool halten 6 Wochen.",
  text_47: "Die LQTY-Belohnungen in diesem Pool halten 35 Monate.",
  text_48: "Bestätigen",
  text_49: "Bestätigen",
  text_50: "Ausstehend",
  text_51: "Success",
  text_52: "Transaction Confirmed",
  text_53: "Failure ",
  text_54: "Please try again.",
  text_55: "Error",
  text_56: "Transaction Rejected",
  text_57: "Transaktion",
  text_58: "Liquidationsreserve",
  text_59: "Die Verschuldung muss mindestens",
  text_591: "betragen.",
  text_60: "Gebühr",
  text_61: "An estimate of the LUSD&ETH return on the LQTY staked to this pool.（Calculation in LUSD)",
  text_62: "Liquity Analysis",
  text_63: "Switch to DevUI",
  text_64: "Switch to FiUI",
  text_65: "Redeem",
  text_67: "Your Trove has been redeemed",
  text_68: "Please reclaim your remaining collateral before opening a new Trove.",
  text_69: "Account",
  text_70: "Connected ",
  text_71: "Change",
  text_720: "The total amount of LUSD your Trove will hold. You will need to repay ",
  text_721: " LUSD to reclaim your collateral (200 LUSD Liquidation Reserve excluded).",
  text_730: "Note: ",
  text_731: "Redemption is not for repaying your loan. To repay your loan, switch to",
  text_732: "Withdraw tab.",
  text_91: "Normal Mode:",
  text_92: "Recovery Mode:",
  text_93: "Recovery Mode Price :",
  text_94: "The dollar value per unit of collateral at which your Trove will drop below a 110% Collateral Ratio and be liquidated. You should ensure you are comfortable with managing your position so that the price of your collateral never reaches this level.",
  text_95: "Liquidation price",
  text_96: "Price",
  text_97: "Currently Burned",
  text_99: "Add",
  text_100: "Buy",
  text_102: "My Wallet",
  text_118: "Market Cap",
  text_119: "Circulating Supply",
  text_127: "Statistics",
  text_128: "TVL",
  text_120: "The amount of LQTY  that are circulating in the market. Formula = (Circulating Supply / Total Supply)*100%",
  text_371: "Total supply",
  text_041: "LUSD in Stability Pool",
  text_863: "Home",
  text_123: "Liquity 生态",
}
export default DE;