import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Button,
  Typography
} from '@material-ui/core'
import { Decimal, Decimalish,Percent, StabilityDeposit, LiquityStoreState } from "@liquity/lib-base";
import { LiquityStoreUpdate, useLiquityReducer, useLiquitySelector } from "@liquity/lib-react";

import { useTranslation, Trans, Translation } from 'react-i18next'

import { useLiquity } from "../../../hooks/LiquityContext";
import { ethers } from "ethers";

import { ChainId, Token, TokenAmount, Pair, WETH, Fetcher, Route, Price } from '@uniswap/sdk'

// import EChartsReact from 'echarts-for-react';
import { contract_address } from '../../../eth-contract/ContractApi'

import common from '../../../utils/common';

import img_icon_09 from '../../../assets/icon-09.svg';
import img_icon from '../../../assets/icon-lqty.svg';
import icon_mask from '../../../assets/icon-mask.svg';
import icon_12 from '../../../assets/icon-12.svg';

// const uniLink = (lusdAddress: string) => `https://app.sushi.com/swap?inputCurrency=${contract_address.usdtToken}&outputCurrency=${lusdAddress}`;
const uniLink = (lusdAddress: string) => `https://curve.fi/lusd`;

const host = window.location.origin;
const img_pusd = `${host}/img/lusd.png`;

const LQTY = new Token(ChainId.MAINNET, '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0', 18)
const USDT = new Token(ChainId.MAINNET, '0xdac17f958d2ee523a2206206994597c13d831ec7', 6)

async function lusdPriceApi() {
  const pairDai = await Fetcher.fetchPairData(USDT,WETH[USDT.chainId])
  const routeDai = new Route([pairDai], WETH[USDT.chainId])
  const pairLqty = await Fetcher.fetchPairData(LQTY, WETH[LQTY.chainId])
  const routeLqty = new Route([pairLqty], WETH[LQTY.chainId])
  const lqtyE = routeLqty.midPrice.toSignificant(6);
  const daiE = routeDai.midPrice.toSignificant(6);

  return 1 / Number(lqtyE) * Number(daiE);
  // console.log(routeDai.midPrice.invert().toSignificant(6)) // 202.081
  // console.log(routeLqty.midPrice.invert().toSignificant(6)) // 0.00494851
  // console.log(1/Number(lqtyE)*Number(daiE)) // 0.00494851
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // item_box: {
    //   background: 'rgba(25, 27, 31, 0.6)',
    //   boxShadow: '6px 8px 10px 0px rgba(12, 1, 47, 0.14)',
    //   borderRadius: '12px',
    //   padding:'20px 24px'
    // },
    img_icon: {
      fontSize: '28px',
      fontWeight: 600,   
      color: '#36383C',
      lineHeight: '40px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '36px',
      }
    },
    overline_text: {
      fontSize: '16px',
      fontWeight: 400,
      color: '#545A6C',
      lineHeight: '20px'
    },
    button_text: {
      marginLeft: '10px',
      fontSize: '16px',
      fontWeight: 600,
      color: '#36383C',
      lineHeight: '20px'
    },
    btn_icon: {
      fontSize: '16px',
      fontWeight: 600,
      color: '#1542CD',
      lineHeight: '20px',
      '&:last-child': {
        marginLeft:'20px',
      }
    },
    price_text: {
      fontSize: '16px',
      fontWeight: 400,
      color: 'rgba(119, 138, 166, 1)',
      lineHeight: '20px',
    },
    price_value_text: {
      marginLeft:'20px',
      fontSize: '18px',
      fontWeight: 600,
      color: '#745DDF',
      lineHeight: '20px',
    }
  })
)

const options = {
  grid: { top: 0, right: 0, bottom: -30, left: 0 },
  xAxis: {
    type: 'category',
    show: false,
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  },
  yAxis: {
    show: false
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: 'line',
      showSymbol: false,
      // smooth: true,
    },
  ],
};

const select = ({
  total,
  price,
  lusdInStabilityPool
}: LiquityStoreState) => ({
  total,
  price,
  lusdInStabilityPool
});

const Pusd: React.FC = () => {
  const classes = useStyles();
  let { t, i18n } = useTranslation();

  const [pusdPrice, setPusdPrice]= useState<Decimal | undefined>(undefined);

  const {
    total,
    price,
    lusdInStabilityPool
  } = useLiquitySelector(select);

  const {
    meContract,
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();
 
  // const lqtyTokenAddress = addresses["lqtyToken"];
  let lusdTokenAddress = addresses["lusdToken"];

  const handleAddCoin = () => {
    (window as any).ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', 
        options: {
          address: lusdTokenAddress, 
          symbol: 'LUSD',
          decimals: 18,
          image: img_pusd, 
        },
      },
    });
  }

  useEffect(() => {
    meContract.contractEth.get_dy_underlying(0,1,ethers.utils.parseUnits('1',18)).then((res:any) => {
      setPusdPrice(Decimal.fromBigNumberString(res))
    })
  }, [price]);

  return (
    <>
      {/* <Box className={classes.item_box}> */}
        <Box display="flex" justifyContent="space-between">
          <Box flex="1">
            <Box
               display="flex" justifyContent="space-between" alignItems="center"
              className={classes.img_icon}
          >
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <img style={{marginRight:'16px'}} src={ img_icon } alt="" />
                LUSD
              </Box>
           
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Typography className={classes.price_text}  variant="body1">
                {t('text_96')}
                </Typography>
                <Typography className={classes.price_value_text} style={{maxWidth:'100px',overflow:'hidden'}} variant="button" >
                $ {pusdPrice?.prettify(4)||'--'}
                </Typography>
              </Box>
            </Box>
            <Box style={{marginTop:'16px'}} display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={classes.overline_text}  variant="body1">
              {t('text_371')}
              </Typography>
              <Typography className={classes.button_text} style={{maxWidth:'100px',overflow:'hidden'}} variant="button" >
              {total.debt.shorten()}
              </Typography>
          </Box>
          <Box style={{marginTop:'16px'}} display="flex" justifyContent="space-between" alignItems="center">
              <Typography className={classes.overline_text}  variant="body1">
              {t('text_041')}
              </Typography>
              <Typography className={classes.button_text} style={{maxWidth:'100px',overflow:'hidden'}} variant="button" >
              {lusdInStabilityPool.shorten()}
              </Typography>
            </Box>
            <Box style={{ marginTop: '16px' }} >
              <Button
                className={classes.btn_icon}
                onClick={handleAddCoin}
                startIcon={<img style={{marginRight:'2px'}} src={ icon_mask } alt="" />  }
              >
               {t('text_99')}
              </Button>
              <Button
                className={classes.btn_icon}
              href={uniLink(lusdTokenAddress)}
              style={{ marginLeft: '20px' }}
                target="_blank"
                startIcon={<img style={{marginRight:'2px'}} src={ img_icon_09 } alt="" />  }
              >
              {t('text_100')}
            </Button>
            <Button
              className={classes.btn_icon}
              style={{ marginLeft: '20px' }}
              component={RouterLink}
              // href={uniLink(lqtyTokenAddress)}
              to="/pool"
              startIcon={<img style={{marginRight:'2px'}} src={ icon_12 } alt="" />  }
              >
              {t('text_11')}
              </Button>
            </Box>
          </Box>
          {/* <Box style={{marginTop:'12px'}}> */}
            
            {/* <EChartsReact option={options} style={{ height: 30,marginTop:'20px' }}/> */}
          {/* </Box> */}
        </Box>
      {/* </Box> */}
    </>
  )
}
export default Pusd
