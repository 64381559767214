import { useContext } from 'react'
import {
  Button,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import { TipContext } from "../new-ui/hook/TipContext";

import { Decimal, LQTYStakeChange } from "@liquity/lib-base";

import { useLiquity } from "../../hooks/LiquityContext";
import { useTransactionFunction } from "../Transaction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subBtn: {
      marginTop: '16px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(21, 66, 205, 1)',
      '&:hover': {
        backgroundColor: 'rgba(21, 66, 205, 1)',
        color: '#fff',
      },
    },
  })
)

type StakingActionProps = {
  handleClose?:() => void;
  change: LQTYStakeChange<Decimal>;
};

export const StakingManagerAction: React.FC<StakingActionProps> = ({ change,handleClose, children }) => {
  const { liquity } = useLiquity();
  const classes = useStyles()

  const Tip = useContext(TipContext);

  const [sendTransaction] = useTransactionFunction(
    "stake",
    change.stakeLQTY
      ? liquity.send.stakeLQTY.bind(liquity.send, change.stakeLQTY)
      : liquity.send.unstakeLQTY.bind(liquity.send, change.unstakeLQTY)
  );

  const handleClick = () => {
    sendTransaction();
    if (handleClose) {
      handleClose();
    }

    Tip?.dispatchEvent(true)
  }

  return <Button onClick={handleClick} className={clsx(classes.subBtn,classes.btnColor)}>{children}</Button>;
};
