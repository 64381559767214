import React,{useEffect,useState} from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  Button,
  Tooltip
} from '@material-ui/core'
import { useTranslation, Trans, Translation } from 'react-i18next'
import clsx from 'clsx'
import { Flex, Spinner } from "theme-ui";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { fetchLqtyPrice } from "../../components/Stability/context/fetchLqtyPrice";
import { Decimal, Decimalish,Percent, StabilityDeposit, LiquityStoreState } from "@liquity/lib-base";
import { LiquityStoreUpdate, useLiquityReducer, useLiquitySelector } from "@liquity/lib-react";
import { Transaction, TransactionFunction, useMyTransactionState } from "../../components/Transaction";
import { useLiquity } from "../../hooks/LiquityContext";
import { COIN, GT } from "../../strings";

import { ClaimAndMove } from "../../components/Stability/actions/ClaimAndMoveNew";
import { ClaimRewards } from "../../components/Stability/actions/ClaimRewardsNew";
import DialogStablePool from "../../components/new-ui/DialogStablePool";

import OverShadomBox from '../../components/new-ui/OverShadomBox'
import icon_t from '../../assets/icon-lqty.svg'
import tip from '../../assets/tip.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      marginTop: '20px',
      [theme.breakpoints.down('xs')]: {
        marginTop: '0',
      },
    },
    back: {
      background: '#fff',
      overflow: 'hidden',
      padding:'20px 20px 30px'
    },
    subtitle1: { 
      fontSize: '14px',
      fontWeight: 400,
      color: '#745DDF',
      lineHeight: '20px'
    },
    button: {
      fontSize: '16px',
      fontWeight: 800,
      color: 'rgba(31, 37, 51, 1)',
      lineHeight: '20px'
    },
    body1: {
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(84, 90, 108, 1)',
      lineHeight: '20px'
    },
    button1: {
      fontSize: '14px',
      // fontWeight: 600,
      color: 'rgba(84, 90, 108, 1)',
      lineHeight: '20px'
    },
    containedBtn: {
      background: '#1542CD',
      borderRadius: '8px',
      padding:'10px',
      fontSize: '14px',
      // fontWeight: 600,
      color: '#fff',
      lineHeight: '22px',
      '&:hover': {
        background: '#1542CD',
        color: '#fff',
      }
    },
    containedBtn1: {
      background: 'rgba(21, 66, 205, 0.1)',
      borderRadius: '16px',
      padding: '2px',
      color: 'rgba(21, 66, 205, 1)',
      fontSize: '22px',
      minWidth:'48px',
      fontWeight: 600,
      '&:hover': {
        background: 'rgba(21, 66, 205, 0.1)',
        color: 'rgba(21, 66, 205, 1)',
      }
    },
    subBtn: {
      borderRadius: '8px',
      marginTop:'20px',
      padding: '12px',
      fontSize: '16px',
      width:'100%',
      // fontWeight: 600,
    },
    outlined: {
      border: '1px solid #1542CD',
      color: 'rgba(21, 66, 205, 1)',
    },
    grid: {
      [theme.breakpoints.up('sm')]: {
        maxWidth:'384px'
      },
    },
    tip: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '17px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '9px',
      border: '1px solid #E3E3F3'
    }
  })
)

type StabilityDepositActionProps = {
  originalDeposit: StabilityDeposit;
  editedLUSD: Decimal;
  changePending: boolean;
  dispatch: (action: { type: "startChange" | "finishChange" }) => void;
};

const selectBtn = ({
  trove,
  lusdBalance,
  frontend,
  ownFrontend,
  haveUndercollateralizedTroves
}: LiquityStoreState) => ({
  trove,
  lusdBalance,
  frontendRegistered: frontend.status === "registered",
  noOwnFrontend: ownFrontend.status === "unregistered",
  haveUndercollateralizedTroves
});

type Action = [name: string, send: TransactionFunction, requirements?: [boolean, string][]];

const StabilityDepositAction: React.FC<StabilityDepositActionProps> = ({
  originalDeposit,
  editedLUSD,
  changePending,
  dispatch
}) => {
  const {
    trove,
    lusdBalance,
    frontendRegistered,
    noOwnFrontend,
    haveUndercollateralizedTroves
  } = useLiquitySelector(selectBtn);

  const classes = useStyles()

  const {
    config,
    liquity: { send: liquity }
  } = useLiquity();

  const frontendTag = frontendRegistered ? config.frontendTag : undefined;

  const myTransactionId = "stability-deposit";
  const myTransactionState = useMyTransactionState(/^stability-deposit-/);

  const { depositLUSD, withdrawLUSD } = originalDeposit.whatChanged(editedLUSD) ?? {};

  const collateralGain = originalDeposit.collateralGain.nonZero;
  const lqtyReward = originalDeposit.lqtyReward.nonZero;
  const gains =
    (collateralGain ?? lqtyReward) &&
    [collateralGain && "ETH", lqtyReward && GT].filter(x => x).join(" & ");

  useEffect(() => {
    if (myTransactionState.type === "waitingForApproval") {
      dispatch({ type: "startChange" });
    } else if (myTransactionState.type === "failed" || myTransactionState.type === "cancelled") {
      dispatch({ type: "finishChange" });
    }
  }, [myTransactionState.type, dispatch]);

  if (!depositLUSD && !withdrawLUSD && !gains) {
    return null;
  }

  const actions: Action[] = gains
    ? [
        [`Claim ${gains}`, liquity.withdrawGainsFromStabilityPool.bind(liquity)],
        ...(collateralGain && !trove.isEmpty
          ? [
              [
                lqtyReward ? `Transfer ETH to Trove & claim ${GT}` : `Transfer ETH to Trove`,
                liquity.transferCollateralGainToTrove.bind(liquity)
              ] as Action
            ]
          : [])
      ]
    : [];

  return myTransactionState.type === "waitingForApproval" ? (
    <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
      {actions.map(([actionName], i) => (
        <Button key={i} variant="contained" disabled className={classes.subBtn}>
          {myTransactionState.id === `${myTransactionId}-${i}` ? (
            <>
              <Spinner sx={{ mr: 2, color: "white" }} size="20px" />
              Waiting for your approval
            </>
          ) : (
            actionName
          )}
        </Button>
      ))}
    </Flex>
  ) : changePending ? null : (
    <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
      {actions.map(([actionName, send, requires], i) => (
        <Transaction key={i} id={`${myTransactionId}-${i}`} {...{ send, requires }}>
          <Button variant="outlined" className={clsx(classes.subBtn,classes.outlined)}>{actionName}</Button>
        </Transaction>
      ))}
    </Flex>
  );
};

const init = ({ stabilityDeposit }: LiquityStoreState) => ({
  originalDeposit: stabilityDeposit,
  editedLUSD: stabilityDeposit.currentLUSD,
  changePending: false
});

type StabilityDepositManagerState = ReturnType<typeof init>;
type StabilityDepositManagerAction =
  | LiquityStoreUpdate
  | { type: "startChange" | "finishChange" | "revert" }
  | { type: "setDeposit"; newValue: Decimalish };

const reduceWith = (action: StabilityDepositManagerAction) => (
  state: StabilityDepositManagerState
): StabilityDepositManagerState => reduce(state, action);

const finishChange = reduceWith({ type: "finishChange" });
const revert = reduceWith({ type: "revert" });

const reduce = (
  state: StabilityDepositManagerState,
  action: StabilityDepositManagerAction
): StabilityDepositManagerState => {
  // console.log(state);
  // console.log(action);

  const { originalDeposit, editedLUSD, changePending } = state;

  switch (action.type) {
    case "startChange":
      return { ...state, changePending: true };

    case "finishChange":
      return { ...state, changePending: false };

    case "setDeposit":
      return { ...state, editedLUSD: Decimal.from(action.newValue) };

    case "revert":
      return { ...state, editedLUSD: originalDeposit.currentLUSD };

    case "updateStore": {
      const {
        stateChange: { stabilityDeposit: updatedDeposit }
      } = action;

      if (!updatedDeposit) {
        return state;
      }

      const newState = { ...state, originalDeposit: updatedDeposit };

      const changeCommitted =
        !updatedDeposit.initialLUSD.eq(originalDeposit.initialLUSD) ||
        updatedDeposit.currentLUSD.gt(originalDeposit.currentLUSD) ||
        updatedDeposit.collateralGain.lt(originalDeposit.collateralGain) ||
        updatedDeposit.lqtyReward.lt(originalDeposit.lqtyReward);

      if (changePending && changeCommitted) {
        return finishChange(revert(newState));
      }

      return {
        ...newState,
        editedLUSD: updatedDeposit.apply(originalDeposit.whatChanged(editedLUSD))
      };
    }
  }
};

const select = ({
  numberOfTroves,
  price,
  total,
  lusdInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLQTY,
  remainingStabilityPoolLQTYReward,
  stabilityDeposit,
  trove,
}: LiquityStoreState) => ({
  numberOfTroves,
  price,
  total,
  lusdInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLQTY,
  remainingStabilityPoolLQTYReward,
  stabilityDeposit,
  trove,
});

const Liquidation: React.FC = () => {
  const classes = useStyles()
  let { t, i18n } = useTranslation();
  const [openTip, setOpenTip] = React.useState(false);
  const [{ originalDeposit, editedLUSD, changePending }, dispatch] = useLiquityReducer(reduce, init);
  
  const {
    numberOfTroves,
    price,
    lusdInStabilityPool,
    total,
    stabilityDeposit,
    trove,
    borrowingRate,
    redemptionRate,
    totalStakedLQTY,
    remainingStabilityPoolLQTYReward
  } = useLiquitySelector(select);
  const lusdInStabilityPoolPct = total.debt.nonZero && new Percent(lusdInStabilityPool.div(total.debt));
  const totalCollateralRatioPct = new Percent(total.collateralRatio(price));
  const [open, setOpen] = React.useState(false);
  const [text, setText] = useState<string>(t('text_47'));
  useEffect(() => {
    setText(t('text_47'))
  }, [i18n.language])

  const [index, setIndex] = React.useState(0);  // 0加，1减

  const handleClickIndex = (num: number) => {
    setOpen(true);
    setIndex(num);
  };

  // 年化计算

  const {
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();
  
  const { chainId } = useWeb3React<Web3Provider>();
  const isMainnet = chainId === 1;

  const [lqtyPrice, setLqtyPrice] = useState<Decimal | undefined>(undefined);
  const hasZeroValue = remainingStabilityPoolLQTYReward.isZero || lusdInStabilityPool.isZero;
  let lqtyTokenAddress = addresses["lqtyToken"];

  // TODO: remove after Team has reviewed on /next
  if (!isMainnet) {
    lqtyTokenAddress = "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2";
  }

  useEffect(() => {
    (async () => {
      try {
        const { lqtyPriceUSD } = await fetchLqtyPrice(lqtyTokenAddress);
        setLqtyPrice(lqtyPriceUSD);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [lqtyTokenAddress]);
  // TODO: switch to this condition after team has reviewed on /next
  // if (!isMainnet || hasZeroValue || lqtyPrice === undefined) return null;
  // if (hasZeroValue || lqtyPrice === undefined) return null;
  const yearlyHalvingSchedule = 0.5; // 50% see LQTY distribution schedule for more info
  const remainingLqtyOneYear = remainingStabilityPoolLQTYReward.mul(yearlyHalvingSchedule);
  const remainingLqtyInUSD = remainingLqtyOneYear.mul(lqtyPrice||0);
  const apyPercentage = remainingLqtyInUSD.div(lusdInStabilityPool).mul(100);
  // const text_47=t('text_47')

  const hasReward = !stabilityDeposit.lqtyReward.isZero;
  const hasGain = !stabilityDeposit.collateralGain.isZero;
  const hasTrove = !trove.isEmpty;

  const poolShare = stabilityDeposit.currentLUSD.mulDiv(100, lusdInStabilityPool);

  return (
    <>
      <Grid
        container
        spacing={3}
        className={classes.box}
        justify="center"
      >
        <Grid item xs={12} sm={10} className={classes.grid}>
          <OverShadomBox className={classes.back}>
          <Box display="flex" justifyContent="space-between" alignItems="center" style={{padding:"20px",margin: '-20px -20px 0',backgroundColor:'rgba(210, 214, 220, .08)'}}>
              <img src={icon_t} alt="" style={{width:'48px'}} />
              <Box style={{textAlign:'right'}}>
                <Typography variant="subtitle1" className={classes.subtitle1} gutterBottom>
                  {t('text_04')}
                </Typography>
                <Typography variant="button" className={classes.button} display="block">
                {lusdInStabilityPool.shorten()} 
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between"  alignItems="center" style={{marginTop:"20px"}}>
                <Typography variant="body1" className={classes.body1}>
                Pool share
                </Typography>
                <Typography variant="button" className={classes.button1} display="block">
                  {poolShare.prettify(4)}%
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between"  alignItems="center" style={{marginTop:"20px"}}>
                <Typography variant="body1" className={classes.body1}>
                  LQTY {t('text_40')}
                  <Tooltip classes={{
                    tooltip:classes.tip
                   }}
                    onClose={()=>setOpenTip(false)}
                    open={openTip}
                    title={text}
                    interactive placement="top">
                      <img src={tip} onClick={()=>setOpenTip(true)} style={{width:'14px', marginLeft: '2px',marginBottom:'-2px'}} alt=""/>
                  </Tooltip>
                </Typography>
                <Typography variant="button" className={classes.button1} display="block">
                  {apyPercentage.toString(2)}%
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: "20px" }}>
                <Typography variant="body1" className={classes.body1}>
                ETH{t('text_05')}
                </Typography>
                <Typography variant="button" className={classes.button1} display="block">
                  {originalDeposit.collateralGain.prettify(4)}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between"  alignItems="center" style={{marginTop:"20px"}}>
                <Typography variant="body1" className={classes.body1}>
                  LQTY{t('text_06')}
                </Typography>
                <Typography variant="button" className={classes.button1} display="block">
                  {originalDeposit.lqtyReward.prettify()}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between"  alignItems="center" style={{marginTop:"20px"}}>
              <Box>
                <Typography variant="subtitle1"  className={classes.subtitle1} gutterBottom>
                <strong>LUSD</strong> {t('text_07')} 
                </Typography>
                <Typography variant="button" className={classes.button} display="block">
                  {editedLUSD.toString(2)}
                </Typography>
              </Box>
              <Box>
                {!originalDeposit.isEmpty ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={()=>handleClickIndex(1)}
                      className={classes.containedBtn1}
                      disableElevation>
                    -
                  </Button>
                  <Button variant="contained" onClick={()=>handleClickIndex(0)} style={{marginLeft:'10px'}} className={classes.containedBtn1} disableElevation>
                      +
                  </Button>
                  </>
                ) : (
                  <Button variant="contained" onClick={()=>handleClickIndex(0)} className={classes.containedBtn} disableElevation>
                    {t('text_08')} LUSD
                  </Button>
                )}
                
              </Box>
            </Box>

            {!originalDeposit.isEmpty && (
              <ClaimRewards disabled={!hasGain && !hasReward}>Claim ETH and LQTY</ClaimRewards>
            )}
            
            {hasTrove && (
              <ClaimAndMove disabled={!hasGain}>
                Claim LQTY and move ETH to Trove
              </ClaimAndMove>
            )}
          </OverShadomBox>

          {/* <Button variant="contained" onClick={()=>handleClickIndex(0)} className={classes.containedBtn} disableElevation>
            {t('text_08')} LUSD
          </Button> */}
        </Grid>
      </Grid>
      {/* {
        open && ( */}
          <DialogStablePool open={open} handleClose={() => setOpen(false)} value_index={index} editedL={editedLUSD.toString(2)}></DialogStablePool>
        {/* )
      } */}
      
    </>
  )
};
export default Liquidation;
