import { useContext } from 'react'
import {
  Button,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core'
import clsx from 'clsx'
import { TipContext } from "../new-ui/hook/TipContext";

import { Decimal, LiquityStoreState, StabilityDepositChange } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { useLiquity } from "../../hooks/LiquityContext";
import { useTransactionFunction } from "../Transaction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subBtn: {
      marginTop: '16px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(21, 66, 205, 1)',
      '&:hover': {
        backgroundColor: 'rgba(21, 66, 205, 1)',
        color: '#fff',
      },
    },
  })
)

type StabilityDepositActionProps = {
  handleClose?:() => void;
  transactionId: string;
  change: StabilityDepositChange<Decimal>;
};

const selectFrontendRegistered = ({ frontend }: LiquityStoreState) =>
  frontend.status === "registered";

export const StabilityDepositAction: React.FC<StabilityDepositActionProps> = ({
  children,
  handleClose,
  transactionId,
  change
}) => {
  const { config, liquity } = useLiquity();
  const classes = useStyles()
  const frontendRegistered = useLiquitySelector(selectFrontendRegistered);
  const Tip = useContext(TipContext);

  const frontendTag = frontendRegistered ? config.frontendTag : undefined;

  const [sendTransaction] = useTransactionFunction(
    transactionId,
    change.depositLUSD
      ? liquity.send.depositLUSDInStabilityPool.bind(liquity.send, change.depositLUSD, frontendTag)
      : liquity.send.withdrawLUSDFromStabilityPool.bind(liquity.send, change.withdrawLUSD)
  );

  const handleClick = () => {
    sendTransaction();
    if (handleClose) {
      handleClose();
    }

    Tip?.dispatchEvent(true)
  }

  return <Button onClick={handleClick} className={clsx(classes.subBtn,classes.btnColor)}>{children}</Button>;
};
