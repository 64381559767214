import { useContext } from 'react'
import {
  Button,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import clsx from 'clsx'
import { TipContext } from "../new-ui/hook/TipContext";

import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { useLiquity } from "../../hooks/LiquityContext";
import { useTransactionFunction } from "../Transaction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subBtn: {
      borderRadius: '8px',
      marginTop:'20px',
      padding: '12px',
      fontSize: '16px',
      width:'100%',
      // fontWeight: 600,
    },
    outlined: {
      border: '1px solid #1542CD',
      color: 'rgba(21, 66, 205, 1)',
    },
  })
)

const selectLQTYStake = ({ lqtyStake }: LiquityStoreState) => lqtyStake;

export const StakingGainsAction: React.FC = () => {
  const { liquity } = useLiquity();
  const classes = useStyles()
  const Tip = useContext(TipContext);
  const { collateralGain, lusdGain } = useLiquitySelector(selectLQTYStake);

  const [sendTransaction] = useTransactionFunction(
    "stake",
    liquity.send.withdrawGainsFromStaking.bind(liquity.send)
  );

  const handleClick = () => {
    sendTransaction();
    
    Tip?.dispatchEvent(true)
  }

  return (
    <Button
      variant="outlined"
      className={clsx(classes.subBtn, classes.outlined)}
      onClick={handleClick}
      disabled={collateralGain.isZero && lusdGain.isZero}>
      Claim
    </Button>
  );
};
