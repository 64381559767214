import React, { useEffect, useState,useCallback } from 'react'
import {
  Link as RouterLink,
  MemoryRouter as Router,
  LinkProps as RouterLinkProps,
  useHistory,
} from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  FormHelperText,
  Tooltip
} from '@material-ui/core'
import {
  CRITICAL_COLLATERAL_RATIO,
  MINIMUM_COLLATERAL_RATIO,
  Difference,
  LiquityStoreState,
  Percent,
  Trove,
  TroveChange,
  Decimal,
  Decimalish,
  LUSD_MINIMUM_NET_DEBT,
  LUSD_LIQUIDATION_RESERVE
} from '@liquity/lib-base'

import {
  selectForTroveChangeValidation,
  validateTroveChange
} from "../Trove/validation/validateTroveChangeNew";
import { useTroveView } from "../Trove/context/TroveViewContext";
import { TroveAction } from "../Trove/TroveActionNew";

import { useTranslation, Trans, Translation } from 'react-i18next'
import { useLiquitySelector } from '@liquity/lib-react'
import { Transaction, useMyTransactionState } from "../../components/Transaction";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import common from '../../utils/common';
import RedditTextField from '../../components/new-ui/RedditTextField'
import DialogBorrow from '../../components/new-ui/DialogBorrow'
import { useLiquity } from "../../hooks/LiquityContext";
import { LiquidatRedeemed } from './LiquidatRedeemed';

import icon_T from '../../assets/icon-lqty.svg'
import eth from '../../assets/eth.svg'
import tip from '../../assets/tip.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      width: '100%',
      marginBttom: '24px',
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '100%',
      margin: '0',
    },
    helperText: {
      // marginTop: '8px',
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    subBtn: {
      marginTop: '16px',
      width: '100%',
      padding: '13px 0',
    },
    success: {
      color:'#28c081'
    },
    warning: {
      color:'#fd9d28'
    },
    danger: {
      color:'#dc2c10'
    },
    muted: {
      // color:'#28c081'
    },
    card: {
      padding:'60px 40px',
      marginTop:'20px',
      background: '#FFFFFF',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.14)',
      borderRadius: '12px'
    },
    captionCard: {
      fontSize: '18px',
      fontWeight: 500,
      color: '#293147',
      lineHeight: '21px'
    },
    overline: {
      marginTop:'12px',
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '16px'
    },
    tip: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '17px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '9px',
      border: '1px solid #E3E3F3'
    },
    body2: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    body2Text: {
      paddingRight: '10px',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(157, 160, 170, 1)',
      lineHeight: '17px',
    },
    bodyText: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(rgba(31, 37, 51, 1))',
      lineHeight: '17px',
    },
    bodyBtn: {
      whiteSpace: 'nowrap',
      padding:'0',
      fontSize: '12px',
      fontWeight: 500,
      color: 'rgba(21, 66, 205, 1)',
      lineHeight: '17px',
    },
    subBtn1: {
      marginTop: '16px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor1: {
      color: '#fff',
      backgroundColor: 'rgba(21, 66, 205, 1)',
      '&:hover': {
        backgroundColor: 'rgba(21, 66, 205, 1)',
        color: '#fff',
      },
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: '#fff',
      },
    },
  })
)

const select = ({ accountBalance, lusdBalance,collateralSurplusBalance }: LiquityStoreState) => ({
  accountBalance,
  lusdBalance,
  redeemed: !collateralSurplusBalance.isZero,
})

const selector = (state: LiquityStoreState) => {
  const { trove,fees, price, accountBalance ,collateralSurplusBalance} = state;
  return {
    trove,
    fees,
    price,
    accountBalance,
    collateralSurplusBalance,
    validationContext: selectForTroveChangeValidation(state)
  };
};

type TroveEditorProps = {
  original: Trove
  edited: Trove
  afterFee: Trove
  borrowingRate: Decimal
  change?: TroveChange<Decimal>
  changePending: boolean
  dispatch: (
    action:
      | { type: 'setCollateral' | 'setDebt'; newValue: Decimalish }
      | { type: 'revert' }
  ) => void
  value: number,
  validChange:any
}

const feeFrom = (original: Trove, edited: Trove, borrowingRate: Decimal): Decimal => {
  const change = original.whatChanged(edited, borrowingRate);

  if (change && change.type !== "invalidCreation" && change.params.borrowLUSD) {
    return change.params.borrowLUSD.mul(borrowingRate);
  } else {
    return Decimal.ZERO;
  }
};

  const TRANSACTION_ID = "trove-adjustment";
  const GAS_ROOM_ETH = Decimal.from(0.1);

const TroveAdjusting: React.FC<TroveEditorProps> = ({
  children,
  original,
  edited,
  afterFee,
  change,
  changePending,
  dispatch,
  value,
  validChange
}) => {
  const classes = useStyles()
  const {
    liquity: { send: liquity }
  } = useLiquity();
  const { accountBalance, lusdBalance, redeemed } = useLiquitySelector(select)
  const { trove,fees, validationContext,price,collateralSurplusBalance } = useLiquitySelector(selector);
  const [open, setOpen] = React.useState(false);
  let { t, i18n } = useTranslation();

  const { view } = useTroveView();

  const [text_72, setText_72] = useState<any>({
    text01: t('text_720'),
    text02: t('text_721'),
    text_95: t('text_95')
  });
  useEffect(() => {
    setText_72({
      text01: t('text_720'),
      text02: t('text_721'),
      text_95: t('text_95')
    })
  }, [i18n.language])

  const borrowingRate = fees.borrowingRate();
  const myTransactionId = "claim-coll-surplus";
  const myTransactionState = useMyTransactionState(myTransactionId);

  const borrowingFeePct = new Percent(borrowingRate)

  const closeCollateralDebt = () => {
    setOpen(true)
    dispatch({
      type: 'setCollateral',
      newValue: 0,
    })
    dispatch({
      type: 'setDebt',
      newValue: 0,
    })
  }

  const closeHandle = () => {
    setOpen(false)
  }

  const [collateralV, setCollateralV] = useState<string>('');
  const [netDebtV, setNetDebtV] = useState<string>('');
  const [collateral, setCollateral] = useState<Decimal>(trove.collateral);
  const [netDebt, setNetDebt] = useState<Decimal>(Number(trove.debt)>0 ? trove.netDebt:Decimal.from(0));

  const [netDebtIn, setNetDebtIn] = useState<Decimal>(Number(trove.debt)>0 ? trove.netDebt:Decimal.from(0));

  const max_borrow = common.numberDecimal(Math.max((Number(trove.collateral.add(Decimal.from(Number(collateralV)>0&&collateralV ? collateralV : 0))) * Number(price)) / 1.15 - Number(trove.debt), 0), 2);

  // (Math.floor(Math.max(((Number(values.eth_amount) + Number(val.collateral)) * Number(price)) / 1.20 - Number(val.debt), 0) * 100) / 100).toString()
  const max_desirable = common.numberDecimal(Number(trove.collateral) - (Number(netDebtIn) * 1.10 / Number(price)), 4)
    // (Math.floor((Number(trove.collateral) - (Number(trove.netDebt) * 1.10 / Number(price))) * 100) / 100).toString()

  const maxBorrowingRate = borrowingRate.add(0.005);
  // const maxEth = accountBalance.gt(GAS_ROOM_ETH) ? accountBalance.sub(GAS_ROOM_ETH) : Decimal.ZERO;
  // const maxCollateral = collateral.add(maxEth);
  const isDebtIncrease = netDebt.gt(netDebtIn);
  const debtIncreaseAmount = isDebtIncrease ? netDebt.sub(netDebtIn) : Decimal.ZERO;

  const fee = isDebtIncrease
    ? feeFrom(trove, new Trove(trove.collateral, trove.debt.add(debtIncreaseAmount)), borrowingRate)
    : Decimal.ZERO;
  const feePct = new Percent(borrowingRate);
  const totalDebt = netDebt.add(LUSD_LIQUIDATION_RESERVE).add(fee);
  const isDirty = !collateral.eq(trove.collateral) || !netDebt.eq(netDebtIn);
  const updatedTrove = isDirty ? new Trove(collateral, totalDebt) : trove;
  const collateralRatio =
    !collateral.isZero && !netDebt.isZero ? updatedTrove.collateralRatio(price) : undefined;
  const collateralRatioPct = new Percent(collateralRatio ?? { toString: () => "N/A" });

  const collateralRatioChange = Difference.between(collateralRatio, trove.collateralRatio(price));
  const changePct = collateralRatioChange && new Percent(collateralRatioChange);

  const [collateralRatioPctIn, setCollateralRatioPctIn] = useState<string>(collateralRatioPct.prettify());

  const [ethPrices, setEthPrices] = useState<Decimal>(netDebt.mul(Decimal.from(1.1)).div(collateral));
  const ethPrice = netDebt.mul(Decimal.from(1.1)).div(collateral);

  const maxEth = accountBalance.gt(GAS_ROOM_ETH) ? accountBalance.sub(GAS_ROOM_ETH) : Decimal.ZERO;
  // const maxCollateral = collateral.add(maxEth);
  // const max_borrows = common.numberDecimal(Math.max(((Number(values.eth_amount) + Number(val.collateral)) * Number(price)) / 1.20 - Number(val.debt), 0), 2)
  
  useEffect(() => {
    setCollateralV('');
    setNetDebtV('');
    setNetDebt(Number(trove.debt)>0 ? trove.netDebt:Decimal.from(0));
    setNetDebtIn(Number(trove.debt) > 0 ? trove.netDebt : Decimal.from(0));
    setCollateral(trove.collateral);
    setCollateralRatioPctIn(collateralRatioPct.prettify())
  }, [accountBalance, lusdBalance])

  const [troveChange, description] = validateTroveChange(
    trove,
    updatedTrove,
    borrowingRate,
    validationContext
  );

  const handleChangeLusd = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    // if (Number(value) > 0) {
      setNetDebtV(value)
    // } else {
    //   setNetDebtV('')
    // }
    // setValues({ ...values, [prop]: event.target.value })
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    // if (Number(value)>0) {
      setCollateralV(value)
    // }else {
    //   setCollateralV('')
    // }
  }

  const handleMaxClick = (str:string) => {
    if (str === 'ETH') {
      setCollateralV(value ? max_desirable : common.numberDecimal(maxEth.toString(), 4))
    } else {
      const balance = Number(netDebtIn) > Number(lusdBalance) ?
        common.numberDecimal(Number(lusdBalance), 2) :
        common.numberDecimal(netDebtIn.toString(),2);
      setNetDebtV(value ? balance : max_borrow);
    }
  }

  useEffect(() => {
    setCollateralV('');
    setNetDebtV('');
    setNetDebt(netDebtIn);
    setCollateral(trove.collateral);
  }, [value])

  useEffect(() => {
    const vals = value ? max_desirable : common.numberDecimal(Number(accountBalance),4)
    if (Number(collateralV) > 0 && Number(collateralV) <= Number(vals)) {
      if (value) {
        setCollateral(trove.collateral.sub(Decimal.from(collateralV)))
      } else {
        setCollateral(trove.collateral.add(Decimal.from(collateralV)))
      }
    } else if (Number(collateralV)>Number(vals)) {
      if (value) {
        setCollateral(trove.collateral.sub(Decimal.from(vals)))
      } else {
        setCollateral(trove.collateral.add(Decimal.from(collateralV)))
      }
    } else if (!collateralV) {
      setCollateral(trove.collateral)
    }
  }, [collateralV])

  useEffect(() => {
    const balance = Number(netDebtIn) > Number(lusdBalance) ?
        common.numberDecimal(Number(lusdBalance), 2) :
        common.numberDecimal(netDebtIn.toString(),2);
    const vals = value ? balance : max_borrow;
    if (Number(netDebtV) > 0 && Number(netDebtV) <= Number(vals)) {
      if (value) {
        setNetDebt(netDebtIn.sub(Decimal.from(netDebtV)))
      } else {
        setNetDebt(netDebtIn.add(Decimal.from(netDebtV)))
      }
    } else if (Number(netDebtV)>Number(vals)) {
      if (value) {
        setNetDebt(netDebtIn.sub(vals))
      } else {
        setNetDebt(netDebtIn.add(Decimal.from(netDebtV)))
      }
    }  else if (!netDebtV) {
      setNetDebt(netDebtIn)
    }
  }, [netDebtV])

  return (
    <Box>
      {
      // myTransactionState.type !== "waitingForConfirmation" &&
      //   myTransactionState.type !== "confirmed"&&redeemed ? (
      //     <>
      //       <Box className={classes.card} display="flex" alignItems="center" justifyContent="center">
      //         <Box style={{textAlign:'center'}}>
      //           <Typography variant="h3" className={classes.captionCard} display="block" gutterBottom>
      //           {t('text_67')}
      //           </Typography>
      //           <Typography variant="caption" className={classes.overline} display="block" gutterBottom>
      //           {t('text_68')}
      //           </Typography>
      //           {/* {myTransactionState.type} */}
      //         </Box>
      //       </Box>

      //       <Transaction
      //         id={myTransactionId}
      //         send={liquity.claimCollateralSurplus.bind(liquity, undefined)}
      //       >
      //         <Button className={clsx(classes.subBtn1,classes.btnColor1)}>Claim {collateralSurplusBalance.prettify()} ETH</Button>
      //       </Transaction>
            // {/* {children} */}
            // </>
            view === "REDEEMED" || view === "LIQUIDATED"? (
            <>
              <LiquidatRedeemed view={view} />
            </>
        ): (
          <>
            <RedditTextField
              labeltext={value ? t('text_15') : t('text_16')}
              coin_in="ETH"
              type='number'
              coin={(<><img src={eth} style={{width:'18px',marginRight:'4px'}} alt=""/><Box>ETH</Box></>)}
              endadornmenttext={value ? t('text_17') : t('text_18')}
              endadornmentvalue={value ? max_desirable : common.numberDecimal(Number(accountBalance),4)}
              isbtn="1"
              btntext="Max"
              btncallback={handleMaxClick}
              onChange={handleChange}
              value={collateralV}
              className={clsx(classes.margin, classes.textField)}
              // defaultValue="react-reddit"
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ backgroundColor: '#fff', marginTop: '20px' }}
              helperText=""
            />
                <RedditTextField
                    labeltext={value ? t('text_19') : t('text_09')}
                    placeholder={!value && !Number(netDebtV) ? t('text_20'):''}
                    coin_in="LUSD"
                    type='number'
                    coin={(<><img src={icon_T} style={{width:'18px',marginRight:'4px'}} alt=""/><Box>LUSD</Box></>)}
                    tiptext={t('text_21')}
                    endadornmenttext={value ? t('text_18') : t('text_22')}
                    endadornmentvalue={value ? common.numberDecimal(Number(lusdBalance),2) : max_borrow}
                    isbtn="1"
                    btntext="Max"
                    btncallback={handleMaxClick}
                    onChange={handleChangeLusd}
                    value={netDebtV}
                    // defaultValue={edited.debt.toString(2)}
                    className={clsx(classes.margin, classes.textField)}
                    // defaultValue="react-reddit"
                    variant="filled"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ backgroundColor: '#fff', marginTop: '20px' }}
            />

            {value ? (
                <>
                <FormHelperText component="div"
                  style={{ marginTop: '10px' }}
                  className={classes.helperText}>
                  <Typography variant="body2" className={classes.body2Text} gutterBottom>
                  {t('text_23')}
                  </Typography>
                  <Button className={classes.bodyBtn} onClick={closeCollateralDebt}>{t('text_24')}</Button>
                </FormHelperText>
                </>
            ) : null
            //   (
            //       <>
            //       <FormHelperText component="div" className={classes.helperText}>
            //         <Typography variant="body2" gutterBottom>
            //           Borrowing fee 
            //         </Typography>
            //         <Typography variant="body2" gutterBottom>
            //           {borrowingFeePct.toString(2)}
            //         </Typography>
            //       </FormHelperText>
            //       </>
            // )
            }

            {/* {children} */}
            
              {description}

              {troveChange ? (
                <TroveAction
                  transactionId={TRANSACTION_ID}
                  change={troveChange}
                  maxBorrowingRate={maxBorrowingRate}
                >
                  {t('text_48')}
                </TroveAction>
              ) : (
                <Button disabled className={clsx(classes.subBtn,classes.btnColor)}>{t('text_48')}</Button>
              )}

              <FormHelperText
                  component="div"
                  style={{ marginTop: '20px' }}
                  className={classes.helperText}
                >
                <Typography className={clsx(classes.body2,classes.body2Text)} variant="body2" gutterBottom>
                {t('text_95')}
                  <Tooltip
                    classes={{
                      tooltip: classes.tip
                    }}
                    title={text_72.text_95}
                    interactive
                    placement="bottom">
                    <img src={tip} style={{ width: '14px', marginRight: '2px', marginLeft: '4px' }} alt="" />
                  </Tooltip>
                </Typography>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                {Number(ethPrices)>0?'$' + ethPrices.prettify():'--'}
                  <ArrowRightAltIcon style={{margin:'0 10px',color:'rgba(31, 37, 51, 1)',fontSize:'12px'}}></ArrowRightAltIcon>
                  <Typography className={
                    ethPrices.prettify()<ethPrice.prettify()
                    ? classes.success
                    : ethPrices.prettify()<ethPrice.prettify()
                    ? classes.warning
                    : classes.muted
                  } style={{ fontSize: '12px' }}>
                          {
                            ethPrices.prettify()===ethPrice.prettify()?
                              '--' : Number(ethPrice) > 0 ? '$' + ethPrice.prettify() : '--'
                  }
                  
                          
                  {/* {collateralRatio === (collateralRatio?.gt(10)
                    ? '× ' + collateralRatio.shorten()
                      : collateralRatioPct.prettify())?'--':(collateralRatio?.gt(10)
                      ? '× ' + collateralRatio.shorten()
                        : collateralRatioPct.prettify())} */}
                  </Typography>
                
                </Box>
              </FormHelperText>
            
            {
              (Number(collateralV)>0 || Number(netDebtV)>0) ? 
                (
                  <>
                    <FormHelperText
                        component="div"
                        style={{ marginTop: '20px' }}
                        className={classes.helperText}
                      >
                        <Typography className={classes.body2Text} variant="body2">
                        {value ? t('text_15') : t('text_16')}
                        </Typography>
                        <Typography className={classes.body2Text} variant="body2">
                          {collateralV||'--'} ETH
                        </Typography>
                    </FormHelperText>

                    
                    {!value && (
                      <>
                      <FormHelperText
                        component="div"
                        // style={{ marginTop: !value && !Number(val.debt) ? '10px' : '20px' }}
                        style={{ marginTop: '10px' }}
                        className={classes.helperText}
                      >
                        <Typography className={classes.body2Text} variant="body2">
                          {t('text_60')}
                        </Typography>
                        <Typography className={classes.body2Text} variant="body2">
                          {fee ? fee.toString(2) : '0.00'} LUSD ({borrowingFeePct.toString(2)})
                        </Typography>
                        </FormHelperText>
                        </>
                    )}
                      <FormHelperText
                      component="div"
                      style={{ marginTop: '10px' }}
                      className={classes.helperText}
                      >
                      <Typography variant="body2" className={clsx(classes.body2,classes.body2Text)}>
                      Total debt
                            {/* {t('text_58')} */}
                        <Tooltip
                          classes={{
                            tooltip: classes.tip
                          }}
                          title={text_72.text01+(Number(totalDebt)>0?totalDebt.sub(Decimal.from(200)).prettify(2):'0')+text_72.text02}
                          interactive
                          placement="bottom">
                          <img src={tip} style={{ width: '14px', marginRight: '2px', marginLeft: '4px' }} alt="" />
                          {/* <WarningIcon style={{ color: 'rgba(210, 214, 220, 1)', fontSize: '14px', marginRight: '2px' }}></WarningIcon> */}
                        </Tooltip>
                      </Typography>
                      <Typography className={classes.body2Text} variant="body2">
                        {totalDebt.prettify(2)} LUSD
                      </Typography>
                        </FormHelperText>
                       
                  
                    
                    <FormHelperText
                      component="div"
                        style={{ marginTop: '10px'}}
                        className={classes.helperText}
                      >
                        <Typography className={classes.body2Text} variant="body2">
                        {value ? t('text_19') : t('text_092')}
                        </Typography>
                        <Typography className={classes.body2Text} variant="body2">
                        {netDebtV||'--'} LUSD
                        </Typography>
                      </FormHelperText>

                <FormHelperText
                  component="div"
                  style={{ marginTop: '10px' }}
                  className={classes.helperText}
                >
                <Typography className={classes.body2Text} variant="body2" gutterBottom>
                {t('text_26')}
                </Typography>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                        {/* {collateralRatio} */}
                  {collateralRatioPctIn}
                  <ArrowRightAltIcon style={{margin:'0 10px',color:'rgba(31, 37, 51, 1)',fontSize:'12px'}}></ArrowRightAltIcon>
                  <Typography className={
                    collateralRatio?.gt(CRITICAL_COLLATERAL_RATIO)
                    ? classes.success
                    : collateralRatio?.gt(MINIMUM_COLLATERAL_RATIO)
                    ? classes.warning
                    : collateralRatio?.lte(MINIMUM_COLLATERAL_RATIO)
                    ? classes.danger
                    : classes.muted
                  } style={{ fontSize: '12px' }}>
                          {
                            collateralRatioPct.prettify() === collateralRatioPctIn ?
                              '--' :
                              collateralRatioPct.prettify()
                          }
                          
                  {/* {collateralRatio === (collateralRatio?.gt(10)
                    ? '× ' + collateralRatio.shorten()
                      : collateralRatioPct.prettify())?'--':(collateralRatio?.gt(10)
                      ? '× ' + collateralRatio.shorten()
                        : collateralRatioPct.prettify())} */}
                  </Typography>
                </Box>
              </FormHelperText>
                </>
              ) : null
            }
            
            </>
        )}

      <DialogBorrow {...{
                          original,
                          edited,
                          afterFee,
                          borrowingRate,
                          change,
                          changePending,
                          dispatch,
                          value,
                        }} open={open} handleClose={closeHandle}></DialogBorrow>
      
    
     
    </Box>
  )
}

export default TroveAdjusting
