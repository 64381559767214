
const zh_CN = {
  text_01:"抵押&借款",
  text_02: "赎回&还款",
  text_03: "兑换 ",
  text_04: "全网总质押数",
  text_05: " 收益",
  text_06: " 奖励",
  text_07: "已质押",
  text_08: "质押 ",
  text_09: "借贷",
  text_091: "借贷",
  text_092: "收到",
  text_10: "稳定池",
  text_11: "质押",
  text_12: "清算",
  text_13: "剩余奖励LQTY",
  text_14: "收益",
  text_15: "赎回",
  text_16: "抵押",
  text_17: "可取：",
  text_18: "钱包：",
  text_19: "还款",
  text_20: "至少借贷2000 LUSD ",
  text_21: "在正常操作中最低抵押物比率为110％，但建议将抵押物比率始终保持在150％以上，以避免在恢复模式下清算。参考安全的值为200%-250%。",
  text_22: "可借：",
  text_23: "赎回所有抵押需要确保足够的LUSD",
  text_24: "一键赎回抵押",
  text_25: "当开启抵押订单时，合约会暂扣200 LUSD作为清算GAS费用。当你赎回所有抵押物时，该费用会自动抵扣还款金额。",
  text_26: "抵押率",
  text_261: "抵押率",
  text_27: "兑换",
  text_28: "借贷",
  text_29: "归还",
  text_30: "取出",
  text_31: "立即赎回",
  text_32: "提取质押",
  text_33: "添加质押",
  text_34: "提取",
  text_35: "添加",
  text_36: "可用：",
  text_37: "LUSD 总供应量",
  text_38: "全网抵押率",
  text_39: "价格",
  text_40: "年化",
  text_41: "拥有者",
  text_42: "质押 (ETH)",
  text_43: "借贷 (LUSD)",
  text_44: "抵押率",
  text_45: "操作",
  text_46: "清算",
  text_lp: "该APY为UNI-LP挖取LQTY奖励的预估统计,LP挖矿奖励时间为6周。",
  text_47: "该APY仅为稳定池质押时获得LQTY预估统计,该池持续35个月。",
  text_48: "确认",
  text_49: "确认交易",
  text_50: "待处理交易",
  text_51: "成功",
  text_52: "交易确认",
  text_53: "失败",
  text_54: "请再次尝试",
  text_55: "错误",
  text_56: "交易被拒绝",
  text_57: "交易广播中",
  text_58: "清算押金",
  text_59: "最低借款",
  text_591: ".",
  text_60: "手续费",
  text_61: "此APR为质押LQTY的估算年华数据，计算方式以LUSD为单位。",
  text_62: "Liquity 分析",
  text_63: "切换 DevUI",
  text_64: "切换 FiUI",
  text_65: "兑换",
  text_67: "你的抵押物已被系统兑换！",
  text_68: "在开启新的抵押前，请取出系统中保留的抵押物。",
  text_69: "账户",
  text_70: "链接",
  text_71: "更换",
  text_720: "这是您当前持有的总债务金额。如需赎回所有抵押物，只需要偿还",
  text_721: "LUSD。（其中200LUSD的清算押金将被直接抵用）",
  text_730: "注意：",
  text_731: "该功能不是偿还债务或撤出抵押物，需要偿还或撤出请切换至",
  text_732: "赎回&还款",
  text_91: "正常模式：",
  text_92: "恢复模式：",
  text_93: "恢复模式触发价：",
  text_94: "当抵押物价格为此时，您的借贷抵押率将跌至110%，面临清算风险。请时刻注意管理你的头寸。",
  text_95: "清算价格",
  text_96: "价格",
  text_97: "销毁",
  text_99: "添加",
  text_100: "购买",
  text_102: "我的钱包",
  text_118: "流动市值",
  text_119: "流通量",
  text_127: "数据面板",
  text_128: "总锁仓量",
  text_120: "市场中流通的LQTY数量和占比。公式=（流通量/总量）*100%",
  text_371: "总供应量",
  text_041: "稳定池质押",
  text_863: "首页",
  text_123: "Liquity 生态",
}
export default zh_CN;