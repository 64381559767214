import { Box, Flex, Text } from "theme-ui";

import { Icon } from "./Icon";

export const ActionDescription: React.FC = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",

      // mb: [2, 3],
      // p: 3,
      fontSize:'12px',
      marginTop: '10px',
      color:'rgba(0, 0, 0, 1)',
      // border: 1,
      // borderRadius: "8px",
      // borderColor: "accent",
      // boxShadow: 2,
      // bg: "rgba(46, 182, 234, 0.05)"
    }}
  >
    <Flex sx={{ alignItems: "center" }}>
      {/* <Icon name="info-circle" size="lg" /> */}
      <Text>{children}</Text>
    </Flex>
  </Box>
);

export const Amount: React.FC = ({ children }) => (
  <Text sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}>{children}</Text>
);
