import React,{useEffect} from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Grid,
} from '@material-ui/core'
// import { MineViewProvider } from "../../components/Farm/context/MineViewProvider";

import PledgeLqty from "./PledgeLqty";
import PledgeUniLq from "./PledgeUniLq";
import Toast from "../../utils/Toast/index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      marginTop: '20px',
      [theme.breakpoints.down('xs')]: {
        marginTop: '0',
      },
    },
  })
)

const Pledge: React.FC = () => {
  const classes = useStyles()
  // Toast.success('123123', 1000000, () => {
  //   console.log('ok结束啦')
  // })
  return (
    <>
      <Grid
        container
        spacing={3}
        className={classes.box}
        justify="center"
      >
        <PledgeLqty></PledgeLqty>
        {/* <MineViewProvider> */}
          {/* <PledgeUniLq></PledgeUniLq> */}
        {/* </MineViewProvider> */}
        
      </Grid>
    </>
  )
};
export default Pledge;
