import {
  Contract,
} from "@ethersproject/contracts";

import { Provider } from "@ethersproject/abstract-provider";
import { Signer } from "@ethersproject/abstract-signer";

import PancakeSwapAbi from './abi-json/PancakeSwapAbi.json';
import LUSD3CRV_ABI from './abi-json/LUSD3CRV_ABI.json';
import CRV_gauge_ABI from './abi-json/CRV_gauge.json';
import CRV_GaugeController_ABI from './abi-json/CRV_GaugeController.json';
import FusePoolLens_ABI from './abi-json/FusePoolLens_ABI.json';
// import CakeLpAbi from './abi-json/CakeLpAbi.json';
import test_abi from './abi-json/test_abi.json';

export const contract_address = {
  uniswap: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',  // 去中心话交易所合约
  curve_lp: '0xEd279fDD11cA84bEef15AF5D39BB4d4bEE23F0cA',  // curve
  usdtToken: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  multiTroveGetter: '0xf06016d822943c42e3cb7fc3a6a3b1889c1045f8',
  XHalfLife: '0xb8a9faDA75c6d891fB77a7988Ff9BaD9e485Ca1C',
  crv_gauge: '0x9B8519A9a00100720CCdC8a120fBeD319cA47a14',
  crv_gauge_controller: '0x2F50D538606Fa9EDD2B11E2446BEb18C9D5846bB',
  fuse_pool_lens: '0x6Dc585Ad66A10214Ef0502492B0CC02F0e836eec',

  test_token: '0xC250d1bb1AEE57c8b895a58dDF5E302513aFbf30',

  LuckUp: '0x241aD9DfC7466C5299d622DF7664B71AB60Fe8D6',
  LuckUp0: '0x4A2C55CcD180cAA7519b7D4D3eD595Ec56fA81b4',
  LuckUp1: '0xfEE47986A4B9083d7dB1829BeEd6f88A91DD4338',
  LuckUp2: '0x31bB5b1655df3CB645d17c62B96B91e01159ce2D',
  LuckUp3: '0xe15f843480BAd6b9ce15338c7741ECD27335101F',
  LuckUp4: '0x1A29d558eEeFA90E23a6224c51a79ff448609539',
  LuckUp5: '0xC1D71192BDfA2ebC99C9b982F3c7C0Fa9EF3Ac4A',
  LuckUp6: '0x1f29d1e83c9f37aB2055e3336c4e680bfF8970b0',
  LuckUp7: '0xF5067d3FEE4D4306DD5DE03a9FE5b85de279D0CA',
  LuckUp8: '0xa31B086125dE7C3BfafABFAF738fd1F0925B6FBB',
  LuckUp9: '0x47df9fa3D01cb82B024CEfB4E2C1aeb1e229876f',
  LuckUp10: '0x86dd862d995147374C8Bc8d8ffedA43C50dC2e57',
  LuckUp11: '0xfb2ed967C27F07a883c9DD8A03B48ec883FC58b2',
  LuckUp12: '0xc05AD6E3DFf412497F72B38A125e187e08CD922F',
  LuckUp13: '0x68CCD86440f58109Cd964FEC1a641ba7A6825B90',
  LuckUp14: '0xCdE82316161446a5006D62f57A1Fb372aD148a45',
  LuckUp15: '0x997aD8F2dd7A46de02A4aa92336dE7513B9D78Ec',
  LuckUp16: '0x2f3bE49022B5944EA3F6050a3b5B415c3f307b78',
  LuckUp17: '0xa649Bc7D436Aad93865D5415Aa4BA6BCA9A05c0a',
  LuckUp18: '0x060952B3b1a3818d8917A03c43fa67bB6a15A2B2',
  LuckUp19: '0x2A0a9AC6D8FBcEA4c470a21862ECe3Aaef7f0C8e',
  LuckUp20: '0x2e00a841F3D9aF5c1c8f931640D268144d6a8193',
  LuckUp21: '0x4757F4E5f76fe3369843770d1090eF4F60e7a92C',
  LuckUp22: '0x39aE8159561a0a33168d6Df073BE3008b8A5ad73',
  LuckUp23: '0x3a287BBD3D6EBB85265266Fc7Ad08138627bC2d2',
  LuckUp24: '0x036a3ccEDCa822c59e57ce16F28C0C3C417359E4',
  LuckUp25: '0xD20Ac7e897b8e54df47d0a491b791C33193cE535',
  LuckUp26: '0x770638E0cD8781DD4b64E3A4Cad06113B6eEfccA',
  LuckUp27: '0x025baf9Ba5DacE8367C70cAD0B44b728eDba5449',
  LuckUp28: '0xd651d97Fdaf2323FC738827544eB7C91368f2BCA',
  LuckUp29: '0x84F48f7E16C4fa7aFC6C2761D22803f6601B02FB',
}

// export const contract_address_luck=[
//   '0x241aD9DfC7466C5299d622DF7664B71AB60Fe8D6',
//   '0x4A2C55CcD180cAA7519b7D4D3eD595Ec56fA81b4',
//   '0xfEE47986A4B9083d7dB1829BeEd6f88A91DD4338',
//   '0x31bB5b1655df3CB645d17c62B96B91e01159ce2D',
//   '0xe15f843480BAd6b9ce15338c7741ECD27335101F',
//   '0x1A29d558eEeFA90E23a6224c51a79ff448609539',
//   '0xC1D71192BDfA2ebC99C9b982F3c7C0Fa9EF3Ac4A',
//   '0x1f29d1e83c9f37aB2055e3336c4e680bfF8970b0',
//   '0xF5067d3FEE4D4306DD5DE03a9FE5b85de279D0CA',
//   '0xa31B086125dE7C3BfafABFAF738fd1F0925B6FBB',
//   '0x47df9fa3D01cb82B024CEfB4E2C1aeb1e229876f',
//   '0x86dd862d995147374C8Bc8d8ffedA43C50dC2e57',
//   '0xfb2ed967C27F07a883c9DD8A03B48ec883FC58b2',
//   '0xc05AD6E3DFf412497F72B38A125e187e08CD922F',
//   '0x68CCD86440f58109Cd964FEC1a641ba7A6825B90',
//   '0xCdE82316161446a5006D62f57A1Fb372aD148a45',
//   '0x997aD8F2dd7A46de02A4aa92336dE7513B9D78Ec',
//   '0x2f3bE49022B5944EA3F6050a3b5B415c3f307b78',
//   '0xa649Bc7D436Aad93865D5415Aa4BA6BCA9A05c0a',
//   '0x060952B3b1a3818d8917A03c43fa67bB6a15A2B2',
//   '0x2A0a9AC6D8FBcEA4c470a21862ECe3Aaef7f0C8e',
//   '0x2e00a841F3D9aF5c1c8f931640D268144d6a8193',
//   '0x4757F4E5f76fe3369843770d1090eF4F60e7a92C',
//   '0x39aE8159561a0a33168d6Df073BE3008b8A5ad73',
//   '0x3a287BBD3D6EBB85265266Fc7Ad08138627bC2d2',
//   '0x036a3ccEDCa822c59e57ce16F28C0C3C417359E4',
//   '0xD20Ac7e897b8e54df47d0a491b791C33193cE535',
//   '0x770638E0cD8781DD4b64E3A4Cad06113B6eEfccA',
//   '0x025baf9Ba5DacE8367C70cAD0B44b728eDba5449',
//   '0xd651d97Fdaf2323FC738827544eB7C91368f2BCA',
//   '0x84F48f7E16C4fa7aFC6C2761D22803f6601B02FB',
// ]

type EthersProvider = Provider;
type EthersSigner = Signer;

export const connectContract = (
  provider: EthersProvider,
  signer: EthersSigner | undefined,
) => {
  const contract = new Contract(contract_address.curve_lp, LUSD3CRV_ABI as any, signer ?? provider);
  return contract;
}

export const connectGaugeContract = (
  provider: EthersProvider,
  signer: EthersSigner | undefined,
) => {
  const contract = new Contract(contract_address.crv_gauge, CRV_gauge_ABI as any, signer ?? provider);
  return contract;
}

export const connectGaugeControllerContract = (
  provider: EthersProvider,
  signer: EthersSigner | undefined,
) => {
  const contract = new Contract(contract_address.crv_gauge_controller, CRV_GaugeController_ABI as any, signer ?? provider);
  return contract;
}

export const connectFusePoolLensContract = (
  provider: EthersProvider,
  signer: EthersSigner | undefined,
) => {
  const contract = new Contract(contract_address.fuse_pool_lens, FusePoolLens_ABI as any, signer ?? provider);
  return contract;
}

export const connectTestContract = (
  provider: EthersProvider,
  signer: EthersSigner | undefined,
) => {
  const contract = new Contract(contract_address.test_token, test_abi as any, signer ?? provider);
  return contract;
}