import React, { useEffect, useState,useCallback } from 'react'
import {
  Link as RouterLink,
  MemoryRouter as Router,
  LinkProps as RouterLinkProps,
  useHistory,
} from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  FormHelperText,
  Tooltip
} from '@material-ui/core'
import {
  CRITICAL_COLLATERAL_RATIO,
  MINIMUM_COLLATERAL_RATIO,
  Difference,
  LiquityStoreState,
  Percent,
  Trove,
  TroveChange,
  Decimal,
  Decimalish,
  LUSD_MINIMUM_NET_DEBT,
  LUSD_LIQUIDATION_RESERVE
} from '@liquity/lib-base'

import {
  selectForTroveChangeValidation,
  validateTroveChange
} from "../Trove/validation/validateTroveChangeNew";

import { useTroveView } from "../Trove/context/TroveViewContext";
import { LiquidatRedeemed } from './LiquidatRedeemed';

import { TroveAction } from "../Trove/TroveActionNew";

import { useTranslation, Trans, Translation } from 'react-i18next'
import { useLiquitySelector } from '@liquity/lib-react'
import { Transaction, useMyTransactionState } from "../../components/Transaction";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import common from '../../utils/common';
import RedditTextField from '../../components/new-ui/RedditTextField'
import DialogBorrow from '../../components/new-ui/DialogBorrow'
import { useLiquity } from "../../hooks/LiquityContext";

import icon_T from '../../assets/icon-lqty.svg'
import eth from '../../assets/eth.svg'
import tip from '../../assets/tip.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      width: '100%',
      marginBttom: '24px',
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '100%',
      margin: '0',
    },
    helperText: {
      // marginTop: '8px',
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    subBtn: {
      marginTop: '20px',
      width: '100%',
      padding: '13px 0',
    },
    success: {
      color:'#28c081'
    },
    warning: {
      color:'#fd9d28'
    },
    danger: {
      color:'#dc2c10'
    },
    muted: {
      // color:'#28c081'
    },
    card: {
      padding:'60px 40px',
      marginTop:'20px',
      background: '#FFFFFF',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.14)',
      borderRadius: '12px'
    },
    captionCard: {
      fontSize: '18px',
      fontWeight: 500,
      color: '#293147',
      lineHeight: '21px'
    },
    overline: {
      marginTop:'12px',
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '16px'
    },
    tip: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(41, 49, 71, 1)',
      lineHeight: '17px',
      background: '#FFFFFF',
      boxShadow: '6px 8px 10px 0px rgba(149, 145, 163, 0.14)',
      borderRadius: '9px',
      border: '1px solid #E3E3F3'
    },
    body2: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    body2Text: {
      // paddingRight: '10px',
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(157, 160, 170, 1)',
      lineHeight: '17px',
    },
    bodyText: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(rgba(31, 37, 51, 1))',
      lineHeight: '17px',
    },
    bodyBtn: {
      whiteSpace: 'nowrap',
      padding:'0',
      fontSize: '12px',
      fontWeight: 500,
      color: 'rgba(21, 66, 205, 1)',
      lineHeight: '17px',
    },
    subBtn1: {
      marginTop: '16px',
      width: '100%',
      padding: '13px 0',
    },
    btnColor1: {
      color: '#fff',
      backgroundColor: 'rgba(21, 66, 205, 1)',
      '&:hover': {
        backgroundColor: 'rgba(21, 66, 205, 1)',
        color: '#fff',
      },
    },
    btnColor: {
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: '#fff',
      },
    },
  })
)

const select = ({ accountBalance, lusdBalance,collateralSurplusBalance }: LiquityStoreState) => ({
  accountBalance,
  lusdBalance,
  redeemed: !collateralSurplusBalance.isZero,
})

const selector = (state: LiquityStoreState) => {
  const { fees, price, accountBalance ,collateralSurplusBalance} = state;
  return {
    fees,
    price,
    accountBalance,
    collateralSurplusBalance,
    validationContext: selectForTroveChangeValidation(state)
  };
};

type TroveEditorProps = {
  original: Trove
  edited: Trove
  afterFee: Trove
  borrowingRate: Decimal
  change?: TroveChange<Decimal>
  changePending: boolean
  dispatch: (
    action:
      | { type: 'setCollateral' | 'setDebt'; newValue: Decimalish }
      | { type: 'revert' }
  ) => void
  value: number,
  validChange:any
}

  const EMPTY_TROVE = new Trove(Decimal.ZERO, Decimal.ZERO);
  const TRANSACTION_ID = "trove-creation";
  const GAS_ROOM_ETH = Decimal.from(0.1);

const DepositWithdrawals: React.FC<TroveEditorProps> = ({
  children,
  original,
  edited,
  afterFee,
  change,
  changePending,
  dispatch,
  value,
  validChange
}) => {
  const classes = useStyles()
  const {
    liquity: { send: liquity }
  } = useLiquity();
  const { accountBalance, lusdBalance, redeemed } = useLiquitySelector(select)
  const { fees, validationContext,price,collateralSurplusBalance } = useLiquitySelector(selector);
  const [open, setOpen] = React.useState(false);
  let { t, i18n } = useTranslation();

  const { view } = useTroveView();

  const [text_72, setText_72] = useState<any>({
    text01: t('text_720'),
    text02: t('text_721'),
    text_94: t('text_94')
  });
  useEffect(() => {
    setText_72({
      text01: t('text_720'),
      text02: t('text_721'),
      text_94: t('text_94')
    })
  }, [i18n.language])

  const borrowingRate = fees.borrowingRate();
  const myTransactionId = "claim-coll-surplus";
  const myTransactionState = useMyTransactionState(myTransactionId);

  const borrowingFeePct = new Percent(borrowingRate)
  // const max_borrow = common.numberDecimal(Math.max(((Number(values.eth_amount) + Number(val.collateral)) * Number(price)) / 1.20 - Number(val.debt), 0),2)
  //   // (Math.floor(Math.max(((Number(values.eth_amount) + Number(val.collateral)) * Number(price)) / 1.20 - Number(val.debt), 0) * 100) / 100).toString()
  // const max_desirable = common.numberDecimal(Number(val.collateral) - (Number(val.debt) * 1.10 / Number(price)), 4)
    // (Math.floor((Number(val.collateral) - (Number(val.debt) * 1.10 / Number(price))) * 100) / 100).toString()

  useEffect(() => {
    setCollateralV('');
    setBorrowAmountV('');
  }, [accountBalance, lusdBalance])

  const closeCollateralDebt = () => {
    setOpen(true)
    dispatch({
      type: 'setCollateral',
      newValue: 0,
    })
    dispatch({
      type: 'setDebt',
      newValue: 0,
    })
  }

  const closeHandle = () => {
    setOpen(false)
  }
  
  const text = t('text_25');

  
  const [collateralV, setCollateralV] = useState<string>('');
  const [borrowAmountV, setBorrowAmountV] = useState<string>('');
  const [one, setOne] = useState<boolean>(false);
  const [collateral, setCollateral] = useState<Decimal>(Decimal.ZERO);
  const [borrowAmount, setBorrowAmount] = useState<Decimal>(Decimal.ZERO);

  const maxBorrowingRate = borrowingRate.add(0.005);
  // const maxEth = accountBalance.gt(GAS_ROOM_ETH) ? accountBalance.sub(GAS_ROOM_ETH) : Decimal.ZERO;
  // const maxCollateral = collateral.add(maxEth);
  const fee = borrowAmount.mul(borrowingRate);
  const feePct = new Percent(borrowingRate);
  const totalDebt = borrowAmount.add(LUSD_LIQUIDATION_RESERVE).add(fee);
  const isDirty = !collateral.isZero || !borrowAmount.isZero;
  const trove = isDirty ? new Trove(collateral, totalDebt) : EMPTY_TROVE;
  const collateralRatio =
  !collateral.isZero && !borrowAmount.isZero ? trove.collateralRatio(price) : undefined;
  const collateralRatioPct = new Percent(collateralRatio ?? { toString: () => "--" });

  const ethPrice=borrowAmount.mul(Decimal.from(1.1)).div(collateral)

  const maxEth = accountBalance.gt(GAS_ROOM_ETH) ? accountBalance.sub(GAS_ROOM_ETH) : Decimal.ZERO;
  // const maxCollateral = collateral.add(maxEth);
  // const max_borrows = common.numberDecimal(Math.max(((Number(values.eth_amount) + Number(val.collateral)) * Number(price)) / 1.20 - Number(val.debt), 0), 2)

  const [troveChange, description] = validateTroveChange(
    EMPTY_TROVE,
    trove,
    borrowingRate,
    validationContext
  );

  const handleChangeLusd = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setBorrowAmountV(value)
    // setValues({ ...values, [prop]: event.target.value })
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setCollateralV(value)
    // setValues({ ...values, [prop]: event.target.value })
  }

  const handleMaxClick = (str:string) => {
    if (str === 'ETH') {
      setCollateralV(value ? '0' : common.numberDecimal(maxEth.toString(), 4))
    } else {
      // const balance = Number(val.debt) > Number(lusdBalance) ?
      //   common.numberDecimal(Number(lusdBalance), 2) :
      //   val.debt;
        setBorrowAmountV(value ? '0' : '0');
    }
  }

  useEffect(() => {
    if (collateralV && !borrowAmountV && !value && !one) {
      setOne(true);
      setBorrowAmountV(LUSD_MINIMUM_NET_DEBT.toString());
    }
  }, [collateralV, borrowAmountV]);

  useEffect(() => {
    setCollateralV('');
    setBorrowAmountV('');
    setBorrowAmount(Decimal.from(0));
    setCollateral(Decimal.from(0));
  }, [value])

  useEffect(() => {
    const vals = value ? '0' : common.numberDecimal(Number(accountBalance),4)
    if (Number(collateralV) > 0 && Number(collateralV)<=Number(vals)) {
      if (value) {
        // setCollateral(Decimal.from(Number(val.collateral) - Number(values.eth_amount)))
      } else {
        setCollateral(Decimal.from(collateralV))
      }
    } else if (Number(collateralV)>Number(vals)) {
      if (value) {
      // setCollateral(Decimal.from(Number(val.collateral) - Number(values.eth_amount)))
      } else {
        setCollateral(Decimal.from(collateralV))
      }
    } else if (!collateralV) {
      setCollateral(Decimal.from(0))
    }
  }, [collateralV])

  useEffect(() => {
    // const vals = '0';
    if (Number(borrowAmountV) > 0 ) {
      if (value) {
        // setCollateral(Decimal.from(Number(val.collateral) - Number(values.eth_amount)))
      } else {
        setBorrowAmount(Decimal.from(borrowAmountV))
      }
    } else if (!borrowAmountV) {
      setBorrowAmount(Decimal.from(0))
    }
  }, [borrowAmountV])

  return (
    <Box>
      {
        // myTransactionState.type !== "waitingForConfirmation" &&
        //   myTransactionState.type !== "confirmed" && redeemed
        view === "REDEEMED" || view === "LIQUIDATED"? (
          <>
            {/* <Box className={classes.card} display="flex" alignItems="center" justifyContent="center">
              <Box style={{textAlign:'center'}}>
                <Typography variant="h3" className={classes.captionCard} display="block" gutterBottom>
                {t('text_67')}
                </Typography>
                <Typography variant="caption" className={classes.overline} display="block" gutterBottom>
                {t('text_68')}
                </Typography>
              </Box>
            </Box>

            <Transaction
              id={myTransactionId}
              send={liquity.claimCollateralSurplus.bind(liquity, undefined)}
            >
              <Button className={clsx(classes.subBtn1,classes.btnColor1)}>Claim {collateralSurplusBalance.prettify()} ETH</Button>
            </Transaction> */}
             <LiquidatRedeemed view={view} />
            </>
        ): (
          <>
            <RedditTextField
              labeltext={value ? t('text_15') : t('text_16')}
              coin_in="ETH"
              type='number'
              coin={(<><img src={eth} style={{width:'18px',marginRight:'4px'}} alt=""/><Box>ETH</Box></>)}
              endadornmenttext={value ? t('text_17') : t('text_18')}
              endadornmentvalue={value ? '0.00' : common.numberDecimal(Number(accountBalance),4)}
              isbtn="1"
              btntext="Max"
              btncallback={handleMaxClick}
              onChange={handleChange}
              value={collateralV}
              className={clsx(classes.margin, classes.textField)}
              // defaultValue="react-reddit"
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ backgroundColor: '#fff', marginTop: '20px' }}
              helperText=""
            />
                <RedditTextField
                    labeltext={value ? t('text_19') : t('text_09')}
                    placeholder={!value && !Number(borrowAmountV) ? t('text_20'):''}
                    coin_in="LUSD"
                    type='number'
                    coin={(<><img src={icon_T} style={{width:'18px',marginRight:'4px'}} alt=""/><Box>LUSD</Box></>)}
                    tiptext={t('text_21')}
                    endadornmenttext={value ? t('text_18') : t('text_22')}
                    endadornmentvalue={value ? common.numberDecimal(Number(lusdBalance),2) : '0.00'}
                    isbtn="1"
                    btntext="Max"
                    btncallback={handleMaxClick}
                    onChange={handleChangeLusd}
                    value={borrowAmountV}
                    // defaultValue={edited.debt.toString(2)}
                    className={clsx(classes.margin, classes.textField)}
                    // defaultValue="react-reddit"
                    variant="filled"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ backgroundColor: '#fff', marginTop: '20px' }}
            />

            {/* {value ? (
                <>
                <FormHelperText component="div"
                  style={{ marginTop: '10px' }}
                  className={classes.helperText}>
                  <Typography variant="body2" className={classes.body2Text} gutterBottom>
                  {t('text_23')}
                  </Typography>
                  <Button className={classes.bodyBtn} onClick={closeCollateralDebt}>{t('text_24')}</Button>
                </FormHelperText>
                </>
            ) : null
            } */}

            {/* {children} */}
            
              {description}

              {troveChange ? (
                <TroveAction
                  transactionId={TRANSACTION_ID}
                  change={troveChange}
                  maxBorrowingRate={maxBorrowingRate}
                >
                  {t('text_48')}
                </TroveAction>
              ) : (
                <Button disabled className={clsx(classes.subBtn,classes.btnColor)}>{t('text_48')}</Button>
              )}

              {
                collateralV && borrowAmountV && (
                  <>
                    <FormHelperText
                  component="div"
                  style={{ marginTop: '20px' }}
                  className={classes.helperText}
                >
                <Typography className={clsx(classes.body2,classes.body2Text)} variant="body2" gutterBottom>
                {t('text_95')}
                  <Tooltip
                    classes={{
                      tooltip: classes.tip
                    }}
                    title={text_72.text_94}
                    interactive
                    placement="bottom">
                    <img src={tip} style={{ width: '14px', marginRight: '2px', marginLeft: '4px' }} alt="" />
                    {/* <WarningIcon style={{ color: 'rgba(210, 214, 220, 1)', fontSize: '14px', marginRight: '2px' }}></WarningIcon> */}
                  </Tooltip>
                </Typography>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                        {/* {collateralRatio} */}
                  --
                  <ArrowRightAltIcon style={{margin:'0 10px',color:'rgba(31, 37, 51, 1)',fontSize:'12px'}}></ArrowRightAltIcon>
                  {Number(ethPrice)>0?'$' + ethPrice.prettify():'--'}
                </Box>
              </FormHelperText>
                  </>
                )
              }

            {
              (Number(collateralV)>0 || Number(borrowAmountV)>0) ? 
                (
                  <>
                    <FormHelperText
                        component="div"
                        style={{ marginTop: '10px' }}
                        className={classes.helperText}
                      >
                        <Typography className={classes.body2Text} variant="body2">
                        {value ? t('text_15') : t('text_16')}
                        </Typography>
                        <Typography className={classes.body2Text} variant="body2">
                          {collateralV} ETH
                        </Typography>
                    </FormHelperText>
                    
                    {!value && (
                      <>
                        <FormHelperText
                          component="div"
                          style={{ marginTop: '10px' }}
                          className={classes.helperText}
                        >
                          <Typography variant="body2" className={clsx(classes.body2,classes.body2Text)}>
                            {t('text_58')}
                            <Tooltip
                              classes={{
                                tooltip: classes.tip
                              }}
                              title={text}
                              interactive
                              placement="bottom">
                              <img src={tip} style={{ width: '14px', marginRight: '2px', marginLeft: '4px' }} alt="" />
                              {/* <WarningIcon style={{ color: 'rgba(210, 214, 220, 1)', fontSize: '14px', marginRight: '2px' }}></WarningIcon> */}
                            </Tooltip>
                          </Typography>
                          <Typography className={classes.body2Text} variant="body2">
                            200 LUSD
                          </Typography>
                        </FormHelperText>
                      </>
                    )}
                    {!value && (
                      <>
                      <FormHelperText
                        component="div"
                        // style={{ marginTop: !value && !Number(val.debt) ? '10px' : '20px' }}
                        style={{ marginTop: '10px' }}
                        className={classes.helperText}
                      >
                        <Typography className={classes.body2Text} variant="body2">
                          {t('text_60')}
                        </Typography>
                        <Typography className={classes.body2Text} variant="body2">
                          {fee ? fee.toString(2) : '0.00'} LUSD ({borrowingFeePct.toString(2)})
                        </Typography>
                      </FormHelperText>

                      <FormHelperText
                      component="div"
                      style={{ marginTop: '10px' }}
                      className={classes.helperText}
                      >
                      <Typography variant="body2" className={clsx(classes.body2,classes.body2Text)}>
                      Total debt
                            {/* {t('text_58')} */}
                        <Tooltip
                          classes={{
                            tooltip: classes.tip
                          }}
                          title={text_72.text01+(Number(totalDebt)>0?totalDebt.sub(Decimal.from(200)).prettify(2):'0')+text_72.text02}
                          interactive
                          placement="bottom">
                          <img src={tip} style={{ width: '14px', marginRight: '2px', marginLeft: '4px' }} alt="" />
                          {/* <WarningIcon style={{ color: 'rgba(210, 214, 220, 1)', fontSize: '14px', marginRight: '2px' }}></WarningIcon> */}
                        </Tooltip>
                      </Typography>
                      <Typography className={classes.body2Text} variant="body2">
                        {totalDebt.prettify(2)} LUSD
                      </Typography>
                        </FormHelperText>
                        </>
                    )}
                    
                    <FormHelperText
                      component="div"
                        style={{ marginTop: '10px'}}
                        className={classes.helperText}
                      >
                        <Typography className={classes.body2Text} variant="body2">
                        {value ? t('text_19') : t('text_092')}
                        </Typography>
                        <Typography className={classes.body2Text} variant="body2">
                        {borrowAmountV} LUSD
                        </Typography>
                      </FormHelperText>

                <FormHelperText
                  component="div"
                  style={{ marginTop: '10px' }}
                  className={classes.helperText}
                >
                <Typography className={classes.body2Text} variant="body2" gutterBottom>
                {t('text_26')}
                </Typography>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                        {/* {collateralRatio} */}
                  --
                  <ArrowRightAltIcon style={{margin:'0 10px',color:'rgba(31, 37, 51, 1)',fontSize:'12px'}}></ArrowRightAltIcon>
                  {collateralRatioPct.prettify()}
                </Box>
              </FormHelperText>
                </>
              ) : null
            }
            
            </>
        )}
    </Box>
  )
}

export default DepositWithdrawals
