import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import './utils/i18n';

const htmlLoading: any = document.getElementById('loading-app');
const docu = document.body;
docu.removeChild(htmlLoading);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
