
const ES = {
  text_01:"Depositar",
  text_02: "Retirar",
  text_03: "Amortización ",
  text_04: "Total en stake",
  text_05: "",
  text_06: "",
  text_07: "STAKED",
  text_08: "Stake ",
  text_09: "Pedir prestado",
  text_091: "Pedir prestado",
  text_092: "Pedir prestado",
  text_10: "Reserva de estabilidad",
  text_11: "Stake",
  text_12: "Liquidación",
  text_13: "LQTY restantes",
  text_14: "Recompensa",
  text_15: "Retirar",
  text_16: "Depositar",
  text_17: "Disponible: ",
  text_18: "Monedero: ",
  text_19: "Pagar",
  text_20: "El préstamo debe ser de al menos 2000 LUSD.",
  text_21: "Aunque el ratio de garantía mínimo es de un 110 % durante la operativa normal, se recomienda mantener un ratio de garantía por encima del 150 % para evitar una liquidación en el modo recuperación. Se recomienda un ratio de garantía por encima del 200 % al 250 % para una mayor seguridad.",
  text_22: "Disponible: ",
  text_23: "Asegurate de disponer de suficientes LUSD antes de cerrar el trove",
  text_24: "Cerrar trove",
  text_25: "Esta tarifa cubre el coste de gas que un liquidador pagaría para liquidar tu Trove. Se te devolverá esta tarifa cuando devuelvas el préstamo.",
  text_26: "Ratio de garantía",
  text_261: "Ratio",
  text_27: "Amortización",
  text_28: "Préstamo",
  text_29: "Pagar",
  text_30: "Retirar",
  text_31: "Cerrar Trove",
  text_32: "Retirar del stake",
  text_33: "Stake",
  text_34: "Retirar",
  text_35: "Stake",
  text_36: "Disponible: ",
  // text_37: "Oferta total de LUSD",
  text_37: "Total LUSD supply",
  // text_38: "Ratio de garantía total",
  text_38: "Total Collateral Ratio",
  text_39: "Precio",
  text_40: " APR ",
  text_41: "Titular",
  text_42: "Garantía (ETH)",
  text_43: "Préstamo (LUSD)",
  text_44: "Ratio de garantía",
  text_45: "Estado",
  text_46: "Liquidar",
  text_lp: "Las recompensas LQTY en esta piscina durarán 6 semanas.",
  text_47: "Las recompensas LQTY en esta piscina durarán 35 meses.",
  text_48: "Confrimar",
  text_49: "Confirmar",
  text_50: "Pendiente",
  text_51: "Success",
  text_52: "Transaction Confirmed",
  text_53: "Failure ",
  text_54: "Please try again.",
  text_55: "Error",
  text_56: "Transaction Rejected",
  text_57: "Transacción",
  text_58: "Reserva de liquidación",
  text_59: "El préstamo debe ser de al menos",
  text_591: ".",
  text_60: "Tarifa",
  text_61: "An estimate of the LUSD&ETH return on the LQTY staked to this pool.（Calculation in LUSD)",
  text_62: "Liquity Analysis",
  text_63: "Switch to DevUI",
  text_64: "Switch to FiUI",
  text_65: "Redeem",
  text_67: "Your Trove has been redeemed",
  text_68: "Please reclaim your remaining collateral before opening a new Trove.",
  text_69: "Account",
  text_70: "Connected ",
  text_71: "Change",
  text_720: "The total amount of LUSD your Trove will hold. You will need to repay ",
  text_721: " LUSD to reclaim your collateral (200 LUSD Liquidation Reserve excluded).",
  text_730: "Note: ",
  text_731: "Redemption is not for repaying your loan. To repay your loan, switch to",
  text_732: "Withdraw tab.",
  text_91: "Normal Mode:",
  text_92: "Recovery Mode:",
  text_93: "Recovery Mode Price :",
  text_94: "The dollar value per unit of collateral at which your Trove will drop below a 110% Collateral Ratio and be liquidated. You should ensure you are comfortable with managing your position so that the price of your collateral never reaches this level.",
  text_95: "Liquidation price",
  text_96: "Price",
  text_97: "Currently Burned",
  text_99: "Add",
  text_100: "Buy",
  text_102: "My Wallet",
  text_118: "Market Cap",
  text_119: "Circulating Supply",
  text_127: "Statistics",
  text_128: "TVL",
  text_120: "The amount of LQTY  that are circulating in the market. Formula = (Circulating Supply / Total Supply)*100%",
  text_371: "Total supply",
  text_041: "LUSD in Stability Pool",
  text_863: "Home",
  text_123: "Liquity 生态",
}
export default ES;