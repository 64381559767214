import React, { useEffect} from 'react';
import { Link as RouterLink,MemoryRouter as Router, LinkProps as RouterLinkProps,useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  ListItem,
  makeStyles,
  List,
  ListItemText,
  Theme,
  createStyles,
  Tabs,
  Tab
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home'

import { useTranslation, Trans, Translation } from 'react-i18next'
import icon_02 from '../../assets/icon-02.svg';
import icon_03 from '../../assets/icon-03.svg';
import icon_04 from '../../assets/icon-04.svg';
import icon_05 from '../../assets/icon-05.svg';

const icon_06=(<svg width="24px" height="24px" style={{marginRight:'6px',marginLeft: '-2px'}} fill="none" viewBox="0 0 24 24" stroke="#D2D6DC"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>)

const icon_08 = (
  <HomeIcon
    style={{ marginRight: '6px', marginLeft: '-2px',width:"24px",opacity:"0.7", height:"24px", color: '#D2D6DC' }}
  ></HomeIcon>
)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  icon: {
    marginRight: theme.spacing(1),
    height: '19px'
  },
  indicator: {
    left: '0',
    right: 'auto',
    width:'4px',
    backgroundColor:'rgba(21, 66, 205, 1)'
  },
    tabs: {
      // borderLeft: `1px solid ${theme.palette.divider}`,
    },
    tab: {
      padding:'10px',
      paddingLeft: '30px',
      textAlign: 'left',
      minHeight: 'auto',
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'initial',
      justifyContent: 'flex-start',
      '&> *:first-child': {
        marginBottom:'0!important'
      }
    },
  })
);

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface LinkTabProps {
  label?: string;
  href?: string;
  target?: string;
  icon: any;
}

function LinkTab(props: LinkTabProps) {
  const classes = useStyles();
  return (
    <Tab
      classes={{
        wrapper: classes.wrapper
      }}
      className={classes.tab}
      component="a"
      target='_blank'
      {...props}
    />
  );
}

type Typeitems = {
  href: String;
  title: any;
  icon: any;
  type?: string;
  target?: boolean;
  // key?: Number;
}

const items: Array<Typeitems> = [
  {
    href: '/',
    icon: icon_08,
    type: 'svg',
    title: 'text_863'
  },
  {
    href: '/trove',
    icon: icon_02,
    title: 'text_091'
  },
  {
    href: '/pool',
    icon: icon_03,
    title: 'text_10'
  },
  {
    href: '/stake',
    icon: icon_04,
    title: 'text_11'
  },
  // {
  //   href: '/eco',
  //   icon: icon_04,
  //   title: 'text_123'
  // },
  {
    href: '/liquidation',
    icon: icon_05,
    title: 'text_12'
  },
  // {
  //   href: 'https://duneanalytics.com/dani/Liquity',
  //   icon: icon_06,
  //   title: 'text_12',
  //   type: 'svg',
  //   target: true
  // },
  // {
  //   href: '/registration',
  //   icon: icon_05,
  //   title: '成为代理商'
  // }
];

const NavItem:React.FC<any> = () => {
  const classes = useStyles();
  let { t, i18n } = useTranslation();
  const local = useHistory().location.pathname;
  // const isUrl = local === '/' ? '/trove' : local;
  const isUrl = local;

  const [value, setValue] = React.useState(0);
  useEffect(() => {
    items.map((item,index) => {
      if (item.href===isUrl) {
        setValue(index)
      }
    })
  }, [])
  
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs
        orientation="vertical"
        // variant="scrollable"
        classes={{
          indicator:classes.indicator
        }}
        value={value}
        onChange={handleChange}
        // aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {items.map((item: any, index: number) => (
          <Tab
            classes={{
              wrapper: classes.wrapper
            }}
            className={classes.tab}
            icon={
              item.type && item.type === 'svg' ? (
                item.icon
              ) : (
                <img src={item.icon} className={classes.icon} alt="" />
              )
              // <img src={item.icon} className={classes.icon} alt="" />
            }
            label={t(item.title)}
            component={RouterLink}
            target={item.target?'_blank':''}
            to={item.href}
            {...a11yProps(index)} />
        ))}
        <LinkTab
          icon={icon_06}
          label={t('text_62')} href="https://duneanalytics.com/dani/Liquity" {...a11yProps(4)} />
{/* 
        <Tab
            classes={{
              wrapper: classes.wrapper
            }}
            className={classes.tab}
            icon={icon_06}
            label={t(item.title)}
            component={RouterLink}
            target='_blank'
            href='icon_06'
            {...a11yProps(index)} /> */}
      </Tabs>
    </>
    // <ListItem
    //   className={clsx(classes.item, className)}
    //   disableGutters
    //   {...rest}
    // >
    //     <Button
    //         // activeClassName={classes.active}
    //         className={classes.button}
    //         component={RouterLink}
    //         to={href}
    //         // onClick={() => { linkClick(href) }}
    //       >
    //     {icon && (
    //       <img src={icon} className={classes.icon} alt=""/>
    //       /* <Icon
    //         className={classes.icon}
    //         size="20"
    //       /> */
    //      )}
    //     <span className={classes.title}>
    //       {title}
    //     </span>
    //     {href === isUrl && (
    //       <Box className={classes.box}></Box>
    //     )}
    //   </Button>
      
    //   </ListItem>
  );
};

export default NavItem;
